import { useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { UseDataFetching, GetAdminUsersAPI, UploadDataForCreateUserAPI, DeleteUserAPI } from '../../apis'
import {Table,Select,TextField,Button,Snackbar,FormLabel,TableBody,TableCell,TableContainer,TableHead,TableRow,Paper} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function GetCookies(name:any){
    var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    if (arr = document.cookie.match(reg)) return unescape(arr[2]);
    else return null;
};
const authorsStyle = makeStyles((theme) => ({
    body:{
        paddingTop:10,
        marginTop:30,
        paddingLeft:70,
        paddingRight:100,
    },
    content:{
        padding:theme.spacing(2),
        paddingTop:40,
        paddingLeft:40,
        border:1,
        borderRadius:4,
        backgroundColor:"white",
        borderStyle:"solid",
        borderColor:"#bdbdbd",
        width:"100%",
        height:90,
    },
    title:{
        marginTop:45,
    },
    textfield:{
        marginTop:8,
        border:1,
        borderRadius:4,
        backgroundColor:"white",
    },

})); 


export const AdminAuthors = () => {
    const classes = authorsStyle();
    let history = useHistory();
    var userId = String(GetCookies("userId"))
    const [rows ,setRows ] = useState([]);
    const [username ,setUsername ] = useState("");
    const [password ,setPassword ] = useState("");
    const [deleteTarget ,setDeleteTarget ] = useState(0);
    const [open, setOpen] = useState(false);
    const [nickname ,setNickname ] = useState("");
    const [role ,setRole] = useState("Admin");
    const [postFailure,setPostFailure] = useState(false);
    const [errMsg,setErrMsg] = useState("");
    const [postSuccess,setPostSuccess] = useState(false);
    const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
        setDeleteTarget(0);
        setPostSuccess(false);
        setPostFailure(false);
    };
    const GetTableData = async() => {
        var list:any =[];
        const  {results} =  await UseDataFetching(GetAdminUsersAPI({})) as any;
        (results['users']||[]).map(function(result:any){
          list.push({'user_id':result.user_id,'username':result.username,'nickname':result.nickname,"user_uuid":result.user_uuid,"role":result.role});
        })
        setRows(list);
    };
    const ClickViewButton = (userId:number) => {

        history.push("/admin-panel/user?&id="+userId)
    };
    const handleClickOpen = async(userId:number)=> {
        setDeleteTarget(userId)
        setOpen(true);
    };

    const handleDeleteUser = async() => {
        const {  results } = await UseDataFetching(DeleteUserAPI({userId:deleteTarget,
        })) as any
        switch (results['code']) {
             case 200:
                setPostSuccess(true);
                GetTableData();
                break
            default:
                setErrMsg(results['message'])
                setPostFailure(true);
                break

        }   
        setOpen(false);
    };


    const handleCreateUser = async() => {
        const { results } = await UseDataFetching(UploadDataForCreateUserAPI({username:username,
            nickname:nickname,
            password:password,
            role:role,
        })) as any
        switch (results['code']) {
             case 200:
                setPostSuccess(true);
                setUsername("");
                setPassword("");
                setNickname("");
                setRole("");
                GetTableData();
                break
            case 1001:
                setErrMsg(results['message'])
                setPostFailure(true);
                break
            default:
                console.log("error");
                break;

        }   
    };

    useEffect(() =>{
        GetTableData();
    }, []);

    return <div className={classes.body}>
        <div>
            <div>
                <FormLabel component="legend">用戶列表</FormLabel>
            </div>
            
            <TableContainer style={{marginTop:5}} component={Paper}>
                <Table  aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell align="center">頭像</TableCell>          
                        <TableCell align="center">顯示名稱</TableCell>
                        <TableCell align="center">用戶名</TableCell>
                        <TableCell align="center">角色</TableCell>
                        <TableCell align="center">Actions</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {rows.map((row:any) => (
                        <TableRow hover key={row.user_id}>
                        <TableCell align="center">
                             <img style={{width:40,height:40,borderRadius:"100%",overflow:"hidden"}} src={"https://d3fdygqk2e2j4k.cloudfront.net/user_icon/"+row.user_uuid}></img>
                        </TableCell>
                        <TableCell align="center">
                            {row.nickname}
                        </TableCell>
                        <TableCell align="center">{row.username}</TableCell>
                        <TableCell align="center">{row.role}</TableCell>
                        <TableCell align="center">
                            <Button onClick={()=>ClickViewButton(row.user_id)}>view</Button>
                            {row.user_id===userId?null: <Button onClick={()=>handleClickOpen(row.user_id)}>delete</Button>}
                        </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
        <div>
            <div className={classes.title}>
                <FormLabel component="legend">新增用戶</FormLabel>
            </div>
            <div className={classes.textfield}>
                <TextField id="schema"
                multiline 
                label="username"
                fullWidth
                value={username}
                onChange={event => setUsername(event.target.value)}
                variant="outlined" />
            </div>
            <div className={classes.textfield}>
                <TextField id="schema"
                multiline 
                label="nickname"
                fullWidth
                value={nickname}
                onChange={event => setNickname(event.target.value)}
                variant="outlined" />
            </div>
            <div className={classes.textfield}>
                <TextField id="schema"
                multiline 
                label="password"
                fullWidth
                value={password}
                onChange={event => setPassword(event.target.value)}
                variant="outlined" />
            </div>
            <div className={classes.textfield}>
            <Select
            value={role}
            onChange={event => setRole(event.target.value as string)}
            fullWidth
            native
            variant="outlined" 
            >
             <option key="0" value="Admin">Admin</option>
             <option key="1" value="Writer">Writer</option>
            
            </Select>
            </div>
           

            <div style={{marginTop:10}}>
                <Button onClick={handleCreateUser}  color="primary" variant="outlined">Create</Button>
            </div>
        </div>
        <Snackbar open={postSuccess} autoHideDuration={3000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
                Success!
            </Alert>
        </Snackbar>
        <Snackbar open={postFailure} autoHideDuration={3000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
                {errMsg}
            </Alert>
        </Snackbar>
        <div>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"delete?"}</DialogTitle>
            <DialogActions>
            <Button onClick={handleClose} color="primary">
                No
            </Button>
            <Button onClick={handleDeleteUser} color="primary" autoFocus>
                Yes
            </Button>
            </DialogActions>
        </Dialog>
        </div>
    </div>
}