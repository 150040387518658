import { makeStyles } from '@material-ui/core/styles'
// theme
import { Colors, Layouts, Fonts, Sprites } from '../theme'
import * as Svgs from '../theme/sprites/svgs'

const background = {
    ...Layouts.defualtBackgroundStyle,
    backgroundColor: Colors.blue1,
}

const footerBackground = {
    ...Layouts.defualtBackgroundStyle,
    ...Layouts.columnWithJCStart as {},
    padding: `40px 0`,
    backgroundColor: Colors.blue2,
}

export const FooterStyles = makeStyles({
    background: {
        ...background,
        height: Layouts.homePageHeight.subFooter,
    },
    backgroundM: {
        ...background,
        height: Layouts.M.homePageHeight.subFooter,
    },
    footerBackground: {
        ...footerBackground,
        height: Layouts.homePageHeight.footer,
    },
    footerBackgroundM: {
        ...footerBackground,
        height: Layouts.M.homePageHeight.footer,
    },
    container: {
        ...Layouts.defaultContainerStyle,
        ...Layouts.rowWithAllCenter,
    },
    containerM: {
        ...Layouts.M.defaultContainerStyle,
        ...Layouts.rowWithAllCenter,
        paddingTop: 16,
    },
    footerContainerM: {
        ...Layouts.M.defaultContainerStyle,
    },
    briefBackground: {
        padding: '0 20px',
        paddingBottom: 24,
    },
    briefContainerWrapper: {
        ...Layouts.rowWithAllCenter,
    },
    briefContainerWrapperM: {
        height: 108,
    },
    briefContainerWrapperWrapperM: {
        ...Layouts.rowWithJCSpaceBetweenAIStart,
    },
    briefContainer: {
        ...Layouts.rowWithJCStart,
        width: 168,
    },
    headerText: {
        ...Fonts.fontXBig700,
        lineHeight: 0,
        color: Colors.blue2,
    },
    headerTextM: {
        ...Fonts.fontBig700,
        lineHeight: 0,
        color: Colors.greyBlack85,
    },
    briefTextM: {
        ...Fonts.fontSmall400,
        margin: `6px 0`,
        marginLeft: 4,
    },
    briefText: {
        ...Fonts.fontMedium700,
        lineHeight: 0,
        color: Colors.blue2,
        marginLeft: 4,
    },
    footerLeftInnerContainer: {
        ...Layouts.rowWithJCSpaceBetweenAIStart,
        width: '55%',
        height: Layouts.homePageHeight.footer,
        borderRight: Layouts.borderLine(Colors.white, 1)
    },
    footerRightInnerContainer: {
        width: '45%',
        height: Layouts.homePageHeight.footer,
        borderLeft: Layouts.borderLine(Colors.white, 1),
    },
    footerCategoriesContainer: {
        ...Layouts.rowWithJCSpaceBetweenAIStart,
        flex: 1,
        padding: `0 80px`
    },
    footerCategoriesContainerM: {
        ...Layouts.rowWithJCSpaceBetweenAIStart,
        marginTop: 32,
        margin: `0 24px`,
        paddingBottom: 24,
        borderBottom: Layouts.borderLine(Colors.white, 1)
    },
    footerCategoryText: {
        ...Fonts.fontMediumBig700,
        marginBottom: 12,
        color: Colors.white,
        margin: 0,
    },
    footerSubCategoryTextContainer: {
        marginBottom: 8,
    },
    footerSubCategoryText: {
        ...Fonts.fontMedium400,
        color: Colors.white,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    footerDisclaimerContainer: {
        ...Layouts.columnJCSpaceBetweenAIStart as {},
        height: '100%',
        padding: `0 40px`,
    },
    footerDisclaimerContainerM: {
        padding: `16px 24px`,
    },
    footerDisclaimerText: {
        ...Fonts.fontSmall400,
        lineHeight: '18px',
        color: Colors.white,
    },
    footerShareIconContainer: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    footerShareIconsContainer: {
        ...Layouts.rowWithJCSpaceBetween,
        width: '80%',
        padding: `8px 0`
    },
    footerShareIconsContainerM: {
        ...Layouts.rowWithJCSpaceBetween,
        width: '35%',
    },
    footerShareIconsContainerWrapperM: {
        ...Layouts.rowWithJCSpaceBetween,
        marginTop: 20
    },
})
