import { makeStyles } from '@material-ui/core/styles'
// theme
import { Colors, Layouts, Fonts } from '../theme'

export const ArticleTagsReadingTimeStyles = makeStyles({
    container: {
        display: 'flex',
        padding: `32px 0`,
        borderBottom: Layouts.borderLine(Colors.blue5),
    },
    containerM: {
        padding: 24,
    },
    headerContainerM: {
        ...Layouts.rowWithJCSpaceBetween as {},
        marginBottom: 4,
    },
    headerText: {
        ...Fonts.fontMedium700,
        margin: 0
    },
    headerSubTextM: {
        ...Fonts.fontMedium400,
        textDecoration: 'underline'
    },
    leftInnerContainer: {
        width: '60%'
    },
    rightInnerContainer: {
        width: '40%',
        ...Layouts.columnWithJCSpaceBetween as {}, 
   },
    labelsContainer: {
        ...Layouts.rowWithWrap as {},
    },
    slideBarTimesContainer: {
        ...Layouts.rowWithJCSpaceBetween as {},
    },
    slideBarTimesContainerWrapperM: {
        marginTop: 24,
    },
    slideBarTimeText: {
        ...Fonts.fontSmall400,
        width: 40,
        textAlign: 'center',
    },
    buttonsContainer: {
        ...Layouts.rowWithJCStart as {},
    },
    buttonsContainerM: {
        marginTop: 24,
    },
})
