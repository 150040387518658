import _ from 'lodash'
// libraries
import { Button as MuiButton } from '@material-ui/core'
// styles
import { buttonStyles } from '../../styles/button-styles'
import '../../css/button.css'
// types
import { IButtonProps } from '../../interfaces'
// theme
import { Sprites } from '../../theme'

export const Button = (props: IButtonProps) => {

    const {
        buttonKey = '',
        buttonContainerClass = '',
        buttonTextClass = '',
        buttonText = '',
        disabled = false,
        onClick = () => null,
        renderComponent = () => <div></div>
    } = props;

    const useStyleButton = buttonStyles()

    return (
        <div key={buttonKey}>
            <MuiButton
                style={{ textTransform: 'none' }}
                disabled={disabled}
                className={`${useStyleButton.buttonContainer} ${useStyleButton[buttonContainerClass]}`}
                onClick={onClick}
            >
                {renderComponent()}
                <div
                    className={`${useStyleButton.buttonText} ${useStyleButton[buttonTextClass]}`}
                >
                    {buttonText}
                </div>
            </MuiButton>
        </div>
    )
}
