export const GetCurrentLocale = () => {
    const locale = localStorage.getItem('@LOCALE')
    const resolvedLocale = locale !== null ? locale : ''
    return { locale: resolvedLocale }
}

export const SetCurrentLocale = (locale: string) => {
    localStorage.setItem("@LOCALE", locale)
}

export const RemoveLocale = () => {
    localStorage.removeItem('@LOCALE')
}
