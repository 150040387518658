import I18n from 'i18n-js'
import _ from 'lodash'

import moment from 'moment'
import 'moment/min/locales'

import { GetCurrentLocale, SetCurrentLocale } from '../helpers/local-storage'

import zh_hk from './locale/zh_hk'
import en from './locale/en'

let Locale: {
    I18n: typeof I18n
    t: (str: string, option: {}) => string
    setLocale(locale: string): void
    init(): void
    currentLocale(): string
} = {
    I18n: I18n,
    t: function (str: string, option: {}) {
        if (_.isEmpty(option)) {
            return I18n.t(str)
        } else {
            return I18n.t(str, option)
        }
    },
    init() {
        // set the default locale is zh_hk
        const { locale } =  GetCurrentLocale()
        const lc = !_.isEmpty(locale) ? locale : 'zh_hk'
        I18n.locale = lc
        moment.locale(lc)

        moment.locale('zh_hk')
        I18n.fallbacks = true
        I18n.translations = {
            zh_hk,
            en,
        }
        window.t = I18n.t
        window.locale = 'zh_hk'
    },
    currentLocale() {
        return I18n.currentLocale()
    },
    setLocale(locale: string) {
        I18n.locale = locale
        window.locale = locale
        moment.locale(_.kebabCase(locale))
        return SetCurrentLocale(locale)
    },
}


export default Locale
