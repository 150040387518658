import { makeStyles } from '@material-ui/core/styles'
// theme
import { Fonts, Colors, Layouts } from '../theme'

export const ContentBriefStyles = makeStyles({
    loadingContainer: {
        ...Layouts.rowWithAllCenter,
        marginTop: 60,
        marginBottom: 24,
        backgroundColor: Colors.white,
    },
    background: {
        ...Layouts.defualtBackgroundStyle,
        marginTop: 32,
    },
    latestOffersContainer: {
        ...Layouts.defaultContainerStyle,
    },
    latestOffersContainerM: {
        ...Layouts.M.defaultContainerStyle,
    },
    latestNewsContainer: {
        ...Layouts.defaultContainerStyle,
        paddingBottom: 80,
        borderBottom: Layouts.borderLine(Colors.blue5)
    },
    aboutUsContainer: {
        ...Layouts.defaultContainerStyle,
        paddingBottom: 80,
        borderBottom: Layouts.borderLine(Colors.blue5)
    },
    headerContainer: {
        ...Layouts.rowWithJCSpaceBetween,
    },
    headerContainerM: {
        ...Layouts.rowWithAllCenter,
        ...Layouts.M.marginHorizontal,
    },
    subHeaderTextContainer: {
        ...Layouts.rowWithAllCenter as {},
    },
    subHeaderTextContainerM: {
        width: '100%',
        ...Layouts.rowWithJCSpaceBetween,
    },
    subHeaderTextAboutUsContainerM: {
        width: '100%',
    },
    subHeaderText: {
        ...Fonts.fontXBig700,
        lineHeight: 0,
    },
    subHeaderTextR: {
        ...Fonts.fontBig700,
        lineHeight: 0,
    },
    subHeaderSubText: {
        ...Fonts.fontMedium400,
        lineHeight: 0,
        marginTop: 8,
        marginLeft: 8,
    },
    subHeaderSubTextR: {
        ...Fonts.fontMedium400,
        lineHeight: 0,
        marginLeft: 8,
    },
    subHeaderAboutUsSubTextM: {
        color: Colors.greyBlack6,
    },
    additionalSubHeaderSubText: {
        lineHeight: '20px',
        marginTop: 0,
        marginLeft: 0,
    },
    buttonsContainer: {
       ...Layouts.rowWithJCEnd,
    },
    arrowCover: {
       ...Layouts.coverLayer as {},
       backgroundColor: Colors.white,
       opacity: 0.5,
    },
    carouselContainer: {
        width: Layouts.contentWidth * 1.024,
    },
    carouselContainerM: {
        width: Layouts.M.contentWidth,
    },
    aboutUsInnerContainer: {
        ...Layouts.rowWithJCSpaceBetween,
    },
    scrollingWrapper: {
        overflowX: 'scroll',
        overflowY: 'hidden',
        display:'flex',
    },
    wrapWrapper: {
        ...Layouts.rowWithWrap as {},
        marginLeft: 24,
    },
})
