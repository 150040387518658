import React, { useEffect, useState } from 'react'
// view
import { HomepageView } from './homepage-view'
// types
import { APIArticle, APICategory } from '../../interfaces'
// helpers
import { ErrorAlert, CheckIsVersionMobile } from '../../helpers'
// apis
import { GetHomePageArticlesAPI, GetCategoriesAPI } from '../../apis'
// libraries
import { useQuery } from 'react-query'

export const HomepageController = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [newsArticles, setNewsArticles] = useState<APIArticle[]>([])
    const [allArticles, setAllArticles] = useState<APIArticle[]>([])

    const isVersionMobile = CheckIsVersionMobile()

    const { error, data, refetch, isFetching, isSuccess } = useQuery<{ newsArticles: APIArticle[], allArticles: APIArticle[] } | null, { message: string }>(['homePageArticles', isVersionMobile], () => GetHomePageArticlesAPI(32, isVersionMobile), {
        enabled: false,
    })

    useEffect(() => {
        if (error !== null) {
            return ErrorAlert({ text1: error.message })
        }

        if (data) {
            setNewsArticles(data.newsArticles)
            setAllArticles(data.allArticles)
        }

        if (isSuccess || !isFetching) {
            setIsLoading(false)
        }

    }, [error, data, isFetching])


    useEffect(() => {
        setIsLoading(true)
        refetch()
    }, [isVersionMobile])


    const ViewProps = {
        isLoading,
        newsArticles,
        allArticles,
    }

    return (
        <HomepageView {...ViewProps} />
    )
}
