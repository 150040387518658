import axios from 'axios'
// helpers
import { APICatchError, APIDomainHTTP, APIHeaders, APIVersion, CancelRequest, ObjectCamelCaseKeys } from '../helpers'
// types
import { APITag } from '../interfaces'

export const GetTagsAPI = async () => {

    const { get, CancelToken } = axios
    const url = `${APIDomainHTTP()}/${APIVersion()}/tags`
    const headers = APIHeaders()

    const source = CancelToken.source()

    CancelRequest.add(
        'GetTags',
        () => source.cancel('GetTags: Operation cancelled by user'),
    )

    try {
        const res: {
            status: number;
            message: string;
            data: {
                tags: APITag[],
            };
        } = await get(url, {
            headers,
            cancelToken: source.token,
        })

        if (res.status !== 200) {
            throw new Error(APICatchError(res.message))
        }

        const { data } = ObjectCamelCaseKeys(res)
        
        const { tags } = data
        return tags
    } catch (e) {
        throw new Error(APICatchError(e.message))
    }
} 
