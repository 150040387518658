import _ from 'lodash'
import React from 'react'
// components
import {
    BeginnerGuide,
    FollowOurSociety,
    HomepageHeader,
} from '../../components'
// styles
import { HomePageStyles } from '../../styles'
// helpers
import { CheckIsVersionMobile } from '../../helpers'

export const HomepageView = (props) => {

    const {
        contentContainer,
    } = HomePageStyles()

    const {
        isLoading,
        newsArticles,
        allArticles,
    } = props

    const isVersionMobile = CheckIsVersionMobile()

    return (
        <div>
            <HomepageHeader />
            <BeginnerGuide />
        </div>
    )
}
