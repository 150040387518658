import _ from 'lodash'
import axios from 'axios'
import { GetTokenStatusAPI } from './get-token-status-api'
// helpers
import { CancelRequest, APICatchError, ObjectSnakeCaseKeys, APIHeaders, APIDomainHTTP, APIVersion } from '../helpers'

export const UploadDataForLoginAPI = async (body: {
    username: string,
    password: string,
}) => {

    const { post, CancelToken } = axios
    const url = `${APIDomainHTTP()}/${APIVersion()}/login`
    const headers = APIHeaders()

    const source = CancelToken.source()

    CancelRequest.add(
        'uploadDataForLogin',
        () => source.cancel('uploadDataForLogin: Operation cancelled by user'),
    )

    try {
        const res: {
            status: number;
            message: string;
        } = await post(url, ObjectSnakeCaseKeys(body), {
            headers,
            cancelToken: source.token,
        })

        if (res.status !== 200) {
            return APICatchError(res.message)
        }
        return res
    } catch (e) {
        return APICatchError(e.message)
    }
} 
