import { makeStyles } from '@material-ui/core/styles'
// theme
import { Layouts, Colors } from '../theme'

const size = (s: number) => {
    return {
        height: s,
        width: s,
    }
}

export const iconStyles = makeStyles({
    small: {
        height: '40vmin',
    },
    medium: {

    },
    big: {

    },
    logo: {
        height: '60%',  
    },
    iconArrowM: {
        width: 20,
        height: 10 * 5 / 6
    },
    iconSearch: {
        height: '19px',
        width: '19px',
    },
    iconAboutUsM: {
        width: 156,
        height: 76,
    },
    iconLatestOffersM: {
        height: 116,
        width: 228,
        borderRadius: 8,
    },
    iconHomePageHeaderButtonsM: {
        ...size(24),
        paddingTop: 32,
    },
    iconHomePageHeaderButtons: {
        ...size(32),
        paddingTop: 32,
    },
    iconHomePagePost: {
        height: 198,
        width: 356,
        borderRadius: 8,
    },
    iconBeginnerGuide: {
        width: '100%'
    },
    iconBeginnerGuideBrief794L: {
        ...size(36),
    },
    iconCommunity: {
        ...size(40),
    },
    iconPlayingMobile:{
        height: 191,
        width: 172,
    },
    iconPlayingMobileM: {
        height: 135,
        width: 121.5,
    },
    iconTickDarkBlue: {
        height: 8,
        width: 12,
    },
    iconFooterShare: {
        ...size(24),
    },
    iconNextDarkBlue: {
        margin: `0 8px`,
    },
    iconBlogShare: {
        ...size(24),
        marginLeft: 12,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    iconDropDownDarkBlue: {
        paddingTop: 2,
    },
    iconPopGuide: {
        ...size(20),
        margin: `0 8px`,
    },
    iconLogoWithTitle: {
        paddingLeft: 24,
    },
    iconLogoWithTitleWhiteM: {
        marginLeft: 24,
    },
    iconMenuDarkBlue: {
        ...size(32),
        paddingRight: 16
    },
    iconCloseDarkBlue: {
        ...size(40),
        paddingRight: 16
    },
    iconEtcM: {
        height: 20,
        width: 25,
    },
    iconEtcMSmall: {
        height: 10,
        width: 15,
    },
    iconBlogShareM: {
        ...size(24),
        marginLeft: 12,
        marginTop: 4,
    },
    iconUser: {
        ...size(48),
        borderRadius: 24,
    },
    iconBannerCompany: {
        ...size(56),
    },
})
