import React from 'react'
// helpers
import { Dictionary, CheckIsVersionMobile } from '../../helpers'
// styles
import { FooterStyles } from '../../styles'
// theme
import { Sprites } from '../../theme'
// components
import { Button } from '../../components'

export const SubFooter = () => {

    const {
        background,
        backgroundM,
        container,
        containerM,
        briefBackground,
        briefContainerWrapperWrapperM,
        briefContainerWrapper,
        briefContainerWrapperM,
        briefContainer,
        headerText,
        headerTextM,
        briefTextM,
        briefText,
    } = FooterStyles()

    const isVersionMobile = CheckIsVersionMobile()

    return (
        <div className={isVersionMobile ? backgroundM : background}>
            <div className={isVersionMobile ? containerM : container}>
                {!isVersionMobile &&
                    <Sprites
                        icon={'IconPlayingMobile'}
                        size={'iconPlayingMobile'}
                        alt={'icon-playing-mobile'}
                    />
                }
                <div className={briefBackground}>
                    <div className={isVersionMobile ? briefContainerWrapperWrapperM : ''}>
                        <div>
                            {isVersionMobile
                                ? <h3 className={headerTextM}>{t('screens.normalText.getLatestMarketNewsFirst')}</h3>
                                : <h2 className={headerText}>{t('screens.normalText.getLatestMarketNewsFirst')}</h2>
                            }

                            <div className={isVersionMobile ? briefContainerWrapperM : briefContainerWrapper}>
                                {Dictionary('latestMarketNewsBriefs')?.map((brief: string, index: number) => {
                                    return (
                                        <div
                                            key={`sub-footer-market-info-brief--${index}`}
                                            className={briefContainer}
                                        >
                                            <Sprites
                                                icon={'IconTickDarkBlue'}
                                                size={'iconTickDarkBlue'}
                                                alt={'icon-tick-dark-blue'}
                                            />
                                            <h6 className={isVersionMobile ? briefTextM : briefText}>{t(`screens.normalText.${brief}`)}</h6>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        {isVersionMobile &&
                            <Sprites
                                icon={'IconPlayingMobile'}
                                size={'iconPlayingMobileM'}
                                alt={'icon-playing-mobile'}
                            />
                        }
                    </div>
                    <Button
                        buttonContainerClass={'joinThroughTGContainer'}
                        buttonTextClass={'joinThroughTGText'}
                        buttonText={t('screens.normalText.joinThroughTelegram')}
                        onClick={() => null}
                    />
                </div>
            </div>
        </div>
    )
}
