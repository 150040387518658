import { useState, useEffect} from 'react';
import { makeStyles,createTheme } from '@material-ui/core/styles';
import {FormLabel,Modal,TextField,Button,Grid,Snackbar,Card,CardActions,Typography,CardActionArea,CardContent,CardMedia} from '@material-ui/core';
import { UseDataFetching, UploadDataForCreateOrUpdateHomepageBannerAPI,GetHomepageBannersAPI,DeleteHomepageBannerAPI } from '../../apis'
import { GetUserToken } from '../../helpers';
import {APIDomainHTTP} from '../../helpers/api-domain-http'
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AdminblogBannerStyle = makeStyles((theme) => ({
    body:{
        paddingTop:10,
        marginTop:10,
        paddingRight:10,
    }





}));



export const AdminblogBanner= () => {
    const classes = AdminblogBannerStyle();
    
  
    
    useEffect(() => {


    }, []);

    return <div>
       <div className={classes.body}>
        <div>
            <FormLabel component="legend">(更改圖片需要較長時間，建議重新添加banner)
                <button  style={{marginLeft:5,width:30}}>+</button>
            </FormLabel>
        </div>



       </div>
    </div>
}