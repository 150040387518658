import React from "react";
import PropTypes from "prop-types";
import { NavLink, useLocation } from "react-router-dom";
// @material-ui/core components
import classNames from "classnames";
import {Drawer,Divider} from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";

import {sidebarStyle} from "../../styles/sidebar-styles";

const useStyles = sidebarStyle;

export default function AdminSidebar(props:any) {
    const classes = useStyles();
    let location = useLocation();
    // verifies if routeName is the one active (in browser input)
    function activeRoute(routeName:any) {
      return location.pathname === routeName;
    }
    const { color, logo, image, logoText, routes } = props;
    
    var links = (
      <List className={classes.list}>
        {routes.map((prop:any, key:any) => {
          var activePro = "";
          var listItemClasses;
          listItemClasses = classNames({
            [" " + classes[color]]: activeRoute(prop.layout + prop.path),
          });
          const whiteFontClasses = classNames({
            [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
          });
          return (
              

            <NavLink
              to={prop.layout + prop.path}
              className={activePro + classes.item}
              activeClassName="active"
              
              key={key}
            >
              <ListItem button className={classes.itemLink + listItemClasses}>
                {typeof prop.icon === "string" ? (
                  <Icon
                    className={classNames(classes.itemIcon, whiteFontClasses, {
                      [classes.itemIconRTL]: props.rtlActive,
                    })}
                  >
                    {prop.icon}
                  </Icon>
                ) : (
                  <prop.icon
                    className={classNames(classes.itemIcon, whiteFontClasses, {
                      [classes.itemIconRTL]: props.rtlActive,
                    })}
                  />
                )}
                <ListItemText
                  primary={props.rtlActive ? prop.rtlName : prop.name}
                  className={classNames(classes.itemText, whiteFontClasses, {
                    [classes.itemTextRTL]: props.rtlActive,
                  })}
                  disableTypography={true}
                />
              </ListItem>
            </NavLink>




          );
        })}
      </List>
    );
    var brand = (
      <div className={classes.logo}>
        <a
          href=""
          className={classNames(classes.logoLink, {
            [classes.logoLinkRTL]: props.rtlActive,
          })}
        >
          <div className={classes.logoImage}>
            <img src={logo} alt="logo" className={classes.img} />
          </div>
          {/* {logoText} */}
        </a>
      </div>
    );
    return (
      <div>
        <div className={classes.brand}>{brand}</div>
        
        <Drawer
          anchor="left"
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
        >
          {/* {brand} */}
          <div className={classes.sidebarWrapper}>{links}</div>
          <div className={classes.background}/>
        </Drawer> 
      </div>
    );

}


AdminSidebar.propTypes = {
    rtlActive: PropTypes.bool,
    color:PropTypes.string,
    handleDrawerToggle: PropTypes.func,
    bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
    logo: PropTypes.string,
    image: PropTypes.string,
    logoText: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object),
    open: PropTypes.bool,
};
