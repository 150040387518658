import _ from 'lodash'
import * as muiColors from '@material-ui/core/colors'

// for more information about mui colors, visit https://material-ui.com/customization/color/

const muiColorConfig = (color: string): string => {
    const colorString = _.lowerCase(color)
    const colorSplit = colorString.split(' ')

    // let clr: string | undefined;
    const clr: string | undefined = 
        colorSplit.length === 2
            ? muiColors[colorSplit[0]][colorSplit[1]]
            :  colorSplit.length === 3
                ? muiColors[colorSplit[0]][`${ _.toUpper(colorSplit[1])}${colorSplit[2]}`]
                : undefined
    
    return clr !== undefined ? clr : 'transparent'
}

export const Colors = {
    // colors in material ui
    mui: muiColorConfig,
    // other custom colors...
    black0: '#000000',
    black1: '#202020',
    blue1: '#E3F0F4',
    blue2: '#004269',
    blue3: '#B9DAE6',
    blue4: '#297FA9',
    blue5: '#E3F0F4',
    blue6: '#1997F3',
    blue7: '#3BABDD',
    blue8: '#90C3D6',
    red0: '#CA0813',
    white: '#ffffff',
    grey0: '#F4F4F4',
    grey1: '#C4C4C4',
    greyBlack1: 'rgba(0, 0, 0, 0.1)',
    greyBlack5: 'rgba(0, 0, 0, 0.5)',
    greyBlack6: 'rgba(0, 0, 0, 0.6)',
    greyBlack85: 'rgba(0, 0, 0, 0.85)',
    greyWhite5: 'rgba(255, 255, 255, 0.5)',
    orange0: '#FBB13B',
    orange1: '#FFF1D6',
    transparent: 'transparent',
}
