import _ from 'lodash'
import axios from 'axios'

const CancelToken = axios.CancelToken
let _cancel: {} = {}

const cancel = (keys: string[] = []) => {
    _.forEach(keys, (key: string) => {
        if (_.isFunction(_cancel[key])) {
            _cancel[key]()
        }
    })
    _cancel = _.omit(_cancel, keys)
}

/**
 * cancelAll
 *
 * Only use this if you are sure you know what you are doing as this will cancel
 * ALL requests, i.e. if called in componentWillUnmount it will potentially cancel
 * the request on the next mounted component depending on when componentWillUnmount is executed.
 *
 * If in doubt always pass the keys to cancel
 *
 * @param {*} keys
 */
const cancelAll = (keys = []) => {
    cancel(keys || Object.keys(_cancel))
}

// TODO: not currently used
const cancelToken = (key: string) => {
    return new CancelToken(c => {
        _cancel[key] = () => c(`${key}: Operation cancelled by user`)
    })
}

const add = (key: string, c: () => void) => {
    _cancel[key] = c
}

export const CancelRequest = {
    cancelToken,
    cancelAll,
    cancel,
    add,
}
