import { useEffect } from 'react'
import 'react-circular-progressbar/dist/styles.css';
import './App.css';

// libraries
import Locale from './services/i18n'
import dotenv from 'dotenv'
import { QueryClient, QueryClientProvider } from 'react-query'

// helpers
import { RemoveCurrentArticleCategories } from './helpers';

// routes
import { Route } from './routes'

dotenv.config()
Locale.init()
const queryClient = new QueryClient()

const App = () => {

  useEffect(() => {
    //RemoveCurrentArticleCategories()
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <Route />
    </QueryClientProvider>
  );
}

export default App;
