import { makeStyles } from '@material-ui/core/styles'
// theme
import { Colors, Layouts, Fonts } from '../theme'

const homePageCommunityContainer = (color: string) => {
    return {
        height: 32,
        width: 98,
        backgroundColor: color,
        "&:hover": {
            backgroundColor: color,
            opacity: 0.6,
        }
    }
}

const defaultLabelContainer = (color: string) => {
    return {
        ...Layouts.rowWithAllCenter,
        marginRight: 8,
        marginTop: 12,
        padding: `8px 12px`,
        borderRadius: 20,
        backgroundColor: color,
        "&:hover": {
            backgroundColor: color,
        },
    }
}

const pageIndexContainer = (color: string) => {
    return {
        minWidth: 48,
        height: 48,
        width: 48,
        borderRadius: 24,
        backgroundColor: color,
        '&:hover': {
            backgroundColor: color,
        },
    }
}

const pageIndexContainerM = (color: string) => {
    return {
        minWidth: 40,
        height: 40,
        width: 40,
        borderRadius: 20,
        backgroundColor: color,
        '&:hover': {
            backgroundColor: color,
        },
    }
}

const defaultButtonContainer = (color: string) => {
    return {
        height: 48,
        width: 168,
        backgroundColor: color,
        "&:hover": {
            backgroundColor: color,
        },
    }
}

const defaultButtonContainerM = (color: string) => {
    return {
        height: 48,
        width: '100%',
        backgroundColor: color,
        "&:hover": {
            backgroundColor: color,
        },
    }
}

const homePageHeaderButtonContainer = {
    borderRadius: 16,
    padding: 1,
    backgroundImage: `linear-gradient(white, white), linear-gradient(to bottom, ${Colors.white}, ${Colors.white}, ${Colors.blue1})`,
    backgroundOrigin: 'border-box',
    backgroundClip: 'content-box, border-box',
    borderImageSlice: 1,
}

const homePageHeaderButtonContainerM = {
    ...homePageHeaderButtonContainer,
    width: 98,
    height: 93,
    marginBottom: 16,
}


export const buttonStyles = makeStyles({
    buttonContainer: { 
    },
    buttonText: {
    },
    additionalButtonContainer: {
    },
    homePageHeaderButtonContainer: {
        ...homePageHeaderButtonContainer,
        width: 168,
        height: 140,
        '&:hover':{
            backgroundImage: `linear-gradient(${Colors.blue3}, ${Colors.blue3}), linear-gradient(to bottom, ${Colors.white}, ${Colors.blue3})`,
        }
    },
    homePageHeaderButtonContainerM: {
        ...homePageHeaderButtonContainerM,
    },
    homePageHeaderButtonContainerM16: {
        ...homePageHeaderButtonContainerM,
        marginRight: 16,
    },
    marginRight16: {
        marginRight: 16
    },
    arrowContainer: {
        height: 40,
        minWidth: 52,
    },
    homePageKnowMoreBasicContainer: {
        ...Layouts.rowWithAllCenter,
        marginTop: 12,
        padding: `8px 16px`,
        border: Layouts.borderLine(Colors.blue4),
        borderRadius: 8,
        backgroundColor: Colors.white,
    },
    homePageKnowMoreBasicText: {
        ...Fonts.fontMedium700,
        color: Colors.blue2,
    },
    homePageNewslabelContainer: {
        ...Layouts.rowWithAllCenter,
        marginRight: 8,
        padding: `4px 8px`,
        borderRadius: 20,
        backgroundColor: Colors.blue5,
    },
    homePageNewslabelText: {
        ...Fonts.fontSmall400,
        color: Colors.blue2,
    },
    homePageCommunityContainerM: {
        ...homePageCommunityContainer(Colors.blue2),
    },
    homePageCommunityYoutubeContainer: {
        ...homePageCommunityContainer(Colors.red0),
    },
    homePageCommunityTelegramContainer: {
        ...homePageCommunityContainer(Colors.blue7),
    },
    homePageCommunityInstagramContainer: {
        ...homePageCommunityContainer(Colors.blue6),
    },
    homePageCommunityText: {
        ...Fonts.fontSmall700,
        color: Colors.white,
        lineHeight: 0,
    },
    joinThroughTGContainer: {
        ...Layouts.rowWithAllCenter,
        height: 48,
        width: 327,
        backgroundColor: Colors.orange0,
    },
    joinThroughTGText: {
        ...Fonts.fontMedium700,
        color: Colors.greyBlack85,
        lineHeight: 0,
    },
    navigationBarButtonContainer: {
        width: '92px',
        height: '50%',
        padding: '0 12px',
        borderRadius: '18px',      
        "&:hover": {
            height: '50%',
            backgroundColor: Colors.greyWhite5,
        },
    },
    articlesTitleContainer: {
        height: 48,
        width: 120,
    },
    articlesTitleText: {
        ...Fonts.fontMediumBig700,
    },
    articleslabelContainer: {
        ...defaultLabelContainer(Colors.blue5),
    },
    selectedArticleslabelText: {
        ...Fonts.fontSmall400,
        color: Colors.white,
    },
    selectedArticleslabelContainer: {
        ...defaultLabelContainer(Colors.blue2)
    },
    articlesFilterConfirmContainer: {
        ...defaultButtonContainer(Colors.blue2)
    },
    articlesFilterCancelContainer: {
        ...defaultButtonContainer(Colors.transparent)
    },
    articlesFilterConfirmContainerM: {
        ...defaultButtonContainerM(Colors.blue2),
        marginBottom: 4,
    },
    articlesFilterCancelContainerM: {
        ...defaultButtonContainerM(Colors.transparent)
    },
    articlesFilterConfirmText: {
        ...Fonts.fontMediumBig700,
        color: Colors.white,
    },
    articlesFilterCancelText: {
        ...Fonts.fontMediumBig700,
        color: Colors.blue2,
    },
    selectedPageIndexContainer: {
        ...pageIndexContainer(Colors.blue1)
    },
    pageIndexContainer: {
        ...pageIndexContainer(Colors.transparent)
    },
    selectedPageIndexContainerM: {
        ...pageIndexContainerM(Colors.blue1)
    },
    pageIndexContainerM: {
        ...pageIndexContainerM(Colors.transparent)
    },
    selectedPageIndexText: {
        ...Fonts.fontMediumBig700,
        color: Colors.blue2,
        lineHeight: 0,
    },
    pageIndexText: {
        ...Fonts.fontMediumBig700,
        color: Colors.blue2,
        lineHeight: 0,
    },
    selectedPageIndexTextM: {
        ...Fonts.fontMedium700,
        color: Colors.blue2,
        lineHeight: 0,
    },
    pageIndexTextM: {
        ...Fonts.fontMedium700,
        color: Colors.blue2,
        lineHeight: 0,
    },
    // blogLabelContainer: {
    //     ...defaultLabelContainer(Colors.blue5),
    // },
    // blogLabelText: {
    //     ...Fonts.fontSmall400,
    //     color: Colors.blue2,
    // },
    coinAnalysisMoreFeaturesContainer: {
       padding: 0,
    },
    coinAnalysisApplyContainer: {
        ...defaultButtonContainer(Colors.orange0)
    },
    coinAnalysisAnalyzeContainer: {
        ...defaultButtonContainer(Colors.transparent),
    },
    coinAnalysisApplyText: {
        ...Fonts.fontMediumBig700,
        color: Colors.greyBlack85,
    },
    coinAnalysisAnalyzeText: {
        ...Fonts.fontMediumBig700,
        color: Colors.blue2,
    },
    bannerApplyDiscountContainer: {
        marginRight: 58,
        marginBottom: 2,
        height: 56,
        width: 161.5
    },
    bannerApplyDiscountContainerM : {
        marginRight: 50,
        height: 48,
        width: 245.5
    },
    searchContainer: {
        ...defaultButtonContainer(Colors.blue2),
        marginLeft: 24,
    },
    searchText: {
        ...Fonts.fontMediumBig700,
        color: Colors.white,
    },
    askChingContainer: {
        ...defaultButtonContainer(Colors.blue2),
        marginTop: 24,
        width: 248,
    },
    askChingText: {
        ...Fonts.fontMedium700,
        color: Colors.white,
    },
    blogLabelContainerM: {
        ...defaultLabelContainer(Colors.blue5),
        padding: `4px 8px`,
    },
    blogLabelTextM: {
        ...Fonts.fontSmall400,
        color: Colors.blue2,
    },
})
