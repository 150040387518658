import _ from 'lodash'
import React from 'react'
// components
import { ContentBriefItem, LoadingIndicator, Button, NotFound } from '../../components'
// styles
import { SearchStyles } from '../../styles'
// theme
import { Sprites } from '../../theme'

export const SearchView = (props) => {

    const {
        isLoading,
        articles,
        keywords,
        onChangeText,
        onClickSearch,
    } = props

    const {
        background,
        container,
        textInputContainer,
        iconContainer,
        searchContainer,
        contentContainer,
        articlesContainer,
        additionalArticleContainer,
    } = SearchStyles()

    return (
        <div className={background}>
            <div className={container}>
                <div>
                    <div className={searchContainer}>
                        <div className={searchContainer}>
                            <div className={iconContainer}>
                                <Sprites
                                    icon={'IconSearch'}
                                    size={'iconSearch'}
                                    alt={'icon-search'}
                                />
                            </div>
                            <input
                                type="text"
                                value={keywords}
                                onChange={onChangeText}
                                className={textInputContainer}
                                placeholder={t('screens.normalText.search')}
                            />
                        </div>
                        <Button
                            buttonContainerClass={'searchContainer'}
                            buttonTextClass={'searchText'}
                            buttonText={t('screens.normalText.search')}
                            onClick={onClickSearch}
                        />
                    </div>
                    {!isLoading && !_.isEmpty(articles)
                        ? <div className={articlesContainer}>
                            {articles.map((article, index) => {
                                return (
                                    <div
                                        key={`article--${index}`}
                                        className={(index + 1) % 3 !== 0 ? additionalArticleContainer : ''}
                                    >
                                        <ContentBriefItem
                                            isCategoryOffers={false}
                                            isCategoryNews={true}
                                            isCategoryAboutUs={false}
                                            item={article}
                                            categories={article?.categories}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                        : <div className={contentContainer}>
                            {isLoading
                                ? <LoadingIndicator />
                                : articles !== null
                                    ? <NotFound />
                                    : null
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
