import { makeStyles } from '@material-ui/core/styles'
// theme
import { Fonts, Colors, Layouts } from '../theme'

export const CoinAnalysisStyles = makeStyles({
    container: {
        ...Layouts.rowWithJCSpaceBetweenAIStart,
        padding: 24,
        width: Layouts.contentWidth,
        borderRadius: 4,
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.32)'
    },
    iconContainer: {
        height: 80,
        width: '25%',
        border: Layouts.borderLine(Colors.greyBlack1, 1)
    },
    coinTypeBriefContainer: {
        paddingLeft: 24,
        width: '40%',
    },
    coinNameContainer: {
        ...Layouts.rowWithJCStart,
    },
    coinNameText: {
        ...Fonts.fontBig700,
        margin: 0,
    },
    coinMarksContainer: {
        marginLeft: 8,
        padding: `3px 6px`,
        borderRadius: 4,
        backgroundColor: Colors.blue2,
    },
    coinMarksText: {
        ...Fonts.fontSmall700,
        color: Colors.white,
    },
    coinAnalysisTypesContainer: {
        ...Layouts.rowWithJCSpaceBetween,
        marginTop: 12,
        marginBottom: 16,
    },
    coinAnalysisTypeContainer: {
        height: 68,
        width: 164,
        ...Layouts.columnWithJCSpaceBetween as {},
    },
    coinAnalysisTypeTitleText: {
        ...Fonts.fontSmall400,
        color: Colors.greyBlack6,
    },
    coinAnalysisTypeValueText: {
        ...Fonts.fontMedium400,
        marginRight: 32,
        color: Colors.greyBlack6,
    },
    AdditionalCoinAnalysisTypeContainer: {
        height: 68,
        width: 104,
        paddingLeft: 16,
        borderLeft: Layouts.borderLine(Colors.greyBlack1),
    },
    coinAnalysisTypeValueH1Text: {
        ...Fonts.font2XBig700,
        margin: 0,
        marginTop: 12,
    },
    moreFeaturesContainer: {
        ...Layouts.rowWithAllCenter,
    },
    moreFeaturesPlainText: {
        ...Fonts.fontMedium700,
        marginRight: 4,
        color: Colors.blue2,
    },
    discountBriefText: {
        ...Fonts.fontMedium400,
        color: Colors.greyBlack6,
    },
    discountContainer: {
        marginLeft: 64,
    },
    discountDetailContainer: {
        ...Layouts.rowWithJCSpaceBetween,
        margin: `16px 0`,
        padding: 8,
        border: Layouts.borderLine(Colors.greyBlack5, 1, 'dashed'),
        borderRadius: 4,
        backgroundColor: Colors.orange1,
    },
    discountDetailText1: {
        ...Fonts.fontSmall400,
        paddingLeft: 8,
    },
    discountDetailText2: {
        ...Fonts.fontSmall700,
    },
    buttonsContainer: {
        ...Layouts.rowWithJCStart,
    },
})
