import { makeStyles } from '@material-ui/core/styles'
// theme
import { Fonts, Colors, Layouts } from '../theme'
import * as Svgs from '../theme/sprites/svgs'

export const DiscountBannerStyles = makeStyles({
    container: {
        ...Layouts.defualtBackgroundStyle,
        backgroundImage: `url(${Svgs['IconBannerDiscount']})`,
        height: 174,
        width: 744,
        borderRadius: 16,
    },
    containerM: {
        ...Layouts.defualtBackgroundStyle,
        flexDirection: 'column',
        backgroundImage: `url(${Svgs['IconBannerDiscountMobile']})`,
        margin: `24px 0`,
        height: 192,
        width: 327,
        borderRadius: 16,
    },
    innerContainer: {
        ...Layouts.rowWithJCSpaceBetween,
        width: '100%',
        padding: 32,
    },
    iconContainer: {
        ...Layouts.rowWithAllCenter,
        width: 160,
        height: 90,
        borderRadius: 8,
        backgroundColor: Colors.white,
    },
    iconContainerM: {
        ...Layouts.rowWithAllCenter,
        width: 120,
        height: 67.5,
        borderRadius: 8,
        backgroundColor: Colors.white,
    },
    iconDiscountDetailContainer: {
        ...Layouts.rowWithAllCenter,
    },
    iconDiscountDetailContainerM: {
        ...Layouts.rowWithAllCenter,
        margin: `9px 32px`,
    },
    foreverDiscountPlainText: {
        ...Fonts.fontMedium400,
        color: Colors.white,
    },
    foreverDiscountPlainTextM: {
        ...Fonts.fontSmall400,
        color: Colors.white,
    },
    foreverDiscountTextContainer: {
        marginTop: 8,
        marginLeft: 16,
    },
    foreverDiscountTextContainerM: {
        marginLeft: 16,
    },
    foreverDiscountTextInnerContainer: {
        ...Layouts.rowWithJCStart,
    },
    foreverDiscountText1: {
        ...Fonts.font5XBig1000,
        margin: 0,
        color: Colors.white,
    },
    foreverDiscountText1M: {
        ...Fonts.font2XBig1000,
        margin: 0,
        color: Colors.white,
    },
    foreverDiscountText2: {
        ...Fonts.font2XBig1000,
        margin: 0,
        color: Colors.orange0,
        textDecoration: 'underline',
        fontFamily: 'Impact',
        paddingLeft: 4,
    },
    foreverDiscountText2M: {
        ...Fonts.fontBig1000,
        margin: 0,
        color: Colors.orange0,
        textDecoration: 'underline',
        fontFamily: 'Impact',
        paddingLeft: 4,
    },
    applyPlainText: {
        ...Fonts.fontMediumBig700,
    },
    timeRemainingContainer: {
        ...Layouts.rowWithAllCenter,
        marginBottom: 2,
    },
    timeRemainingContainerM: {
        ...Layouts.rowWithAllCenter,
        marginTop: 4,
        marginBottom: 2,
    },
    timeRamainingText: {
        ...Fonts.fontSmall400,
        marginRight: 8,
        color: Colors.white,
    },
    timeRamainingPlainText: {
        ...Fonts.fontMedium700,
        marginRight: 8,
        color: Colors.white,
    },
    timeRemainingValueText: {
        ...Fonts.fontXBig1000,
        marginRight: 2,
        marginBottom: 4,
        color: Colors.white,
    }
})
