import axios from 'axios'
// helpers
import { CancelRequest, APICatchError, ObjectSnakeCaseKeys, APIHeaders, APIDomainHTTP, APIVersion } from '../helpers'
import { RemoveUserToken} from '../helpers/local-storage'

export const DeleteCategoryAPI = async (body: {
    categoryId : any,
}) => {

    const { post, CancelToken } = axios
    const url = `${APIDomainHTTP()}/${APIVersion()}/category/delete/`+body.categoryId
    const headers = APIHeaders()

    const source = CancelToken.source()

    CancelRequest.add(
        'DeleteCategoryAPI',
        () => source.cancel('DeleteCategoryAPI: Operation cancelled by user'),
    )

    try {
        const res: {
            status: number;
            message: string;
        } = await post(url, ObjectSnakeCaseKeys(body), {
            headers,
            cancelToken: source.token,
        })
        console.log(res.status);
        if (res.status !== 200) {
            return APICatchError(res.message)
        }
        
        return res
    } catch (e) {
        if (e.message === 'Request failed with status code 403'){
            RemoveUserToken();
        }
        return APICatchError(e.message)
    }
} 
