import { makeStyles } from '@material-ui/core/styles'
// theme
import { Layouts } from '../theme'

export const BaseStyles = makeStyles({
    background: {
        minWidth: Layouts.contentWidth + 96,
    },
    backgroundM: {
        minWidth: Layouts.M.contentWidth,
    }
})
