import { makeStyles } from '@material-ui/core/styles';
import {ArticleEditor} from '../../components'
import { InputAdornment,TextField,Chip,FormLabel,Tooltip,Grid ,Select,FormControlLabel} from '@material-ui/core';
import { useState, useEffect,Fragment } from 'react';
import { UseDataFetching, GetArticleAPI ,GetTagsListAPI,GetCategoryListAPI,GetAdminUsersAPI} from '../../apis'
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const NewArticlesStyle = makeStyles((theme) => ({
    body:{
        marginLeft:8,
        marginRight:15,
    },
    wrapper:{
       marginTop:20,
       paddingBottom:35,
    },
    group:{
        backgroundColor:"white",
        border:1,
        marginTop:10,
    },

    groupTitle:{
        marginTop:15,
    },
    select:{
        backgroundColor:"white",
    },
    groupNoMargin:{
        border:1,
        borderRadius:4,
        marginTop:3,
        borderStyle:"solid",
        borderColor:"#bdbdbd",
    },
    
    tagGroup:{
        padding:10,
        minHeight:30,
        borderRadius:4,
        backgroundColor:"white",
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
        margin: theme.spacing(0.5),
        },
     },
    
    title:{
        width:"100%",
        borderRadius:4,
        marginTop:3,
        backgroundColor:"white",
    },

}));

export const AdminNewArticles = (props:any) => {
    var d = new Date();
    var year = d.getFullYear();
    var month = d.getMonth() + 1;
    var day = d.getDate();
    var monthTime =""
    var dayTime =""
    if (month < 10) {
        monthTime = "0" + month;
    }else{
        monthTime = String(month);
    }
    if (day < 10) {
        dayTime = "0" + day;
    }else{
        dayTime = String(day);
    }
    var nowDate = year + "-" + monthTime + "-" + dayTime;
    const classes = NewArticlesStyle();
    const [title, setTitle] = useState('');
    const [path, setPath] = useState('');
    const [postDate, setPostDate] = useState(nowDate);
    const [readingTime, setReadingTime] = useState(0);
    const [imageAlt, setImageAlt] = useState('');
    const [metaTitle,setMetaTitle] = useState('');
    const [postId, setPostId] = useState(0);
    const [category, setCategory] = useState([{ category_cn: '', category_en: '',category_id:0 }]);
    const [tags, setTags] = useState([{ tag_cn: '', tag_en: '',tag_id:0 }]);
    const [initialValue, setInitialValue] = useState('');
    const [description, setDescription] = useState('');
    const [headerElement, setHeaderElement] = useState('');
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const [mobileSourceCode,setMobileSourceCode] = useState('');
    const [desktopSourceCode,setDesktopSourceCode] = useState('');
    const [qaCount ,setQaCount ] = useState(1);
    const [q1 ,setQ1] = useState("");
    const [q2 ,setQ2] = useState("");
    const [q3 ,setQ3] = useState("");
    const [q4 ,setQ4] = useState("");
    const [a1 ,setA1] = useState("");
    const [a2 ,setA2] = useState("");
    const [a3 ,setA3] = useState("");
    const [a4 ,setA4] = useState("");
    const [link,setLink] = useState('');
    const [bottomBanner, setBottomBanner] = useState(false);
    const [file,setFile] = useState(null);
    const [showUploadImagebutton, setShowUploadImagebutton] = useState('');
    const [showImage, setShowImage] = useState('none');
    const [button,setButton] = useState('');
    const [tagRows ,setTagRows ] = useState([{ tag_cn: '', tag_en: '',tag_id:0 }]);
    const [authorRows ,setAuthorRows ] = useState([{user_id:0,nickname:'',username:''}]);
    const [authorId,setAuthorId] = useState(0);
    const [categoryRows ,setCategoryRows ] = useState([{ category_cn: '', category_en: '',category_id:0 }]);
    const GetTagsData =  async()=> {
        var list:any =[];
        const  {error,results} =  await UseDataFetching(GetTagsListAPI({})) as any;
        (results['tags']||[]).map(function(result:any){
           list.push({'tag_id':result.tag_id,'tag_cn':result.tag_cn,'tag_en':result.tag_en });
        })
        setTagRows(list);
    }
    const GetCategoryData =  async()=> {
        var list:any =[];
        const  {error,results} =  await UseDataFetching(GetCategoryListAPI({})) as any;
        (results['category']||[]).map(function(result:any){
           list.push({'category_id':result.category_id,'category':result.category_cn + ' / '+ result.category_en ,'category_cn':result.category_cn,'category_en':result.category_en});
        })
        setCategoryRows(list);
    }
    const handleAuthorChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setAuthorId(Number(event.target.value as any));
      };
    const qaChange = (feature:any,featureNumber:any) => {
        switch (featureNumber){
            case 1:
                setQ1(feature);
                break
            case 2:
                setQ2(feature);
                break
            case 3:
                setQ3(feature);
                break
            case 4:
                setQ4(feature);
                break
            case 5:
                setA1(feature);
                break
            case 6:
                setA2(feature);
                break
            case 7:
                setA3(feature);
                break
            case 8:
                setA4(feature);
                break    
        }
    }
    const addQa = async()=>{
        if (qaCount!=4){
            setQaCount(qaCount+1)
        }
    }
    const cutQa = async()=>{
        if (qaCount>0){
            switch (qaCount){
                case 1:
                    setA1('')
                    setQ1('')
                    break
                case 2:
                    setA2('')
                    setQ2('')
                    break
                case 3:
                    setA3('')
                    setQ3('')
                    break
                case 4:
                    setA4('')
                    setQ4('')
                    break
            }
            setQaCount(qaCount-1)
        }
    }
    const GetAuthorsData =  async()=> {
        var list:any =[];
        const  {error,results} =  await UseDataFetching(GetAdminUsersAPI({})) as any;
        (results['users']||[]).map(function(result:any){
          list.push({'user_id':result.user_id,'nickname':result.nickname,'username':result.username});
        })
        setAuthorRows(list);
      };
    const GetArticleData = async(postId:number) => {
        const { error , results } = await UseDataFetching(GetArticleAPI(postId)) as any
        setTitle(results['title']);
        setInitialValue(results['content']);
        setHeaderElement(results['header_element']);
        setDescription(results['description']);
        setMetaTitle(results['meta_title'])
        setCategory(results['category'])
        setTags(results['tags'])
        setPath(results['path'])
        setAuthorId(results['user_id'])
        setImageAlt(results['image_alt'])
        setReadingTime(results['reading_time'])
        setPostDate(results['post_date'])
        setDescription(results['description'])
        setMetaTitle(results['meta_title'])
        setButton(results['button'])
        setShowUploadImagebutton('none')
        setShowImage('')
        setMobileSourceCode(results['mobile_source_code'])
        setDesktopSourceCode(results['desktop_source_code'])
        setLink(results['link'])
        if (results['desktop_source_code']!==""){
            setBottomBanner(true);
        }
        setQaCount(results.questions?.length||0)
        for (var i = 0; i < results.answers.length||0; i++) { 
            if (i === 0){
                setA1(results.answers[i])
            }else if (i === 1){
                setA2(results.answers[i])
            }
            else if (i === 2){
                setA3(results.answers[i])
            }
            else if (i === 3){
                setA4(results.answers[i])
            }
         }
         for (var i = 0; i < results.questions.length; i++) { 
            if (i === 0){
                setQ1(results.questions[i])
            }else if (i === 1){
                setQ2(results.questions[i])
            }
            else if (i === 2){
                setQ3(results.questions[i])
            }
            else if (i === 3){
                setQ4(results.questions[i])
            }
         }
       
        setImagePreviewUrl("https://cryptowesearch.s3.ap-southeast-1.amazonaws.com/blog_img/"+results['post_uuid']);
    };
    const handleImageChange = (e:any) => {
        e.preventDefault();
        var reader = new FileReader();
        setShowUploadImagebutton('none');
        setShowImage('none');
        (document.getElementById("result")as any).innerHTML=""
        reader.onload = function (event) {
            var txt = (event.target as any).result;
            var img = document.createElement("img");
            img.src = txt;
            img.width=320;
            img.height=180;
            (document.getElementById("result") as any).appendChild(img);
        };
        
        if (e.target.files[0]){
            reader.readAsDataURL(e.target.files[0]);
            setFile(e.target.files[0]); 
        }else{
            setShowUploadImagebutton('');
        }

    }

    var id = props.location.search.substr(5,10);
    useEffect(() => {
        GetAuthorsData();
        if (id != ""){
            (document.getElementById("result")as any).innerHTML=""
            setShowImage('none');
            setPostId(id);
            GetArticleData(id);
            GetTagsData();
            GetCategoryData();

        }else{
            setAuthorId(authorRows[0]['user_id'])
            setCategory([{ category_cn: '', category_en: '',category_id:0 }]);
            setTags([{ tag_cn: '', tag_en: '',tag_id:0 }]);
            setTitle('');
            setPath('');
            setDescription('');
            setMetaTitle('');
            setReadingTime(0);
            setPostId(0);
            GetCategoryData();
            GetTagsData();
            setButton('');
            setBottomBanner(false);
            setQaCount(0);
            setQ1('');setQ2('');setQ3('');setQ4('');
            setA1('');setA2('');setA3('');setA4('');
        }

    }, [id]);
    return <div className={classes.body}>
        <div className={classes.groupTitle}>
            <FormLabel component="legend">標題</FormLabel>
        </div>
        <div className={classes.title}>
            <TextField id="title" 
            value={title}
            onChange={event => setTitle(event.target.value)}
            fullWidth 
            required
            variant="outlined" />
        </div>
        <div className={classes.groupTitle}>
            <FormLabel component="legend">分類</FormLabel>
        </div>
        <div className={classes.groupNoMargin}>
        <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={categoryRows}
                disableCloseOnSelect
                value={category?category[0]?(category[0].category_id===0?[]:category):[]:[]}                  
                onChange={(event, value) => setCategory(value)} 
                getOptionSelected = {(option, value) => option.category_id === value.category_id}
                getOptionLabel={(option) => option.category_cn?option.category_cn:""}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option.category_cn}
                          color="primary"
                          {...getTagProps({ index })}
                        />
                      ))
                }
                renderOption={(option, { selected }) => (
                    <Fragment>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option.category_cn}
                    </Fragment>
                )}
                style={{ width: '100%', backgroundColor:"white",borderRadius:4 ,border:1}}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined"   placeholder="" />
                )}
                />
        </div>
        <div className={classes.groupTitle}>
            <FormLabel component="legend">標籤（最多3個）</FormLabel>
        </div>
        <div className={classes.groupNoMargin}>
        <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={tagRows}
                disableCloseOnSelect
                defaultValue={[]}
                value={tags?[0]?(tags[0]?.tag_id===0?[]:tags):[]:[]}       
                getOptionLabel={(option) => option.tag_cn?option.tag_cn:""}
                getOptionSelected = {(option, value) => option.tag_id === value.tag_id}
                onChange={(event, value) => setTags(value)} 
                renderTags={(value, getTagProps) =>
                    value.map((option, index,value) => (
                        <Chip
                          variant="outlined"
                          label={option.tag_cn}
                          color="secondary"
                          {...getTagProps({ index })}
                        />
                      ))
                }
                renderOption={(option, { selected }) => (
                    <Fragment>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option.tag_cn}
                    </Fragment>
                )}
                style={{ width: '100%', backgroundColor:"white",borderRadius:4 ,border:1}}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined"  placeholder="" />
                )}
                />
            
        </div>
        <div className={classes.groupTitle}>
            <FormLabel component="legend">Writer</FormLabel>
        </div>
        <div className={classes.groupNoMargin}>
            <Select
            value={authorId}
            onChange={handleAuthorChange}
            fullWidth
            className={classes.select}
            native
            variant="outlined" 
            >
            {
                authorRows.map((option, index) => (
                <option key={option.user_id} value={option.user_id}>{option.nickname}</option>
                ))
            }
            
        </Select>
            
        </div>
        <div className={classes.groupTitle}>
            <FormLabel >Meta title</FormLabel>
        </div>
        <div className={classes.title}>
            <TextField id="schema"
              multiline 
              fullWidth 
              value={metaTitle}
              onChange={event => setMetaTitle(event.target.value)}
              variant="outlined" />
        </div>

        <div className={classes.groupTitle}>
            <FormLabel >Description</FormLabel>
        </div>
        <div className={classes.title}>
            <TextField id="schema"
              multiline 
              fullWidth 
              value={description}
              onChange={event => setDescription(event.target.value)}
              variant="outlined" />
        </div>

        <div className={classes.groupTitle}>
            <Tooltip disableFocusListener title='Example: <meta name="keywords" content="crypto">'><FormLabel >Header element</FormLabel></Tooltip>
        </div>
        <div className={classes.title}>
            <TextField id="schema"
              multiline 
              fullWidth 
              value={headerElement}
              onChange={event => setHeaderElement(event.target.value)}
              variant="outlined" />
        </div>

        <Grid container spacing={1}>
            <Grid item xs={6}>
                <div className={classes.groupTitle}>   
                    <FormLabel component="legend">Route path</FormLabel>
                </div>
                <div className={classes.title}>
                <TextField id="path" 
                value={path}
                onChange={event => setPath(event.target.value)}
                fullWidth 
                required
                variant="outlined" />
                </div>
            </Grid>
            <Grid item xs={6}>
                <div className={classes.groupTitle}>
                    <FormLabel component="legend">Reading time(min)</FormLabel>
                </div>
                <div className={classes.title}>
                    <TextField id="readingTime" 
                    value={readingTime}
                    type="number"
                    onChange={event => setReadingTime(Number(event.target.value))}
                    fullWidth 
                    required
                    variant="outlined" />
                </div>
            </Grid>
        </Grid> 

        
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <div className={classes.groupTitle}>
                    <FormLabel component="legend">Image alt</FormLabel>
                </div>
                <div className={classes.title}>
                    <TextField id="path" 
                    value={imageAlt}
                    fullWidth
                    onChange={event => setImageAlt(event.target.value)}
                    required
                    variant="outlined" />
                </div>   
            </Grid>
            <Grid item xs={6}>
                <div className={classes.groupTitle}>
                    <FormLabel component="legend">Post date</FormLabel>
                </div>
                <div className={classes.title}>
                    <TextField id="postDate" 
                    value={postDate}
                    type="date"
                    onChange={event => setPostDate(event.target.value)}
                    fullWidth 
                    required
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined" />
                </div>
            </Grid>
        </Grid>
        <div className={classes.groupTitle}>
            <FormLabel component="legend">Q&A
            <button style={{marginLeft:5,width:30}}  onClick={addQa} >+</button>
            <button style={{marginLeft:5,width:30}}  onClick={cutQa}  >-</button>
            </FormLabel>
        </div>
        <div id ="qa">           
        {qaCount>=1?
        <>
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <div className={classes.title}>
                    <TextField id={"q1"} fullWidth variant="outlined" 
                    InputProps={{
                        startAdornment: <InputAdornment position="start">Q</InputAdornment>,
                    }}
                    onChange={event => qaChange(event.target.value,1)}
                    value={q1}
                /></div>
           </Grid>
           <Grid item xs={6}>
           <div className={classes.title}>
                    <TextField id={"a1"} fullWidth variant="outlined" 
                    InputProps={{
                        startAdornment: <InputAdornment position="start">A</InputAdornment>,
                    }}
                    onChange={event => qaChange(event.target.value,5)}
                    value={a1}
                /></div>
           </Grid>
        </Grid>
        </>
        :null}
        {qaCount>=2?
        <>
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <div className={classes.title}>
                    <TextField id={"q2"} fullWidth variant="outlined" 
                    InputProps={{
                    startAdornment: <InputAdornment position="start">Q</InputAdornment>,
                    }}
                    onChange={event => qaChange(event.target.value,2)}
                    value={q2}
                /></div>
           </Grid>
           <Grid item xs={6}>
           <div className={classes.title}>
                    <TextField id={"a2"} fullWidth variant="outlined" 
                    InputProps={{
                        startAdornment: <InputAdornment position="start">A</InputAdornment>,
                    }}
                    onChange={event => qaChange(event.target.value,6)}
                    value={a2}
                /></div>
           </Grid>
        </Grid>
        </>
        :null}
        {qaCount>=3?
        <>
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <div className={classes.title}>
                    <TextField id={"q3"} fullWidth variant="outlined" 
                    InputProps={{
                        startAdornment: <InputAdornment position="start">Q</InputAdornment>,
                    }}
                    onChange={event => qaChange(event.target.value,3)}
                    value={q3}
                /></div>
           </Grid>
           <Grid item xs={6}>
           <div className={classes.title}>
                    <TextField id={"a3"} fullWidth variant="outlined"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">A</InputAdornment>,
                    }} 
                    onChange={event => qaChange(event.target.value,7)}
                    value={a3}
                /></div>
           </Grid>
        </Grid>
        </>
        :null}
        {qaCount>=4?
        <>
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <div className={classes.title}>
                    <TextField id={"q4"} fullWidth variant="outlined"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">Q</InputAdornment>,
                    }} 
                    onChange={event => qaChange(event.target.value,4)}
                    value={q4}
                /></div>
           </Grid>
           <Grid item xs={6}>
           <div className={classes.title}>
                    <TextField id={"a4"} fullWidth variant="outlined"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">A</InputAdornment>,
                    }} 
                    onChange={event => qaChange(event.target.value,8)}
                    value={a4}
                /></div>
           </Grid>
        </Grid>
        </>
        :null}
        </div>

        <div className={classes.groupTitle}>
            <FormControlLabel
                control={
                <Checkbox
                    checked={bottomBanner}
                    onChange={event => setBottomBanner(event.target.checked)}
                    name="bottomBanner"
                    color="primary"
                />
                }
                label="Bottom Banner"
            />
        </div>
        {bottomBanner?<div><div className={classes.groupTitle}>
                    <FormLabel component="legend">Link</FormLabel>
                </div>
                <div className={classes.title}>
                    <TextField id="link" 
                    value={link}
                    fullWidth
                    onChange={event => setLink(event.target.value)}
                    required
                    variant="outlined" />
                </div> 
        <div className={classes.groupTitle}>
                    <FormLabel component="legend">Desktop Source Code</FormLabel>
                </div>
                <div className={classes.title}>
                    <TextField id="desktopSourceCode" 
                    value={desktopSourceCode}
                    fullWidth
                    onChange={event => setDesktopSourceCode(event.target.value)}
                    required
                    variant="outlined" />
                </div> 
                <div className={classes.groupTitle}>
                <FormLabel component="legend">Mobile Source Code</FormLabel>
            </div>
            <div className={classes.title}>
                    <TextField id="mobileSourceCode" 
                    value={mobileSourceCode}
                    fullWidth
                    onChange={event => setMobileSourceCode(event.target.value)}
                    required
                    variant="outlined" />
                </div> 
                <div className={classes.groupTitle}>
                <FormLabel component="legend">Button</FormLabel>
                 </div>
                <div className={classes.title}>
                    <TextField id="button" 
                    value={button}
                    fullWidth
                    onChange={event => setButton(event.target.value)}
                    required
                    variant="outlined" />
                </div>     
            </div>:null
        }
    
        
        <div className={classes.groupTitle}>
            <FormLabel component="legend">Image (1200*628,1.91:1)</FormLabel>
        </div>
        <div style={{marginTop:10}}>
            <input id="image" style={{display:'none'}} type="file" onChange={(e)=>handleImageChange(e)}/>
            <label style={{display:showUploadImagebutton, color:"#1890FF",border:"1px dashed #1890FF",padding:'3px 15px'}} htmlFor="image">upload</label>
            <label  htmlFor="image"><div id ="result"></div>< img style={{display:showImage,width:'320px',height:'180px'}} src={imagePreviewUrl} /></label>
            
        </div>


        <div className={classes.wrapper}>
            <ArticleEditor button={button} bottomBanner={bottomBanner} mobileSourceCode={mobileSourceCode} desktopSourceCode={desktopSourceCode} link={link}  authorId={authorId} metaTitle={metaTitle} description={description} file={file} postDate={postDate} readingTime={readingTime} imageAlt={imageAlt} path={path} tags={tags} category={category} initialValue={initialValue} id={postId} title={title} headerElement={headerElement} q1={q1}  q2={q2} q3={q3} q4={q4} a1={a1}  a2={a2} a3={a3} a4={a4}/>
        </div>

        
    </div>
}