import React, { useEffect } from 'react'
// libraries
// import { BrowserRouter, useLocation } from 'react-router-dom'
import { Redirect, Route, useLocation, useHistory, useRouteMatch, Router } from 'react-router-dom'
// routes
import { PrivateRoute } from './private-route'
// components
import {
    NavigationBar,
    SubFooter,
    Footer,
} from '../components'
// screens
import {
    AdminLogin,
    AdminPanel,
    Homepage,
    Search,
    NotFound,
} from '../screens'
// styles
import { BaseStyles } from '../styles'
// helpers
import { CheckIsVersionMobile } from '../helpers'

export const InnerRoute = () => {

    const {
        background,
        backgroundM,
    } = BaseStyles()

    const isVersionMobile = CheckIsVersionMobile()

    const location = useLocation()
    const history = useHistory()
    const match = useRouteMatch(location.pathname)


    // useEffect(() => {
    //     window.scrollTo(0, 0)

        // const path = location.pathname.split('/')[1]
        // if (location.pathname === '/' || (path !== 'admin' && path !== 'n')) {
        //     return history.push('/n/home')
        // }

    // }, [location.pathname])

    return (
        <div>
            <Route path='/login' component={AdminLogin} />
            <PrivateRoute path='/admin-panel' component={AdminPanel} />
        </div>
        // location?.pathname?.split('/')[1] === 'n'
        //     ? <div className={isVersionMobile ? backgroundM : background}>
        //         <NavigationBar />
        //         <div>
        //             <Route path='/n' exact component={Homepage} />
        //             <Route path='/n/home' exact component={Homepage} />
        //             <Route path='/n/blog/:category' exact component={Articles} />
        //             <Route path='/n/blog/:category/:path' component={Blog} />
        //             <Route path='/n/search' exact component={Search} />
        //             <Route path='/n/:path' component={NotFound} />
        //         </div>
        //         <SubFooter />
        //         <Footer />
        //     </div>
        //     : <div>
        //         <Route path='/admin-login' component={AdminLogin} />
        //         <PrivateRoute path='/admin-panel' component={AdminPanel} />
        //     </div>
    )
}
