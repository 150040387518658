import _ from 'lodash'
// svgs
import * as Svgs from './svgs'
// styles
import { iconStyles } from '../../styles/icon-styles'
import '../../css/icon.css'
// types
import { ISpritesProps } from '../../interfaces'

export const Sprites = (props: ISpritesProps) => {

    const {
        icon = '',
        size = '',
        alt = '',
        className = '',
        isPng = false,
        isLink = false,
    } = props

    const useStyleIcon = iconStyles()

    return (
        <img
            src={(isPng || isLink) ? icon : Svgs[icon as string]}
            className={`${useStyleIcon[size]} ${useStyleIcon[className]}`}
            alt={alt}
        />
    )
}