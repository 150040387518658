import { makeStyles } from '@material-ui/core/styles'
// theme
import { Colors, Layouts, Fonts } from '../theme'

export const ArticlesStyles = makeStyles({
    background: {
        ...Layouts.defualtBackgroundStyle,
    },
    container: {
        ...Layouts.defaultContainerStyle,
    },
    containerM: {
        ...Layouts.M.defaultContainerStyle,
    },
    headerTextContainerM: {
        ...Layouts.rowWithJCSpaceBetween,
        ...Layouts.M.marginHorizontal,
    },
    headerText: {
        ...Fonts.font2XBig,
    },
    headerTextM: {
        ...Fonts.fontBig700,
    },
    titlesContainer: {
        ...Layouts.rowWithJCStart,
        marginTop: 8,
        borderBottom: Layouts.borderLine(Colors.blue5),
        borderRadius: 0,
    },
    buttonContainer: {
        borderBottom: Layouts.borderLine(Colors.white, 4),
        borderRadius: 0,
        '-webkit-transition': 'all 0.3s ease-out',
        transition: 'all 0.3s ease-out'
    },
    selectedButtonContainer: {
        borderBottom: Layouts.borderLine(Colors.orange0, 4),
        borderRadius: 0,
        '-webkit-transition': 'all 0.3s ease-out',
        transition: 'all 0.3s ease-out'
    },
    blogItemsContainer: {
        ...Layouts.rowWithWrap as {},
        position: 'relative',
    },
    blogItemsContainerM: {
        ...Layouts.M.marginHorizontal,
    },
    additionalBlogItemContainer: {
        marginRight: 24,
    },
    buttonFilterContainerM: {
        ...Layouts.rowWithJCSpaceBetween,
        padding: `6px 8px`,
        borderRadius: 4,
        backgroundColor: Colors.blue1,
    },
    filterPlainTextM: {
        ...Fonts.fontMedium400,
        marginLeft: 4,
    },
    noOfFiltersLabelContainer: {
        ...Layouts.rowWithAllCenter,
        marginLeft: 4,
        height: 16,
        width: 16,
        borderRadius: 8,
        backgroundColor: Colors.blue2,
    },
    noOfFiltersLabelText: {
        ...Fonts.fontXSmall700,
        color: Colors.white,
    },
    loadingContainer: {
        ...Layouts.rowWithAllCenter,
        ...Layouts.coverLayer as {},
        backgroundColor: Colors.white,
        opacity: 0.7,
    },
    loadingContainerM: {
        ...Layouts.rowWithAllCenter,
        height: 300,
    },
    notFoundContainer: {
        ...Layouts.rowWithAllCenter,
        flex: 1,
        marginTop: 20,
        marginBottom: 60,
    },
    emptyScreenContainer: {
        height: 400,
    },
})
