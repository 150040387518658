import _ from 'lodash'

export const ErrorAlert = ({
    text1,
    text2,
}: {
    text1: string,
    text2?: string,
}) => {
    const resolvedText1 = (!isNaN(parseInt(text1)) || typeof text1 === 'number' || (text1.split(' '))[0] === 'null') ? t('screens.errorCode.default') : text1
    alert(resolvedText1)
    // window.location.reload()
}
