import _ from 'lodash'
import axios from 'axios'
// helpers
import { CancelRequest, APICatchError, ObjectSnakeCaseKeys, APIHeaders, APIDomainHTTP, APIVersion } from '../helpers'
import { RemoveUserToken} from '../helpers/local-storage'




export const UploadDataForCreateOrUpdateHomepageBannerAPI = async (body: {
    link: string,
    imageAlt: string,
    order:number,
    id:number,
    publicTime:number,
    status:string,
    endTime:number,
}) => {
    const { post, CancelToken } = axios
    const url = `${APIDomainHTTP()}/${APIVersion()}/website/banner`
    const headers = APIHeaders()

    const source = CancelToken.source()

    CancelRequest.add(
        'uploadDataForCreateArticle',
        () => source.cancel('uploadDataForCreateArticle: Operation cancelled by user'),
    )

    try {
        const res: {
            status: number;
            message: string;
        } = await post(url, ObjectSnakeCaseKeys(body), {
            headers,
            cancelToken: source.token,
        })
        if (res.status !== 200) {
            return APICatchError(res.message)
        }
        
        return res
    } catch (e) {
        if (e.message == 'Request failed with status code 403'){
            RemoveUserToken()
        }
        return APICatchError(e.message)
    }
} 
