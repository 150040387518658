import React, { useState, useRef } from 'react'
// view screens
import { NavigationBarView } from './navigation-bar-view'
// types
import { DrawerPosition } from '../../interfaces'

export const NavigationBarController = () => {

    const [isPopoverOpened, setIsPopoverOpened] = useState(false)
    const [isDrawerOpened, setIsDrawerOpened] = useState<boolean>(false)
    const [drawerPosition, setDrawerPosition] = useState<DrawerPosition>('left')
    const popoverRef = useRef(null);

    const onHoverTitle = () => {
        setIsPopoverOpened(true)
    }

    const onLeaveTitle = () => {
        setIsPopoverOpened(false)
    }

    const onClickTitle = (title: string) => {
        setIsDrawerOpened(false)
        setDrawerPosition('left')
    }

    const onHandleDrawerVisibiity = (isOpened: boolean, position: DrawerPosition) => {
        setIsDrawerOpened(isOpened)
        setDrawerPosition(position)
    }

    const ViewProps = {
        popoverRef,
        isPopoverOpened,
        isDrawerOpened,
        drawerPosition,
        onHoverTitle,
        onLeaveTitle,
        onClickTitle,
        onHandleDrawerVisibiity,
    }

    return (
        <NavigationBarView {...ViewProps} />
    )
}
