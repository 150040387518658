import { useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { UseDataFetching, GetPlatformsAricleAPI,GetPlatformListAPI, GetPlatformDataAPI,UploadDataForCreatePlatformAPI,DeletePlatformAPI } from '../../apis'
import {Table,Select,Checkbox,FormControlLabel,TextField,Grid,InputLabel,Button,InputAdornment,Snackbar,FormLabel,TableBody,TableCell,TableContainer,TableHead,TableRow,Paper} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { GetUserToken } from '../../helpers';
import {APIDomainHTTP} from '../../helpers/api-domain-http'
import { useHistory } from 'react-router-dom'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ReactDOM from 'react-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const platformStyle = makeStyles((theme) => ({
    body:{
        paddingTop:10,
        marginTop:30,
        paddingLeft:70,
        paddingRight:100,
    },
    select:{
        marginTop:2,
        backgroundColor:"white",
    },
    content:{
        padding:theme.spacing(2),
        paddingTop:40,
        paddingLeft:40,
        border:1,
        borderRadius:4,
        backgroundColor:"white",
        borderStyle:"solid",
        borderColor:"#bdbdbd",
        width:"100%",
        height:90,
    },
    subTitle:{
        marginTop:5,
    },
    title:{
        marginTop:45,
    },
    inputFied:{
        marginTop:1,
    },
    textfield:{
        border:1,
        marginTop:9,
        borderRadius:4,
        backgroundColor:"white",
    },
    featurefield:{
        border:1,
        marginTop:3,
        borderRadius:4,
        backgroundColor:"white",
    }

})); 


export const AdminPlatform= (props:any) => {
    const classes = platformStyle();
    const [imageSrc ,setImageSrc ] = useState("");
    const [showUploadImagebutton, setShowUploadImagebutton] = useState('');
    const [showImage, setShowImage] = useState('none');
    const [displayName ,setDisplayName ] = useState("");
    const [productName ,setProductName ] = useState("");
    const [website ,setWebsite ] = useState("");
    const [description ,setDescription ] = useState("");
    const [platformUuid ,setPlatformUuid ] = useState("");
    const [coinNumber ,setCoinNumber ] = useState(0);
    const [featureCount ,setFeatureCount ] = useState(1);
    const [invitationCode ,setInvitationCode ] = useState("");
    const [invitationLink ,setInvitationLink ] = useState("");
    const [postId,setPostId] = useState(0);
    const [imageAlt ,setImageAlt ] = useState("");
    const [cryptoCurrency ,setCryptoCurrency] = useState("");
    const [legalCurrency ,setLegalCurrency] = useState("");
    const [withdrawalDepositMethod ,setWithdrawalDepositMethod] = useState("");
    const [activityContent,setActivityContent]= useState("");
    const [transactionFee ,setTransactionFee ] = useState(0);
    const [depositFee ,setDepositFee ] = useState(0);
    const [withdrawalFee ,setWithdrawalFee ] = useState(0);
    const [coinMarketCapScore ,setCoinMarketCapScore ] = useState(0);
    const [safetyIndex ,setSafetyIndex ] = useState(0);
    const [popularityIndex ,setPopularityIndex ] = useState(0);
    const [scaleIndex ,setScaleIndex ] = useState(0);
    const [discount ,setDiscount ] = useState(0);
    const [currentPlatformId,setCurrentPlatformId] = useState(0);
    const [feature1 ,setFeature1] = useState("");
    const [feature2 ,setFeature2] = useState("");
    const [feature3 ,setFeature3] = useState("");
    const [feature4 ,setFeature4] = useState("");
    const [feature5 ,setFeature5] = useState("");
    const [feature6 ,setFeature6] = useState("");
    const [feature7 ,setFeature7] = useState("");
    const [feature8 ,setFeature8] = useState("");
    const [comparable,setComparable] =  useState(false);
    const [open, setOpen] = useState(false);
    const [deleteTarget, setDeleteTarget] = useState(0);
    const [file, setFile] = useState(null);
    const [postSuccess,setPostSuccess] = useState(false);
    const [rows ,setRows ] = useState([]);
    const [platformsAricleList ,setPlatformsAricleList] = useState([{post_id:0,title:''}]);
    var id = props.location.search.substr(5,10);
    let history = useHistory();
    const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
        setDeleteTarget(0);
        setPostSuccess(false);
    };
    const ClickDeletePlatformButton = async() => {
        await UseDataFetching(DeletePlatformAPI({platformId:deleteTarget})) as any;
        GetPlatformData();
        setPostSuccess(true);
        setOpen(false);
    };


    
    const handleClickOpen = async(userId:number)=> {
        setDeleteTarget(userId)
        setOpen(true);
    };

    const ClickPlatformButton = (platformId:number) =>{
        history.push("/admin-panel/platform?&id="+platformId)
    }

    const handleImageChange = (e:any) => {
        e.preventDefault();
        var reader = new FileReader();
        setShowUploadImagebutton('none');
        setShowImage('none');
        (document.getElementById("result")as any).innerHTML=""
        reader.onload = function (event) {
            var txt = (event.target as any).result;
            var img =  document.createElement("img");
            img.src = txt;
            img.id = "imageExample"
            img.width=80;
            img.height=80;
            (document.getElementById("result") as any).appendChild(img);
        };
        if (e.target.files[0]){
            reader.readAsDataURL(e.target.files[0]);
            setFile(e.target.files[0]); 
        }else{
            setShowUploadImagebutton('');
        }

    }
    const GetPlatformsArticle =  async()=> {
        var list:any =[];
        const  {results} =  await UseDataFetching(GetPlatformsAricleAPI({})) as any;
        (results['platforms']||[]).map(function(result:any){
           list.push({'post_id':result.post_id,'title':result.title });
        })
        setPlatformsAricleList(list);
    }
    
    const GetPlatformsData =  async()=> {
        setImageSrc("");
        setShowUploadImagebutton('');
        setShowImage("none");
        setDisplayName("");
        setProductName("");
        setWebsite("");
        setDescription("");
        setCoinNumber(0);
        setInvitationCode("");
        setInvitationLink("");
        setImageAlt("");
        setCryptoCurrency("");
        setLegalCurrency("");
        setWithdrawalDepositMethod("");
        setActivityContent("");
        setTransactionFee(0);
        setDepositFee(0);
        setWithdrawalFee(0);
        setCoinMarketCapScore(0);
        setSafetyIndex(0);
        setPopularityIndex(0);
        setScaleIndex(0);
        setDiscount(0);
        setFeatureCount(1);
        setFeature1("");
        setFeature2("");
        setFeature3("");
        setFeature4("");
        setFeature5("");
        setFeature6("");
        setFeature7("");
        setFeature8("");

        var list:any =[];
        const  {results} =  await UseDataFetching(GetPlatformListAPI({})) as any;
        (results['platforms']||[]).map(function(result:any){
           list.push({'platform_id':result.platform_id,'display_name':result.display_name,'platform_uuid':result.platform_uuid });
        })
        setRows(list);
      }
    const createOrUpdatePlatform = async() => {
        var platformfeatures : Array<string>=[];
        feature1!=""?platformfeatures.push(feature1):setFeature1("")
        feature2!=""?platformfeatures.push(feature2):setFeature2("")
        feature3!=""?platformfeatures.push(feature3):setFeature3("")
        feature4!=""?platformfeatures.push(feature4):setFeature4("")
        feature5!=""?platformfeatures.push(feature5):setFeature5("")
        feature6!=""?platformfeatures.push(feature6):setFeature6("")
        feature7!=""?platformfeatures.push(feature7):setFeature7("")
        feature8!=""?platformfeatures.push(feature8):setFeature8("")

        const { results } = await UseDataFetching(UploadDataForCreatePlatformAPI({
            description:description,
            productName:productName,
            platformId:currentPlatformId,
            displayName:displayName,
            website:website,
            comparable:comparable,
            postId:postId,
            coinNumber :coinNumber,
            invitationCode:invitationCode,
            invitationLink:invitationLink,
            imageAlt:imageAlt,
            cryptoCurrency:cryptoCurrency,
            legalCurrency:legalCurrency,
            transactionFee :transactionFee,
            coinMarketCapScore:coinMarketCapScore,
            depositFee:depositFee,
            withdrawalFee:withdrawalFee,
            withdrawalDepositMethod:withdrawalDepositMethod,
            safetyIndex:safetyIndex,
            popularityIndex:popularityIndex,
            scaleIndex:scaleIndex,
            activityContent:activityContent,
            discount:discount,
            features:platformfeatures,
        })) as any

        switch (results['code']) {
            case 200:
                if (file){
                    var xhr, formData;
                    xhr = new XMLHttpRequest();
                    xhr.withCredentials = false;
                    if (id == ""){
                        xhr.open('POST', APIDomainHTTP() + '/v1/upload/platform_img/'+results['platform_uuid']);
                    }else{
                        xhr.open('POST', APIDomainHTTP() + '/v1/upload/platform_img/'+platformUuid);
                    }
                    xhr.setRequestHeader("Cache-Control","no-cache");
                    xhr.setRequestHeader("Authorization",GetUserToken());
                    xhr.onload = function() {
                        var json;
                        console.log(xhr.status);
                        json = JSON.parse(xhr.responseText);
                    };        
                    formData = new FormData();
                    file && formData.append('img', file, "upload.jpg");
                    xhr.send(formData);
                }
                setPostSuccess(true);
                if (id == ""){
                    GetPlatformData();
                }
                break
        }

    }
    const featureChange = (feature:any,featureNumber:any) => {
        switch (featureNumber){
            case 1:
                setFeature1(feature);
                break
            case 2:
                setFeature2(feature);
                break
            case 3:
                setFeature3(feature);
                break
            case 4:
                setFeature4(feature);
                break
            case 5:
                setFeature5(feature);
                break
            case 6:
                setFeature6(feature);
                break
            case 7:
                setFeature7(feature);
                break
            case 8:
                setFeature8(feature);
                break    
        }
    }

    const addFeature = () => {
        if (id == ""){
            if (featureCount<8){
                var node = document.createElement('div');
                var featureId = document.createAttribute("id");
                featureId.value = "features"+featureCount;
                node?.setAttributeNode(featureId)
                document.getElementById('features')?.appendChild(node);
                
                ReactDOM.render(
                    <div className={classes.featurefield}>
                        <TextField id={"feature"+featureCount} fullWidth variant="outlined" 
                        onChange={event => featureChange(event.target.value,featureCount)}
                        InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                <FiberManualRecordIcon fontSize="small"/>
                            </InputAdornment>
                            ),
                        }}/>
                      </div> ,
                    document.getElementById('features'+featureCount)
                );
                setFeatureCount(featureCount+1);
            }
        }else{
            if (featureCount<8){
                setFeatureCount(featureCount+1);
            }
        }
    
    }
    const removeFeature = () => {
        if (featureCount>1){
            if (id==""){
                document.getElementById('features'+(featureCount-1))?.remove();
            }
            setFeatureCount(featureCount-1);
            featureChange("",featureCount);
        }
    }
    
    const GetPlatformData =  async()=> {
        var list:any =[]; 
        const  {results} =  await UseDataFetching(GetPlatformDataAPI(id)) as any;
        setImageSrc("https://cryptowesearch.s3.ap-southeast-1.amazonaws.com/platform_img/"+results["platform_uuid"]);
        setShowUploadImagebutton('none');
        setShowImage("");
        setDisplayName(results["display_name"]);
        setProductName(results["product_name"]);
        setWebsite(results["website"]);
        setComparable(results["comparable"]);
        setDescription(results["description"]);
        setCoinNumber(results["coin_number"]);
        setInvitationCode(results["invitation_code"]);
        setInvitationLink(results["invitation_link"]);
        setImageAlt(results["image_alt"]);
        setCryptoCurrency(results["crypto_currency"]);
        setPostId(results["post_id"]);
        setLegalCurrency(results["legal_currency"]);
        setWithdrawalDepositMethod(results["withdrawal_deposit_method"]);
        setActivityContent(results["activity_content"]);
        setTransactionFee(results["transaction_fee"]);
        setDepositFee(results["deposit_fee"]);
        setWithdrawalFee(results["withdrawal_fee"]);
        setPlatformUuid(results["platform_uuid"])
        setCoinMarketCapScore(results["coin_market_cap_score"]);
        setSafetyIndex(results["safety_index"]);
        setPopularityIndex(results["popularity_index"]);
        setScaleIndex(results["scale_index"]);
        setDiscount(results["discount"]);
        //setFeatureCount(8);
        setFeatureCount(results["features"]?.length);
        for (let item in results["features"] ){
            switch (Number(item)){
                case 0:
                    setFeature1(results["features"][item]["feature"]);
                    break
                case 1:
                    setFeature2(results["features"][item]["feature"]);
                    break
                case 2:
                    setFeature3(results["features"][item]["feature"]);
                    break
                case 3:
                    setFeature4(results["features"][item]["feature"]);
                    break
                case 4:
                    setFeature5(results["features"][item]["feature"]);
                    break
                case 5:
                    setFeature6(results["features"][item]["feature"]);
                    break
                case 6:
                    setFeature7(results["features"][item]["feature"]);
                    break
                case 7:
                    setFeature8(results["features"][item]["feature"]);
                    break    
            }
        }

        // const [features ,setFeatures] = useState([]);

       
    }
    useEffect(() =>{
        GetPlatformsArticle();
        if (id ==""){
            (document.getElementById("result")as any).innerHTML=""
            setShowImage('none');
            GetPlatformsData();
        }else{
            GetPlatformData();
            setCurrentPlatformId(Number(id));
        }

    }, [id]);

    return <div  className={classes.body}>
            { id==""?<div>
            <div>
                <FormLabel component="legend">平台列表</FormLabel>
            </div>
            <div>
            <TableContainer style={{marginTop:5}} component={Paper}>
                <Table  aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell align="center">Icon</TableCell>          
                        <TableCell align="center">平台名稱</TableCell> 
                        <TableCell align="center">Actions</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {rows.map((row:any) => (
                        <TableRow key={row.platform_id} hover>
                        <TableCell align="center">
                             <img style={{width:40,height:40,borderRadius:"100%",overflow:"hidden"}} src={"https://cryptowesearch.s3.ap-southeast-1.amazonaws.com/platform_img/"+row.platform_uuid}></img>
                        </TableCell>
                        <TableCell align="center">
                            {row.display_name}
                        </TableCell>
                        <TableCell align="center">
                            <Button onClick={()=>ClickPlatformButton(row.platform_id)}>view</Button>
                            <Button onClick={()=>handleClickOpen(row.platform_id)} >delete</Button>

                        </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
            </div></div>:<div></div>}
            

            <div>
                {id==""?
                <div>
                <div className={classes.title}>
                    <FormLabel component="legend">平台指南</FormLabel>
                </div>
                <Select
                    //value={authorId}
                    //onChange={handleAuthorChange}
                    fullWidth
                    className={classes.select}
                    native
                    variant="outlined" 
                    >
                    {
                        platformsAricleList.map((option, index) => (
                        <option key={option.post_id} value={option.post_id}>{option.title}</option>
                        ))
                    }
                </Select>
                <div  style={{marginTop:15}}>
                        <Button color="primary" variant="outlined">Update</Button>
                </div>

                <div className={classes.title}>
                    <FormLabel component="legend">新增平台</FormLabel>
                </div>
                </div>
                :<div></div>}
                <div className={classes.inputFied}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <div className={classes.textfield}>
                                <TextField id="schema"
                                label="展示名稱"
                                value={displayName}
                                onChange={event => setDisplayName(event.target.value)}
                                fullWidth
                                variant="outlined" />
                            </div>
                        </Grid >
                        <Grid item xs={6}>
                            <div className={classes.textfield}>
                                <TextField id="schema"
                                label="產品名稱"
                                value={productName}
                                onChange={event => setProductName(event.target.value)}
                                fullWidth
                                variant="outlined" />
                            </div>
                        </Grid >
                    </Grid >
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <div className={classes.textfield}>
                                <TextField id="schema"
                                label="網址"
                                value={website}
                                onChange={event => setWebsite(event.target.value)}
                                fullWidth
                                variant="outlined" />
                            </div>
                        </Grid >
                        <Grid item xs={6}>
                            <div className={classes.textfield}>
                                <TextField id="schema"
                                label="出入金方式"
                                value={withdrawalDepositMethod}
                                onChange={event => setWithdrawalDepositMethod(event.target.value)}
                                fullWidth
                                variant="outlined" />
                            </div>
                        </Grid >
                    </Grid >
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <div className={classes.textfield}>
                                <TextField id="schema"
                                multiline 
                                value={legalCurrency}
                                label="法定貨幣"
                                onChange={event => setLegalCurrency(event.target.value)}
                                fullWidth
                                variant="outlined" />
                            </div>
                        </Grid >
                        <Grid item xs={6}>
                            <div className={classes.textfield}>
                                <TextField id="schema"
                                multiline 
                                value={cryptoCurrency}
                                label="加密貨幣"
                                onChange={event => setCryptoCurrency(event.target.value)}
                                fullWidth
                                variant="outlined" />
                            </div>
                        </Grid >
                    </Grid >
                    
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <div className={classes.textfield}>
                                <TextField id="schema"
                                label="存款費用"
                                value={depositFee}
                                type="number"
                                onChange={event => setDepositFee(Number(event.target.value))}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                          %
                                      </InputAdornment>
                                    ),
                                  }}
                                variant="outlined" />
                            </div>
                        </Grid >
                        <Grid item xs={6}>
                            <div className={classes.textfield}>
                                <TextField id="schema"
                                label="提款費用"
                                value={withdrawalFee}
                                fullWidth
                                type="number"
                                onChange={event => setWithdrawalFee(Number(event.target.value))}
                                InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                          %
                                      </InputAdornment>
                                    ),
                                  }}
                                variant="outlined" />
                            </div>
                        </Grid >
                    </Grid >
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <div className={classes.textfield}>
                                <TextField id="schema"
                                label="安全指數"
                                value={safetyIndex}
                                onChange={event => setSafetyIndex(Number(event.target.value))}
                                type="number"
                                fullWidth
                                variant="outlined" />
                            </div>
                        </Grid >
                        <Grid item xs={6}>
                            <div className={classes.textfield}>
                                <TextField id="schema"
                                label="人氣指數"
                                value={popularityIndex}
                                onChange={event => setPopularityIndex(Number(event.target.value))}
                                fullWidth
                                type="number"
                                variant="outlined" />
                            </div>
                        </Grid >
                    </Grid >
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <div className={classes.textfield}>
                                <TextField id="schema"
                                label="幣種數量"
                                value={coinNumber}
                                onChange={event => setCoinNumber(Number(event.target.value))}
                                type="number"
                                fullWidth
                                variant="outlined" />
                            </div>
                        </Grid >
                        <Grid item xs={6}>
                            <div className={classes.textfield}>
                                <TextField id="schema"
                                label="規模指數"
                                value={scaleIndex}
                                onChange={event => setScaleIndex(Number(event.target.value))}
                                fullWidth
                                type="number"
                                variant="outlined" />
                            </div>
                        </Grid >
                    </Grid >
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <div className={classes.textfield}>
                                <TextField id="schema"
                                multiline 
                                value={invitationCode}
                                onChange={event => setInvitationCode(event.target.value)}
                                label="邀請碼"
                                fullWidth
                                variant="outlined" />
                                </div>
                         </Grid> 
                        <Grid item xs={6}>
                            <div className={classes.textfield}>
                                <TextField id="schema"
                                label="邀請碼優惠幅度"
                                value={discount}
                                fullWidth
                                onChange={event => setDiscount(Number(event.target.value))}
                                type="number"
                                InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                          %
                                      </InputAdornment>
                                    ),
                                  }}
                                variant="outlined" />
                            </div>
                        </Grid >
                    </Grid >
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <div className={classes.textfield}>
                                <TextField id="schema"
                                label="邀請碼鏈接"
                                value={invitationLink}
                                onChange={event => setInvitationLink(event.target.value)}
                                type="text"
                                fullWidth
                                variant="outlined" />
                            </div>
                        </Grid> 
                        <Grid item xs={6}>
                            <div className={classes.textfield}>
                                <TextField id="schema"
                                label="CoinMarketCap Score"
                                value={coinMarketCapScore}
                                onChange={event => setCoinMarketCapScore(Number(event.target.value))}
                                type="text"
                                fullWidth
                                variant="outlined" />
                            </div>
                        </Grid >
                    </Grid >

                    <Grid item xs={12}>
                            <div className={classes.textfield}>
                                <TextField id="schema"
                                multiline 
                                value={activityContent}
                                label="活動內容"
                                onChange={event => setActivityContent(event.target.value)}
                                fullWidth
                                variant="outlined" />
                            </div>
                    </Grid >
                    <Grid item xs={12}>
                        <div className={classes.textfield}>
                            <TextField id="schema"
                            multiline 
                            label="描述"
                            value={description}
                            onChange={event => setDescription(event.target.value)}
                            fullWidth
                            variant="outlined" />
                        </div>
                    </Grid >

                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <div className={classes.textfield}>
                                <TextField id="schema"
                                multiline 
                                label="Image Alt"
                                value={imageAlt}
                                onChange={event => setImageAlt(event.target.value)}
                                fullWidth
                                variant="outlined" />
                                </div>
                         </Grid> 
                         <Grid item xs={6}>
                            <div className={classes.textfield}>
                                <TextField id="schema"
                                label="手續費"
                                type="number"
                                value={transactionFee}
                                onChange={event => setTransactionFee(Number(event.target.value))}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                          %
                                      </InputAdornment>
                                    ),
                                  }}
                                variant="outlined" />
                            </div>
                        </Grid >

                    </Grid>
                    <div className={classes.subTitle}>
                    <FormLabel component="legend">平台介紹</FormLabel>
                    </div>
                    <Select
                        value={postId}
                        onChange={event => setPostId(Number(event.target.value))}
                        fullWidth
                        className={classes.select}
                        native
                        variant="outlined" 
                        >
                        {
                            platformsAricleList.map((option, index) => (
                            <option key={option.post_id} value={option.post_id}>{option.title}</option>
                            ))
                        }
                        
                    </Select>

                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={comparable}
                            onChange={event => setComparable(event.target.checked)}
                            name="checkedB"
                            color="primary"
                        />
                        }
                        label="Comparable"
                    />

                    <Grid item xs={12}>
                        <InputLabel style={{marginTop:14}}  htmlFor="standard-adornment-password">
                            特點
                            <button style={{marginLeft:5,width:30}}  onClick={addFeature} >+</button>
                            <button style={{marginLeft:5,width:30}}   onClick={removeFeature} >-</button>

                        </InputLabel>
                    </Grid>
                    <div id ="features">
                        {id==""?null:<div>{featureCount>=1?
                          <div id={"features1"} className={classes.featurefield}>
                            <TextField id={"feature1"} fullWidth variant="outlined" 
                            onChange={event => featureChange(event.target.value,1)}
                            value={feature1}
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <FiberManualRecordIcon fontSize="small"/>
                                </InputAdornment>
                                ),
                            }}/>
                            </div>:null}
                            {featureCount>=2?
                          <div id={"features2"} className={classes.featurefield}>
                            <TextField id={"feature2"} fullWidth variant="outlined" 
                            onChange={event => featureChange(event.target.value,2)}
                            value={feature2}
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <FiberManualRecordIcon fontSize="small"/>
                                </InputAdornment>
                                ),
                            }}/>
                            </div>:null}
                            {featureCount>=3?
                          <div id={"features3"} className={classes.featurefield}>
                            <TextField id={"feature3"} fullWidth variant="outlined" 
                            onChange={event => featureChange(event.target.value,3)}
                            value={feature3}
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <FiberManualRecordIcon fontSize="small"/>
                                </InputAdornment>
                                ),
                            }}/>
                            </div>:null}
                            {featureCount>=4?
                          <div id={"features4"} className={classes.featurefield}>
                            <TextField id={"feature4"} fullWidth variant="outlined" 
                            onChange={event => featureChange(event.target.value,4)}
                            value={feature4}
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <FiberManualRecordIcon fontSize="small"/>
                                </InputAdornment>
                                ),
                            }}/>
                            </div>:null}
                            {featureCount>=5?
                          <div id={"features5"} className={classes.featurefield}>
                            <TextField id={"feature5"} fullWidth variant="outlined" 
                            onChange={event => featureChange(event.target.value,5)}
                            value={feature5}
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <FiberManualRecordIcon fontSize="small"/>
                                </InputAdornment>
                                ),
                            }}/>
                            </div>:null}
                            {featureCount>=6?
                          <div id={"features6"} className={classes.featurefield}>
                            <TextField id={"feature6"} fullWidth variant="outlined" 
                            onChange={event => featureChange(event.target.value,6)}
                            value={feature6}
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <FiberManualRecordIcon fontSize="small"/>
                                </InputAdornment>
                                ),
                            }}/>
                            </div>:null}
                            {featureCount>=7?
                          <div id={"features7"} className={classes.featurefield}>
                            <TextField id={"feature7"} fullWidth variant="outlined" 
                            onChange={event => featureChange(event.target.value,7)}
                            value={feature7}
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <FiberManualRecordIcon fontSize="small"/>
                                </InputAdornment>
                                ),
                            }}/>
                            </div>:null}
                            {featureCount>=8?
                          <div id={"features8"} className={classes.featurefield}>
                            <TextField id={"feature8"} fullWidth variant="outlined" 
                            onChange={event => featureChange(event.target.value,8)}
                            value={feature8}
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <FiberManualRecordIcon fontSize="small"/>
                                </InputAdornment>
                                ),
                            }}/>
                            </div>:null}


                        </div>
                        }
                    </div>


                    <InputLabel style={{marginTop:10}}  htmlFor="standard-adornment-password">Upload Icon(56*56)</InputLabel>
                    <div style={{marginTop:10}}>
                        <input id="image" style={{display:'none'}} type="file" onChange={(e)=>handleImageChange(e)} />
                        <label style={{display:showUploadImagebutton, color:"#1890FF",border:"1px dashed #1890FF",padding:'3px 15px'}} htmlFor="image">upload</label>
                        <label  htmlFor="image"><div id ="result"></div>< img style={{display:showImage,width:'56px',height:'56px'}} src={imageSrc} /></label>
                        
                    </div>

                    <div  style={{marginTop:25}}>
                        <Button onClick={createOrUpdatePlatform} color="primary" variant="outlined">{id==""?"Create":"Update"}</Button>
                     </div>
                    
                </div>

                <br /><br /><br /><br /><br /><br /><br /><br />

            </div>
            <Snackbar open={postSuccess} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    Success!
                </Alert>
            </Snackbar>
            <div>
             <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">{"delete?"}</DialogTitle>
            <DialogActions>
            <Button onClick={handleClose} color="primary">
                No
            </Button>
            <Button onClick={ClickDeletePlatformButton} color="primary" autoFocus>
                Yes
            </Button>
            </DialogActions>
         </Dialog>
        </div>

    </div>
}