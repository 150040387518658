import _ from 'lodash';
import { isNullOrUndefined } from './is-null-or-undefined';
import { GetUserToken } from '../helpers';

export const APIHeaders = (additionalHeaderContents?: { [key: string]: string }) => {
    const token = GetUserToken()
    const headers = {
        'Accept': 'application/json',
        'Content-Type':'application/x-www-form-urlencoded',
        'Authorization': token,
        // 'X-REQUEST-SOURCE': 'mobile',
        ...additionalHeaderContents,
    };
    return _.pickBy(headers, isNullOrUndefined);
};
