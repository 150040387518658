import { makeStyles } from '@material-ui/core/styles'
// theme
import { Fonts, Colors, Layouts } from '../theme'

export const PopoverStyles = makeStyles({
    popover: {
        pointerEvents: 'none',
    },
    popoverContent: {
        pointerEvents: 'auto',
        backgroundColor: Colors.transparent,
        boxShadow: 'none'
    },
})
