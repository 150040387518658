import { useRef,useState,useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Button ,Snackbar} from '@material-ui/core';
import { useHistory } from 'react-router-dom'
import { UseDataFetching, UploadDataForCreateOrUpdateArticleAPI,UploadBlogImageAPI } from '../../apis'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { GetUserToken } from '../../helpers';
import CircularProgress from '@material-ui/core/CircularProgress';
import {APIDomainHTTP} from '../../helpers/api-domain-http'
function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function GetCookies(name:any){
    var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    if (arr = document.cookie.match(reg)) return unescape(arr[2]);
    else return null;
};
export default function ArticleEditor(props:any) {
    const editorRef :any = useRef(null);
    let {id,authorId,title,button,description,metaTitle,headerElement,
        initialValue,category,tags,path,imageAlt,readingTime,postDate,
        file,bottomBanner,mobileSourceCode,desktopSourceCode,link,q1,q2,q3,q4,a1,a2,a3,a4} = props
    const [postId, setPostId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [postSuccess, setPostSuccess] = useState(false);
    const [postFailure, setPostFailure] = useState(false);
    const questions = [q1,q2,q3,q4];
    const answers = [a1,a2,a3,a4];
    let history = useHistory();
    const [errorMessage,setErrorMessage] = useState("");
    const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setPostFailure(false);
        setPostSuccess(false);
    };
    let pid = 0 
    var  categoryArray:number[] = [];
    var  tagsArray:number[] = [];     
    (category||[]).map(function(result:any){
        categoryArray.push(result.category_id);
    });
    (tags||[]).map(function(result:any){
        tagsArray.push(result.tag_id);
    });
    const clickPreviewButton =()=> {
        window.open("https://www.cryptowesearch.com/blog/all/"+path);
    }
    
    const saveArticle = async(saveType:any) => {
        if (path === ""){
            setErrorMessage('Route path can not be empty');
            setPostFailure(true);
            return
        }
        if (bottomBanner === false){
            mobileSourceCode=""
            desktopSourceCode=""
            link=""
        }

        if (editorRef.current) {
            const content = editorRef.current.getContent();
            if (postId === 0){
                pid = Number(id)
            }else{
                pid = postId
            }
            setLoading(true);
            const { error , results } = await UseDataFetching(UploadDataForCreateOrUpdateArticleAPI({title:title,
                headerElement:headerElement,
                postStatus:saveType,
                path:path,
                mobileSourceCode:mobileSourceCode,
                desktopSourceCode:desktopSourceCode,
                link:link,
                description:description,
                metaTitle:metaTitle,
                readingTime:readingTime,
                button:button,
                category:categoryArray,
                postDate:postDate,
                userId:authorId,
                postId:pid,
                imageAlt:imageAlt,
                tags:tagsArray,
                questions:questions,
                answers:answers,
                content:content})) as any
            
                if (results!== undefined){
                    switch (results['code']) {
                        case 200:
                           setPostSuccess(true);
                           setPostId(results['post_id'])
                           if (file){
                               var xhr, formData;
                               xhr = new XMLHttpRequest();
                               xhr.withCredentials = false;
                               xhr.open('POST', APIDomainHTTP() + '/v1/upload/blog_icon/'+results['post_uuid']);
                               xhr.setRequestHeader("Cache-Control","no-cache");
                               xhr.setRequestHeader("Authorization",GetUserToken());
                               xhr.onload = function() {
                                   var json;
                                   json = JSON.parse(xhr.responseText);
                                   if (results['post_id']!==0){
                                       history.push("/admin-panel/article_editor?&id="+results['post_id']);
                                   }
                               };        
                               formData = new FormData();
                               file && formData.append('img', file, "upload.jpg");
                               xhr.send(formData);
                           }else{
                               if (results['post_id']!==0){
                                   history.push("/admin-panel/article_editor?&id="+results['post_id']);
                               }
                           }
                           break
                       case 3001:
                           setErrorMessage(results['message']);
                           setPostFailure(true);
                           break
                       default:
                           setErrorMessage(results['message']);
                           setPostFailure(true);
                           break;
                   }   
                   setLoading(false);
               
                }else{
                    setErrorMessage('error');
                    setPostFailure(true);
                    setLoading(false);
                }
        }
                
            
    };

    const publish = async(e:any) => {
        if (path === ""){
            setErrorMessage('Route path can not be empty');
            setPostFailure(true);
            return
        }else if (!file && id === 0){
            setErrorMessage('Image can not be empty');
            setPostFailure(true);
            return
        }
        if (bottomBanner === false){
            mobileSourceCode=""
            desktopSourceCode=""
            link=""
        }
        if (editorRef.current) {
            const content = editorRef.current.getContent();
            if (postId === 0){
                pid = Number(id)
            }else{
                pid = postId
            }
            setLoading(true);
            const { error , results } = await UseDataFetching(UploadDataForCreateOrUpdateArticleAPI({title:title,
                headerElement:headerElement,
                postStatus:"published",
                postId:pid,
                postDate:postDate,
                path:path,
                mobileSourceCode:mobileSourceCode,
                desktopSourceCode:desktopSourceCode,
                link:link,
                userId:authorId,
                button:button,
                description:description,
                metaTitle:metaTitle,
                imageAlt:imageAlt,
                readingTime:readingTime,
                tags:tagsArray,
                category:categoryArray,
                questions:questions,
                answers:answers,
                content:content})) as any
            if (results!== undefined){
                switch (results['code']) {
                    case 200:
                       setPostSuccess(true);
                       setPostId(results['post_id'])
                       if (file){
                           var xhr, formData;
                           xhr = new XMLHttpRequest();
                           xhr.withCredentials = false;
                           xhr.open('POST', APIDomainHTTP() + '/v1/upload/blog_icon/'+results['post_uuid']);
                           xhr.setRequestHeader("Cache-Control","no-cache");
                           xhr.setRequestHeader("Authorization",GetUserToken());
                           xhr.onload = function() {
                               var json;
                               //console.log(xhr.status);
                               json = JSON.parse(xhr.responseText);
                               if (results['post_id']!==0){
                                   history.push("/admin-panel/article_editor?&id="+results['post_id']);
                               }
                           };        
                           formData = new FormData();
                           file && formData.append('img', file, "upload.jpg");
                           xhr.send(formData);
   
                       }else{
                           if (results['post_id']!==0){
                               history.push("/admin-panel/article_editor?&id="+results['post_id']);
                           }
                       }
                       
                       break
                   case 3001:
                       setErrorMessage(results['message']);
                       setPostFailure(true);
                       break    
                   default:
                       setErrorMessage(results['message']);
                       setPostFailure(true);
                       break;
               }   
               setLoading(false);
            }else{
                setErrorMessage('error');
                setPostFailure(true);
                setLoading(false);
            }
            
        }
    };


    return <div><Editor
    onInit={(evt, editor) => editorRef.current  = editor}
    apiKey = "vrxoghgj6xhqn42jmrqytm4n9p53qnvsdnzbtzfagurrl50u"
    initialValue={initialValue}
    init={{
      min_height:640,
      max_height:640,
//      menubar: false,
      menubar: 'file edit view insert format tools table',
    //   paste_preprocess: function (pluginApi, data) {
    //     console.log(data.content);
        
    //     // Apply custom filtering by mutating data.content
    //     // For example:
    //     // const content = data.content;
    //     // const newContent = yourCustomFilter(content);
    //     // data.content = newContent;
    //   },
      keep_styles:false,
      forced_root_block:false,
      external_plugins:{//'word':'https://d3fdygqk2e2j4k.cloudfront.net/js/tiny/word.plugin.min.js',
        'gallery':'https://cryptowesearch.s3.ap-southeast-1.amazonaws.com/js/tiny/gallery.plugin.min.js',
        'banner':'https://cryptowesearch.s3.ap-southeast-1.amazonaws.com/js/tiny/banner.plugin.min.js',
        'blog':'https://cryptowesearch.s3.ap-southeast-1.amazonaws.com/js/tiny/blog.plugin.min.js',
        'blog2':'https://cryptowesearch.s3.ap-southeast-1.amazonaws.com/js/tiny/blog2.plugin.min.js',
        'qa':'https://cryptowesearch.s3.ap-southeast-1.amazonaws.com/js/tiny/qa.plugin.min.js',
        'vline':'https://cryptowesearch.s3.ap-southeast-1.amazonaws.com/js/tiny/vline.plugin.min.js',
      },
      plugins: 'gallery vline qa advcode mediaembed advtable formatpainter  banner print hr preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template  codesample table  export charmap  pagebreak nonbreaking anchor insertdatetime advlist lists wordcount imagetools textpattern help emoticons autosave  autoresize blog',
      toolbar:[
        ' undo redo |  styleselect fontsizeselect fontselect  | table image media | forecolor backcolor bold italic underline strikethrough link | preview code fullscreen',
        'alignleft aligncenter alignright alignjustify outdent indent | bullist numlist | blockquote subscript superscript removeformat | charmap emoticons hr pagebreak insertdatetime print  export| bdmap indent2em lineheight  | banner  blog blog2 vline  qa gallery',
      ],
      branding: false,
      fontsize_formats: '8px 12px 14px 16px 18px 24px 36px 48px 56px 72px',
      resize: "both",
      allow_conditional_comments: true,
      allow_unsafe_link_target: true,
      cleanup_on_startup : true,
      fix_list_elements : false,
      fix_nesting : false,
      fix_table_elements : false,
      paste_use_dialog : true,
      paste_auto_cleanup_on_paste : true,
      paste_remove_styles: true,
      paste_remove_styles_if_webkit: true,
      paste_strip_class_attributes: true,
    //   powerpaste_keep_unsupported_src: false,
    //   powerpaste_allow_local_images: true,
    //   powerpaste_word_import: 'prompt',
    //   powerpaste_html_import: 'prompt',
      //convert_urls: true,
    //   images_dataimg_filter: function(img) {
    //     return !img.hasAttribute('internal-blob');  // blocks the upload of <img> elements with the attribute "internal-blob".
    //   },
      images_reuse_filename: true,
      automatic_uploads: true,
      content_css_cors: true,
      images_upload_handler: async(blobInfo, succFun, failFun)  =>{
        var json:any;
        var xhr, formData;
        var file = blobInfo.blob();
        if (file){
            try{           
                
                const { error , results } = await UseDataFetching(UploadBlogImageAPI({
                    image:file,
                })) as any

                succFun(results.location);
                return


                // xhr = new XMLHttpRequest();
                // xhr.withCredentials = false;
                // xhr.open('POST', APIDomainHTTP()+'/v1/upload/blog_img');
                // xhr.setRequestHeader("Authorization",GetUserToken());
                //let tt =Math.random()*2000; 
                // xhr.onload = setTimeout(function(){
                //     var json;
                //     if (xhr.status != 200) {
                //         failFun('HTTP Error: ' + xhr.status);
                //         return;
                //     }
                //     json = JSON.parse(xhr.responseText);
                //     if (!json || typeof json.location != 'string') {
                //         failFun('Invalid JSON: ' + xhr.responseText);
                //         return;
                //     }
                //     succFun(json.location);
                //     return 
                // },tt)
                // xhr.onerror = function () {
                //     //failFun('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
                //     return
                // }
                // xhr.onload = function() {
                //     var json;
                //     if (xhr.status != 200) {
                //         failFun('HTTP Error: ' + xhr.status);
                //         return;
                //     }
                //     json = JSON.parse(xhr.responseText);
                //     if (!json || typeof json.location != 'string') {
                //         failFun('Invalid JSON: ' + xhr.responseText);
                //         return;
                //     }
                //     succFun(json.location);
                //     return
                // };
                // formData = new FormData();
                // formData.append('img', file, "upload.jpg");
                // if ("withCredentials" in xhr && file.size>0) {
                //     xhr.send(formData);
                // }else{
                //     xhr.send(null);
                // }
                
            }catch(err){
                console.log(err);
            }
        }
        
      },
    }}
  />
    <div style={{marginTop:25}}>
        {id!==0? <Button onClick={clickPreviewButton}  variant="contained" >preview</Button>:null}
        <Button onClick={e=>saveArticle("draft")} style={{marginLeft:10}}  variant="contained" color="primary">儲存草稿</Button>
        {GetCookies("role")=="Admin"?<Button onClick={publish} style={{marginLeft:10}} variant="contained" color="secondary">發布</Button>:<Button onClick={e=>saveArticle("pending")} style={{marginLeft:10}}  variant="contained" color="secondary">提交審核</Button>}
        {loading && <CircularProgress  size={20} style={{marginLeft:10}} />}
    </div>

    <Snackbar open={postSuccess} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
            Success!
        </Alert>
    </Snackbar>

    <Snackbar open={postFailure} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
             {errorMessage}
        </Alert>
    </Snackbar>

  </div>
 


}