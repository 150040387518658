import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import SearchIcon from '@material-ui/icons/Search';
import React,{ useEffect,useState } from 'react';
import {  Route, Redirect,useHistory } from 'react-router-dom'
import { UseDataFetching, GetArticlesOverviewDataAPI } from '../../apis'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { DataGrid, GridColDef, GridValueGetterParams } from '@material-ui/data-grid';


const OverviewStyle = makeStyles((theme) => ({
    table:{
        backgroundColor: 'white',
    }
}));

const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 110 },
    {
      field: 'title',
      headerName: 'Title',
      width: 300,
      editable: true,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 200,
      editable: true,
    },
    {
        field: 'path',
        headerName: 'Path',
        width: 120,
        editable: true,
      },
    {
      field: 'postDate',
      headerName: 'Post date',
      width: 150,
      editable: true,
    },
    {
      field: 'createdDate',
      headerName: 'Created date',
      description: 'This column has a value getter and is not sortable.',
      width: 160,
    },
  ];

export const AdminOverview = () => {
    const classes = OverviewStyle();
    const GetOverviewData =  async()=> {
        var list:any =[];
        const  {results} =  await UseDataFetching(GetArticlesOverviewDataAPI({})) as any;
        (results['articles']||[]).map(function(result:any){
           list.push({'id':result.id,'title':result.title,'description':result.description,'createdDate':result.created_date,'postDate':result.post_date,'path':result.path});
        })
        setRows(list);
    }
    const [rows,setRows]= useState([]);
    useEffect(() => {
        GetOverviewData();
    }, []);
    return <div>
        <div className={classes.table} style={{ height: 580, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                //pageSize={25}
                checkboxSelection
                disableSelectionOnClick
            />
        </div>
    </div>
}