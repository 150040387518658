import { makeStyles } from '@material-ui/core/styles'
// theme
import { Colors, Layouts, Fonts, Sprites } from '../theme'
import * as Svgs from '../theme/sprites/svgs'

export const NotFoundStyles = makeStyles({
    container: {
        ...Layouts.columnWithAllCenter as {},
    },
    container404: {
        ...Layouts.columnWithAllCenter as {},
        paddingBottom: 40,
        height: 400,
    },
    resultNotFoundText: {
        ...Fonts.fontXBig700,
        margin: 0,
    },
    noResultText: {
        ...Fonts.fontMeduimBig400,
        marginTop: 4,
        color: Colors.greyBlack6,
    },
})
