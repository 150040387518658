import { makeStyles } from '@material-ui/core/styles'
// theme
import { Fonts, Colors, Layouts } from '../theme' 

export const SearchStyles = makeStyles({
    background: {
        ...Layouts.defualtBackgroundStyle,
        backgroundColor: Colors.blue1
    },
    container: {
        ...Layouts.defaultContainerStyle,
        padding: `16px 0`,
        backgroundColor: Colors.blue1,
   },
   textInputContainer: {
        borderWidth: 0,
        height: 24,
        width: 877,
        padding: `12px 0`,
        paddingRight: 12,
        borderRadius: 4,
        ...Fonts.fontMediumBig400,
        '&:focus': {
            outline: 'none',
        }
   },
   iconContainer: {
        ...Layouts.rowWithAllCenter,
        height: 48,
        width: 48,
        backgroundColor: Colors.white,
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
   },
   searchContainer: {
        ...Layouts.rowWithJCStart,
   },
   contentContainer: {
        ...Layouts.rowWithAllCenter,
        height: 300,
   },
   articlesContainer: {
     ...Layouts.rowWithWrap as {},
     marginTop: 24,
     padding: `0 12px`,
     backgroundColor: Colors.white,
   },
   additionalArticleContainer: {
     marginRight: 12,
   },
})
