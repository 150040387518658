import _ from 'lodash'
import React from 'react'
// styles
import { FollowOurSocietyStyles } from '../../styles'
// theme
import { Sprites } from '../../theme'
// components
import { Button } from '../../components'
// helpers
import { CheckIsVersionMobile, Dictionary } from '../../helpers'

export const SocialShare = () => {

    const {
        socialShareContainer,
        socialShareContainerM,
        communityContainer,
        communityInnerContainer,
        communityTextContainer,
        communityText,
        communitySubText,
    } = FollowOurSocietyStyles()
    const isVersionMobile = CheckIsVersionMobile()

    return (
        <div className={isVersionMobile ? socialShareContainerM : socialShareContainer}>
            <div>
                {Dictionary('shareCommunities')?.map((community: string, index: number) => {
                    const resolvedCommunity = _.upperFirst(community)
                    const isCommunityYoutube = community === 'youtube'
                    const isCommunityTelegram = community === 'telegram'
                    const isCommunityInstagram = community === 'instagram'

                    return (
                        <div
                            key={`community--${index}`}
                            className={communityContainer}
                        >
                            <div className={communityInnerContainer}>
                                <Sprites
                                    icon={`IconCommunity${resolvedCommunity}`}
                                    size={`iconCommunity`}
                                    alt={`icon-community-${community}`}
                                />
                                <div className={communityTextContainer}>
                                    <h6 className={communityText}>{t(`screens.normalText.community${resolvedCommunity}`)}</h6>
                                    {(isCommunityYoutube || isCommunityTelegram) &&
                                        <div className={communitySubText}>
                                            {t(`screens.normalText.${community}Followers`, { people: 9999 })}
                                        </div>
                                    }
                                </div>
                            </div>
                            <Button
                                buttonContainerClass={isVersionMobile ? 'homePageCommunityContainerM' : `homePageCommunity${resolvedCommunity}Container`}
                                buttonTextClass={`homePageCommunityText`}
                                buttonText={isCommunityYoutube ? 'Subscribe' : 'Follow'}
                                onClick={() => null}
                            />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
