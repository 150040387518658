export { AdminPanel } from './admin-panel'
export { AdminAuthors } from './admin-authors'
export { AdminDashboard } from './admin-dashboard'
export { AdminAllArticles } from './admin-allarticle'
export { AdminNewArticles } from './admin-newarticle'
export { AdminCategoryTags} from './admin-categorytags'
export { AdminProfile } from './admin-profile'
export {AdminPlatform} from './admin-platform'
export {AdminCoin} from './admin-coin'
export {AdminOverview} from './admin-overview'
export {AdminblogBanner} from './admin-blogbanner'
export {AdminHomepageBanner} from './admin-homepagebanner'
export {AdminHomepageVideo} from './admin-homepagevideo'