import React from 'react'
// view screens
import { FollowOurSocietyView } from './follow-our-society-view'

export const FollowOurSocietyController = () => {

    const ViewProps = {

    }

    return (
        <FollowOurSocietyView {...ViewProps} />
    )
}