import React, { useState } from 'react'
// view screens
import { CoinAnalysisView } from './coin-analysis-view'

export const CoinAnalysisController = () => {


    const onClickMoreFeatures = () => {

    }

    const onClickButton = (type: string) => {
        
    }

    const ViewProps = {
        onClickMoreFeatures,
        onClickButton,
    }

    return (
        <CoinAnalysisView {...ViewProps} />
    )
}
