import _ from 'lodash'
// libraries
import { TextField as MuiTextField } from '@material-ui/core'
// styles
import { textFieldStyles, textFieldWithStyles } from '../../styles/text-field-styles'
import '../../css/text-field.css'
// types
import { ITextFieldProps } from '../../interfaces'

export const TextField = (props: ITextFieldProps) => {

    const {
        disabled = false,
        type = undefined,
        variant = undefined,
        textFieldClass = '',
        label = '',
        placeholder = '',
        layout = 'standard',
        onChange,
        value,
    } = props;

    const TextFieldWithStyles = textFieldWithStyles(MuiTextField)
    const useStyleTextField = textFieldStyles()

    return (
        <TextFieldWithStyles
            disabled={disabled}
            id={layout}
            className={`${useStyleTextField.textField} ${useStyleTextField[textFieldClass]}`}
            type={type}
            label={label}
            variant={variant}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
        />
    )
}
