export const ImageDomain = (type: string) => {
    let domain = ''
    switch(type){
        case 'user':
            domain = 'https://d3fdygqk2e2j4k.cloudfront.net/user_icon'
            break
        case 'blog':
            domain = 'https://d3fdygqk2e2j4k.cloudfront.net/blog_img'
            break
        case 'banner':
            domain = 'https://d3fdygqk2e2j4k.cloudfront.net/platform_img'
            break
        default:
            break
    }

    return domain
}