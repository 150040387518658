import { useState,useEffect } from 'react';
import {headerStyle} from "../../styles/navbar-sytles";
import {dashboardRoutes} from "../../screens/admin-panel/admin-panel"
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { useHistory } from 'react-router-dom'
import Grow from "@material-ui/core/Grow";
import MenuItem from "@material-ui/core/MenuItem";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Person from "@material-ui/icons/Person";
import Poppers from "@material-ui/core/Popper";
import { RemoveUserToken} from '../../helpers/local-storage'
import Button from '@material-ui/core/Button';
import Hidden from "@material-ui/core/Hidden";

const useStyles = headerStyle;
function GetCookies(name:any){
    var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    if (arr = document.cookie.match(reg)) return unescape(arr[2]);
    else return null;
};
const useRouteName = () => {
    let name = "";
    dashboardRoutes.forEach((route) => {
      if (window.location.href.indexOf(route.layout + route.path) !== -1) {
        name = route.name;
      }
    });
    return name;
};

function clearCookieExceptUsername() {
  var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
  if (keys) {
    for (var i = keys.length; i--;) {
      if (keys[i]!="username"){
        document.cookie = keys[i] + '=0;path=/;expires=' + new Date(0).toUTCString();
        document.cookie = keys[i] + '=0;path=/;domain=' + document.domain + ';expires=' + new Date(0).toUTCString();
        document.cookie = keys[i] + '=0;path=/;domain=kevis.com;expires=' + new Date(0).toUTCString();
      }
    }
  }
}
const logout = () => {
    clearCookieExceptUsername();
    window.location.reload();
    RemoveUserToken();
};

export default function AdminNavBar(props:any) {
    const classes = useStyles();
    const routeName = useRouteName();
    const [openProfile, setOpenProfile] = useState(null);
    const [nickname, setNickname] = useState("");
    const { color } = props;
    let history = useHistory();
    const userProfile = () => {
        setOpenProfile(null);
        history.push("/admin-panel/user")
    };
    const handleClickProfile = (event) => {
        if (openProfile ) {
          setOpenProfile(null);
        } else {
          setOpenProfile(event.currentTarget);
        }
      };
      const handleCloseProfile = () => {
        setOpenProfile(null);
      };
      useEffect(() => {
        const cookiesNickname =GetCookies("nickname") 
        if (cookiesNickname != null){
            setNickname(cookiesNickname);
        }
      }, [])
    return (
        <AppBar color="transparent"  position="fixed" className={classes.appBar }>
          <Toolbar className={classes.container}>
            <a className={classes.title}>
                {routeName}
            </a>
            <Hidden smDown implementation="css">
            <div className={classes.manager}>
                <a className={classes.name}>
                    Hi, {nickname}
                </a>
                <Button color="primary" onClick={handleClickProfile} className={classes.buttonLink}>
                    <Person  />
                        <Hidden mdUp implementation="css">
                            <p className={classes.linkText}>Profile</p>
                        </Hidden>
                </Button>
              
                <Poppers
                open={Boolean(openProfile)}
                anchorEl={openProfile}
                transition
                disablePortal
                >
            {({ TransitionProps, placement }) => (
                <Grow
                {...TransitionProps}
                style={{
                    transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
                >
                <Paper>
                    <ClickAwayListener onClickAway={handleCloseProfile}>
                    <MenuList role="menu">
                        <MenuItem
                        onClick={userProfile}
                        >
                        Profile
                        </MenuItem>
                        <MenuItem
                        onClick={logout}
                        >
                        Logout
                        </MenuItem>
                    
                    </MenuList>
                    </ClickAwayListener>
                </Paper>
                </Grow>
            )}
            </Poppers>
        </div>
            </Hidden>
          </Toolbar>
        </AppBar>
      );


}