import _ from 'lodash'
import { CSSProperties } from 'react'
// theme
import { Colors } from './colors'
// helpers
import { ObjectCamelCaseKeys } from '../helpers'
// types
import { Fonts as Fs } from '../interfaces'

const fontFamily = undefined
// const fontFamilyBold = undefined

// 0 -> normal
// number -> number
// 1000 -> bold
const fontBoldSizes = ['0', '400', '500', '700', '1000']

const getFontSizeLineHeight = (fontSize: number, lineHeight: number) => {
    return {
        fontSize,
        lineHeight: `${lineHeight}px`,
        // letterSpacing: -0.3,
        color: Colors.black1,
    }
}

const config = {
    fontXSmall: getFontSizeLineHeight(10, 14),
    fontSmall: getFontSizeLineHeight(12, 17),
    fontMedium: getFontSizeLineHeight(14, 20),
    fontMediumBig: getFontSizeLineHeight(16, 23),
    fontXMediumBig: getFontSizeLineHeight(18, 26),
    fontBig: getFontSizeLineHeight(20, 29),
    fontXBig: getFontSizeLineHeight(24, 35),
    font2XBig: getFontSizeLineHeight(32, 46),
    font3XBig: getFontSizeLineHeight(35, 46),
    font4XBig: getFontSizeLineHeight(40, 40),
    font5XBig: getFontSizeLineHeight(56, 65),
}

const fonts = [
    'font_x_small',
    'font_small',
    'font_medium',
    'font_medium_big',
    'font_x_medium_big',
    'font_big',
    'font_x_big',
    'font_2x_big',
    'font_3x_big',
    'font_4x_big',
    'font_5x_big',
]

const resolvedFonts = _.reduce(fonts, (acc: string[], item: string) => {
    return [
        ...acc, 
        ..._.reduce(fontBoldSizes, (bolds: string[], bold) => {
            return [
                ...bolds,
                `${item}_${bold}`
            ]
        }, [])
    ]
}, [])

const fontStyles = _.reduce(resolvedFonts, (acc: any | {}, font: string) => {
    const fontSplit = font.split('_')
    const fontNumber = fontSplit[fontSplit.length - 1]
    const resolvedFont = _.camelCase(font.substring(0, font.lastIndexOf('_')))
    return {
        ...acc,
        ...ObjectCamelCaseKeys({
            [font]: {
                ...config[resolvedFont],
                fontWeight: fontNumber === '0' ? 'normal' : fontNumber === '1000' ? 'bold' : fontNumber,
                fontFamily,
            },
        })
    }
}, {}) as any

export const Fonts: any = {
    ...fontStyles,
}
