import _ from 'lodash'
import React, { useState } from 'react'
// styles
import { BeginnerGuideStyles } from '../../styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
// theme
import { Sprites } from '../../theme'
// components
import { BeginnerGuideItem } from '../../components'
// helpers
import { CheckIsVersionMobile } from '../../helpers'

export const BeginnerGuide = () => {

    const {
        background,
        container,
        containerM,
        titleText,
        titleTextM,
        subTitleText,
        subTitleTextM,
        subContainer,
        subContainerM,
        itemContainer,
        verticalLine1,
        verticalLine2,
        verticalLine3,
        verticalLine1M,
        verticalLine2M,
        verticalLine3M,
        verticalLine1L794,
        verticalLine2L794,
        verticalLine3L794,
    } = BeginnerGuideStyles()
    const isVersionMobile = CheckIsVersionMobile()
    const isLowerThan794 = useMediaQuery('(max-width: 793px)')
    const isHigherThan480 = useMediaQuery('(min-width: 481px)')
    const isInRange = isLowerThan794 && isHigherThan480

    return (
        <div className={background}>
            <div className={isVersionMobile ? containerM : container}>
                {isVersionMobile
                    ? <h3 className={titleTextM}>{t('screens.normalText.beginnerGuide2')}</h3>
                    : <h2 className={titleText}>{t('screens.normalText.beginnerGuide2')}</h2>
                }
                <span className={isVersionMobile ? subTitleTextM : subTitleText}>{t('screens.normalText.startLearningCrypto')}</span>
                <div className={isVersionMobile ? subContainerM : subContainer}>
                    {isVersionMobile
                        ? null
                        : <div className={itemContainer}>
                            <Sprites
                                icon={'IconBeginnerGuide'}
                                size={'iconBeginnerGuide'}
                                alt={'icon-beginner-guide'}
                            />
                        </div>
                    }
                    <div className={isVersionMobile ? '' : itemContainer}>
                        <div className={isVersionMobile ? verticalLine1M : isInRange ? verticalLine1L794 : verticalLine1} />
                        <div className={isVersionMobile ? verticalLine2M : isInRange ? verticalLine2L794 : verticalLine2} />
                        <div className={isVersionMobile ? verticalLine3M : isInRange ? verticalLine3L794 : verticalLine3} />
                        {_.range(1, 5).map((n: number) => {
                            return (
                                <BeginnerGuideItem
                                    isInRange={isInRange}
                                    id={n}
                                    title={t(`screens.normalText.beginnerGuideBrief${n}`)}
                                    subTitle={t(`screens.normalText.beginnerGuideDescription${n}`)}
                                />
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
