import { useState, useEffect,createRef,RefObject } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Dashboard from "@material-ui/icons/Dashboard";
// import PerfectScrollbar from "perfect-scrollbar";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import { AdminSidebar} from "../../components"
import logo from "../../theme/sprites/svgs/logo.svg";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AirplayIcon from '@material-ui/icons/Airplay';
import { Label, MenuBook, Message, People, Settings } from '@material-ui/icons';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CreateIcon from '@material-ui/icons/Create';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';
import { AdminAuthors } from './admin-authors';
import { AdminDashboard } from './admin-dashboard';
import {AdminNavBar} from "../../components";
import { AdminNewArticles } from './admin-newarticle';
import { AdminAllArticles } from './admin-allarticle';
import { AdminCategoryTags } from './admin-categorytags';
import { AdminProfile } from './admin-profile';
import { AdminPlatform } from './admin-platform';
import {AdminblogBanner} from './admin-blogbanner'
import {AdminCoin} from './admin-coin';
import { AdminHomepageBanner } from './admin-homepagebanner';
import { AdminHomepageVideo } from './admin-homepagevideo';
import { AdminOverview } from './admin-overview';


let ps;

const drawerWidth = 260;

const container = {
  paddingRight: "15px",
  paddingLeft: "15px",
  marginRight: "auto",
  marginLeft: "auto",
};
const adminStyle = makeStyles((theme) => ({
    wrapper: {
      position: "relative",
      top: "0",
      height: "100vh",
    },
    mainPanel: {
      [theme.breakpoints.up("md")]: {
        width: `calc(100% - ${drawerWidth}px)`,
      },
      overflow: "auto",
      position: "relative",
      float: "right",
      maxHeight: "100%",
      width: "100%",
      overflowScrolling: "touch",
    },
    content: {
      marginTop: "64px",
      paddingLeft:"13px",
      backgroundColor:"#eeeeee",
      minHeight: "calc(100vh - 0px)",
    },
    container,
    map: {
      marginTop: "70px",
    },
}));

function GetCookies(name:any){
  var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  if (arr = document.cookie.match(reg)) {
    console.log(arr[2]);
    return unescape(arr[2]);
  }else {return null};
};



export let dashboardRoutes = GetCookies("role")==="Admin"? [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: Dashboard,
      component: AdminDashboard,
      layout: "/admin-panel",
    },
    {
        path: "/articles",
        name: "Articles",
        icon: LibraryBooks,
        component: AdminAllArticles,
        layout: "/admin-panel",
    },
    {
      path: "/article_editor",
      name: "Article Editor",
      icon: CreateIcon,
      component: AdminNewArticles,
      layout: "/admin-panel",
    },
    {
        path: "/category_tags",
        name: "Category / Tags",
        icon: Label,
        component: AdminCategoryTags,
        layout: "/admin-panel",
    },
    {
      path: "/platform",
      name: "Platform",
      icon: AirplayIcon,
      component: AdminPlatform,
      layout: "/admin-panel",
    },
    {
      path: "/coin",
      name: "Coin",
      icon: AttachMoneyIcon,
      component: AdminCoin,
      layout: "/admin-panel",
    },
    {
      path: "/authors",
      name: "Authors",
      icon: People,
      component: AdminAuthors,
      layout: "/admin-panel",
    },
    {
      path: "/user",
      name: "User Profile",
      icon: Person,
      component: AdminProfile,
      layout: "/admin-panel",
    },
    {
      path: "/overview",
      name: "Overview",
      icon: AssignmentIcon,
      component: AdminOverview,
      layout: "/admin-panel",
    },
    {
      path: "/homepage_banner",
      name: "Homepage Banner",
      icon: AspectRatioIcon,
      component: AdminHomepageBanner,
      layout: "/admin-panel",
    },
    {
      path: "/homepage_video",
      name: "Homepage Video",
      icon: ArtTrackIcon,
      component: AdminHomepageVideo,
      layout: "/admin-panel",
    },
  ]:[
    {
      path: "/articles",
      name: "Articles",
      icon: LibraryBooks,
      component: AdminAllArticles,
      layout: "/admin-panel",
    },
    {
      path: "/article_editor",
      name: "Article Editor",
      icon: CreateIcon,
      component: AdminNewArticles,
      layout: "/admin-panel",
    },
    {
      path: "/user",
      name: "User Profile",
      icon: Person,
      component: AdminProfile,
      layout: "/admin-panel",
    },
  ];

  const switchRoutes = (
    <Switch>
      {dashboardRoutes.map((prop, key) => {
        if (prop.layout === "/admin-panel") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
        return null;
      })}

      <Redirect from="/admin-panel" to="/admin-panel/dashboard" />
    </Switch>
  );

export const AdminPanel = () => {
    const classes = adminStyle();
    const mainPanel = createRef();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [color] = useState("blue");
    const [showSideBar,setShowSideBar]= useState(true);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
      };

    const resizeFunction = () => {
      if (window.innerWidth >= 960) {
        setMobileOpen(false);
        setShowSideBar(true);
      }
      if (window.innerWidth < 960) {
        setMobileOpen(true);
        setShowSideBar(false);
      }
    };
    // initialize and destroy the PerfectScrollbar plugin
    useEffect(() => {
      // if (navigator.platform.indexOf("Win") > -1) {
      //   ps = new PerfectScrollbar(mainPanel.current as any, {
      //     suppressScrollX: true,
      //     suppressScrollY: false,
      //   });
      //   document.body.style.overflow = "hidden";
      // }

      window.addEventListener("resize", resizeFunction);
      // Specify how to clean up after this effect:
      return function cleanup() {
        if (navigator.platform.indexOf("Win") > -1) {
          ps.destroy();
        }
        window.removeEventListener("resize", resizeFunction);
      };
    }, [mainPanel]);
    return (
        <div className={classes.wrapper}>
          {showSideBar?<AdminSidebar
                routes={dashboardRoutes}
                logoText={"Crypto WeSearch"}
                logo={logo}
                image={logo}
                handleDrawerToggle={handleDrawerToggle}
                open={mobileOpen}
                color={color}
            />:null}
            <div className={classes.mainPanel}   >
                {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
                {/* {getRoute() ? (
                  <div className={classes.content}>
                    <div className={classes.container}>{switchRoutes}</div>
                  </div>
                ) : (
                  <div className={classes.map}>{switchRoutes}</div>
                )} */}
                <div className={classes.content}>
                    <div className={classes.container}>{switchRoutes}</div>
                  </div>
                
                <AdminNavBar
                  routes={dashboardRoutes}
                  handleDrawerToggle={handleDrawerToggle}
                />

               
            </div>
        </div>
    
    );
}