import { makeStyles } from '@material-ui/core/styles'
// theme
import { Fonts, Colors, Layouts } from '../theme'

export const BeginnerGuideItemHeight = 160
export const BeginnerGuideItemIconSize = 70
const verticalLinePositionDefault = (n: number, isVM: boolean = false) => ((isVM ? 100 : BeginnerGuideItemHeight) + BeginnerGuideItemIconSize) / 2 + ((isVM ? 100 : BeginnerGuideItemHeight) * n)

const verticalLineStyle = (n: number, is794L: boolean = false, isVM: boolean = false) => {
    return {
        borderLeft: Layouts.borderLine(Colors.blue4, 2),
        position: 'absolute',
        height: (isVM ? 100 : BeginnerGuideItemHeight) - BeginnerGuideItemIconSize + (is794L ? 18 : 0),
        marginTop: verticalLinePositionDefault(n, isVM) - (is794L ? 9: 0),
        marginLeft: 82 - (is794L ? 32.5: 0) - (isVM ? 48 : 0),
    }
}

export const BeginnerGuideStyles = makeStyles({
    background: {
        ...Layouts.defualtBackgroundStyle,
    },
    container: {
        ...Layouts.defaultContainerStyle,
        paddingTop: 80,
        paddingBottom: 52,
        borderBottom: Layouts.borderLine(Colors.blue5)
    },
    containerM: {
        ...Layouts.M.defaultContainerStyle,
        marginTop: 12,
        marginBottom: 80,
    },
    titleText: {
        ...Fonts.fontXBig700,
        lineHeight: 0,
    },
    titleTextM: {
        ...Fonts.fontBig700,
        marginLeft: 24,
        marginRight: 24,
        lineHeight: 0,
        color: Colors.greyBlack85,
    },
    subTitleText: {
        ...Fonts.fontMedium400,
        color: Colors.greyBlack85,
    },
    subTitleTextM: {
        ...Fonts.fontMedium400,
        ...Layouts.M.marginHorizontal,
        color: Colors.greyBlack6,
    },
    subContainer: {
        ...Layouts.rowWithAllCenter as {},
        marginTop: 16,
    },
    subContainerM: {
        ...Layouts.rowWithAllCenter as {},
        ...Layouts.M.marginHorizontal,
        marginTop: 16,
    },
    itemContainer: {
        width: '50%',
    },
    verticalLine1: {
        ...verticalLineStyle(0) as {}
    },
    verticalLine2: {
        ...verticalLineStyle(1) as {}
    },
    verticalLine3: {
        ...verticalLineStyle(2) as {}
    },
    verticalLine1M: {
        ...verticalLineStyle(0, false, true) as {}
    },
    verticalLine2M: {
        ...verticalLineStyle(1, false, true) as {}
    },
    verticalLine3M: {
        ...verticalLineStyle(2, false, true) as {}
    },
    verticalLine1L794: {
        ...verticalLineStyle(0, true) as {}
    },
    verticalLine2L794: {
        ...verticalLineStyle(1, true) as {}
    },
    verticalLine3L794: {
        ...verticalLineStyle(2, true) as {}
    },
})
