import _ from 'lodash'
import moment from 'moment'
import React from 'react'
// helpers
import { CheckIsVersionMobile, ImageDomain } from '../../helpers'
// styles
import { ContentBriefItemStyles } from '../../styles'
// theme
import { Layouts, Images, Sprites } from '../../theme'
// libraries
import { Link } from 'react-router-dom'

export const ContentBriefItemView = (props) => {

    const {
        isLastItem = false,
        isCategoryOffers,
        isCategoryNews,
        isCategoryAboutUs,
        isCategoryBlogArticles,
        item,
        categories,
        onClickItem,
    } = props

    const {
        blogArticlesContainerM,
        blogArticlesImageContainerM,
        blogArticlesTextM,
        latestOffersContainer,
        latestOffersImageContainer,
        latestOffersTextContainer,
        latestOffersText,
        latestNewsContainer,
        latestNewsContainerM,
        latestNewsImageContainer,
        latestNewsImageContainerM,
        latestNewsTextContainer,
        latestNewsText,
        latestNewsTextM,
        latestNewsSubText,
        labelContainerWrapper,
        aboutUsContainer,
        aboutUsContainerM,
        aboutUsImageContainer,
        aboutUsImageContainerM,
        aboutUsTextContainer,
        aboutUsText,
        aboutUsTextM,
        aboutUsSubText,
        latestOffersImageContainerM,
        itemContainer,
        latestOffersContainerM,
        marginRightM,
        newslabelContainer,
        newslabelText,
    } = ContentBriefItemStyles()

    const isVersionMobile = CheckIsVersionMobile()
    const firstCategoryPath = categories ? categories[0]?.categoryPath : null
    const resolvedFirstCategoryPath = firstCategoryPath !== null ? null : item?.categories ? item?.categories[0]?.categoryPath : null
    const finalFirstCategoryPath = firstCategoryPath !== null ? firstCategoryPath : resolvedFirstCategoryPath

    const RenderLabelComponent = () => (
        isCategoryNews && !_.isEmpty(item?.tags)
            ? <div className={labelContainerWrapper}>
                {item.tags.map((tag: { tagCn: string }, index: number) => {
                    return (
                        <div key={`label--${index}`}>
                            <div className={newslabelContainer}>
                                <div className={newslabelText}>{tag.tagCn}</div>
                            </div>
                        </div>
                    )
                })}
            </div>
            : null
    )

    const RenderComponentM = () => {
        return (
            <Link
                to={`/n/blog/${finalFirstCategoryPath}/${item?.path}`}
                style={Layouts.noUnderline}
                onClick={() => onClickItem(item?.path)}
            >
                <div
                    className={`${isCategoryBlogArticles ? blogArticlesContainerM : isCategoryNews ? latestNewsContainerM : isCategoryOffers ? latestOffersContainerM : aboutUsContainerM} ${isLastItem && marginRightM}`}
                    onClick={() => onClickItem(item?.postId)}
                >
                    <div className={`${isCategoryBlogArticles ? blogArticlesImageContainerM : isCategoryNews ? latestNewsImageContainerM : isCategoryOffers ? latestOffersImageContainerM : aboutUsImageContainerM}`}></div>
                    {RenderLabelComponent()}
                    {isCategoryAboutUs
                        ? <h6 className={aboutUsTextM}> 使用獨家優惠碼申請，即享20%永久交易折扣碼申請，即享20%永久交易折扣</h6>
                        : <h5 className={isCategoryBlogArticles ? blogArticlesTextM : latestNewsTextM}>{item?.title}</h5>
                    }
                    {isCategoryNews && <span className={latestNewsSubText}>{moment(item?.postDate).format('LL')}</span>}
                </div>
            </Link>
        )
    }

    const RenderComponent = () => {
        
        return (
            <Link
                to={`/n/blog/${finalFirstCategoryPath}/${item?.path}`}
                style={Layouts.noUnderline}
                onClick={() => onClickItem(item?.path)}
            >
                <div className={`${isCategoryNews ? latestNewsContainer : isCategoryOffers ? latestOffersContainer : aboutUsContainer} ${itemContainer}`}>
                    <div className={`${isCategoryNews ? latestNewsImageContainer : isCategoryOffers ? latestOffersImageContainer : aboutUsImageContainer}`}>
                        {!isCategoryAboutUs ?
                            <Sprites
                                isLink
                                icon={`${ImageDomain('blog')}/${item?.postUuid}`}
                                size={'iconHomePagePost'}
                                alt={item?.imageAlt}
                            />
                            : null
                        }
                    </div>
                    {RenderLabelComponent()}
                    <div className={isCategoryNews ? latestNewsTextContainer : isCategoryOffers ? latestOffersTextContainer : aboutUsTextContainer}>
                        <h5 className={isCategoryNews ? latestNewsText : isCategoryOffers ? latestOffersText : aboutUsText}>{item?.title}</h5>
                        {isCategoryNews
                            ? <span className={latestNewsSubText}>{moment(item?.postDate).format('LL')}</span>
                            : isCategoryAboutUs
                                ? <div className={aboutUsSubText}>你可以在這裡探索過百篇教學文章，由0至1學習投資加密貨幣。</div>
                                : null
                        }
                    </div>
                </div>
            </Link>
        )
    }

    return (
        isVersionMobile
            ? RenderComponentM()
            : RenderComponent()
    )
}
