import React from 'react'
// styles
import { PopoverStyles } from '../../styles'
// libraries
import { Popover as MuiPopover } from '@material-ui/core';

export const Popover = (props) => {

    const {
        popoverRef,
        isPopoverOpened,
        onHoverTitle,
        onLeaveTitle,
        renderComponent,
    } = props

    const {
        popover,
        popoverContent,
    } = PopoverStyles()

    return (
        <MuiPopover
            style={{
                marginTop: 24,
            }}
            id="mouse-over-popover"
            className={popover}
            classes={{
                paper: popoverContent,
            }}
            open={isPopoverOpened}
            anchorEl={popoverRef?.current}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 46,
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            PaperProps={{ onMouseEnter: onHoverTitle, onMouseLeave: onLeaveTitle }}
        >
            {renderComponent()}
        </MuiPopover>
    )
}
