// types
import { APICategory } from '../../interfaces'

export const GetCurrentArticleCategories = () => {
    const categories = localStorage.getItem('@ARTICLE_CATEGORIES')
    const resolvedCategories = categories !== null ? JSON.parse(categories) : '' 

    return resolvedCategories
}

export const SetCurrentArticleCategories = (categories: APICategory[]) => {
    localStorage.setItem("@ARTICLE_CATEGORIES", JSON.stringify(categories))
}

export const RemoveCurrentArticleCategories = () => {
    localStorage.removeItem('@ARTICLE_CATEGORIES')
}
