import { makeStyles} from '@material-ui/core/styles'

export const headerStyle = makeStyles((theme) => ({
    appBar: {
      backgroundColor: "transparent",   
      boxShadow: "none",
      borderBottom: "0",
      marginBottom: "0",
      width: "100%",
      paddingLeft:"260px",
      border: "0",
      borderRadius: "0px",
      transition: "all 150ms ease 0s",
    },
    container: {
      backgroundColor:"#eeeeee",
      paddingRight: "15px",
      paddingLeft: "15px",
    },
    flex: {
      flex: 1,
    },
    title: {
      marginLeft:35,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 350,
      letterSpacing: "unset",
      fontSize: "18px",
      borderRadius: "3px",
      textTransform: "none",
      color: "#555555",    
    },
    name: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 360,
      fontSize: "14px",
      textTransform: "none",
      color: "#555555",
    },
    manager: {
      position: "absolute", 
  　  right:5,
      top:8,
    },
    buttonLink: {
      width: "30px",
      height: "30px",
      marginLeft: "-10px",
    },
    linkText: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 300,
      lineHeight: "1.5em",
      fontSize: "14px",
      margin: "0px",
    },
   
}));
