import React, { useEffect } from 'react'
// view screens
import { DrawerView } from './drawer-view'

export const DrawerController = (props) => {

    const {
        position,
        onHandleDrawerVisibiity,
    } = props

    const onClose = () => {
        onHandleDrawerVisibiity(false, position)
    }

    const ViewProps = {
        ...props,
        onClose,
    }


    return (
        <DrawerView {...ViewProps} />
    )
}