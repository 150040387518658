import _ from 'lodash'
import axios from 'axios'
// helpers
import { ObjectSnakeCaseKeys, CancelRequest, APICatchError, APIHeaders, APIDomainHTTP, APIVersion, ObjectCamelCaseKeys } from '../helpers'
// types
import { APIArticle } from '../interfaces'

export const GetHomePageArticlesAPI = async (categoryId: number, isVersionMobile: boolean) => {

    const { get, CancelToken } = axios
    const url = `${APIDomainHTTP()}/${APIVersion()}/articles_brief/home`
    const headers = APIHeaders()

    const source = CancelToken.source()

    CancelRequest.add(
        'GetHomePageArticles',
        () => source.cancel('GetHomePageArticles: Operation cancelled by user'),
    )

    const params = ObjectSnakeCaseKeys({
        categoryId,
        isVersionMobile,
    })

    try {
        const res: {
            status: number;
            message: string;
            data: {
                results: { 
                    newsArticles: APIArticle[],
                    allArticles: APIArticle[],
                },
            };
        } = await get(url, {
            params,
            headers,
            cancelToken: source.token,
        })

        if (res.status !== 200) {
            throw new Error(APICatchError(res.message))
        }

        const { data } = ObjectCamelCaseKeys(res)

        const { results } = data
        return results
    } catch (e) {
        throw new Error(APICatchError(e.message))
    }
} 
