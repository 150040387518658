import { CSSProperties } from "react"
import { Colors } from "./colors"

const contentWidth = 1128
const contentWidthM = 375
const backgroundWidth = window.innerWidth

// R stands for responsive

export const Layouts = {
    M: {
        defaultContainerStyle: {
            width: contentWidthM,
        },
        contentWidth: contentWidthM,
        homePageHeight: {
            navBar: 80,
            header: 553,
            content: 1617,
            subFooter: 256,
            footer: 511,
        },
        marginHorizontal: {
            margin: `0 24px`,
        }
    },
    backgroundWidth,
    contentWidth,
    defualtBackgroundStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    defaultContainerStyle: {
        width: contentWidth,
    },
    coverLayer: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    rowWithWrap: {
        display: 'flex',
        flexWrap: 'wrap',
        // alignItems: 'center',
    },
    rowWithAllCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    rowWithJCStart: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    rowWithJCSpaceBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    rowWithJCSpaceBetweenAIStart: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
    rowWithJCEnd: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    rowWithAIStart: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    columnWithAllCenter: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    columnWithAIStart: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    columnWithJCStart: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    columnJCSpaceBetweenAIStart: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
    columnWithJCSpaceBetween: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    rowWithAISpaceBetween: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'space-between',
    },
    containerPadding: `0 ${(window.innerWidth - contentWidth) / 2}px`,
    homePageHeight: {
        navBar: 80,
        header: 525,
        content: 2486 + 50,
        subFooter: 240,
        footer: 282,
    },
    noUnderline: {
        textDecoration: 'none',
    },
    borderLine: (c:string = Colors.black0, n:number = 1, t:string = 'solid') => `${n}px ${t} ${c}`
}
