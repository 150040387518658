import { makeStyles } from '@material-ui/core/styles';
import { Button, Select, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton,Input,InputAdornment,Snackbar } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import SearchIcon from '@material-ui/icons/Search';
import React,{ useEffect,useState } from 'react';
import {  Route, Redirect,useHistory } from 'react-router-dom'
import { UseDataFetching, GetArticlesListAPI, GetArticlesNumberAPI,GetTagsListAPI,GetCategoryListAPI,MoveArticleToRecycleBinAPI, DeleteArticleAPI, GetAdminUsersAPI } from '../../apis'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';


function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function GetCookies(name:any){
  var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  if (arr = document.cookie.match(reg)) return unescape(arr[2]);
  else return null;
};
const ArticlesStyle = makeStyles((theme) => ({
    wrapper:{
       marginLeft:5,
       marginRight:15,
    },
    appbar:{
        marginLeft:10, 
        width:320,
        backgroundColor:"white",
    },
    root: {
        borderRadius:4,
        backgroundColor:"white",
        '& > *': {
          margin: theme.spacing(1),
        },
    },
    selectEmpty: {
        borderRadius:4,
        backgroundColor:"white",
        paddingBottom:10,
        '& > *': {
            marginLeft: theme.spacing(1),
          },
    },
    group:{
      backgroundColor:"white",
      border:1,
      borderRadius:4,
      marginTop:15,
      borderStyle:"solid",
      borderColor:"#bdbdbd",
    },
    select:{
        marginLeft:15,
        width:120,
        height:30,
    },
    selectButton:{
        height:30,
    },
    table:{
        borderRadius:4,
        backgroundColor:"white",
    },
    input:{
      height:20,
    },

    paginNation: {
      marginTop:15,
      paddingBottom:20,
  },
}));

interface Data {
    index:string;
    title: string;
    author: string;
    category: string;
    tags: string;
    date: string;
}
interface Column {
    id: 'index'|'title'| 'status' | 'author' | 'category' | 'tags' | 'date';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
  }
const columns: Column[] = [
    { id: 'index', label: 'ID', minWidth: 5 },
    { id: 'title', label: '內容標題', minWidth: 10 },
    { id: 'status', label: '狀態', minWidth: 10 },
    { id: 'author', label: '作者', minWidth: 10 },
    {
      id: 'category',
      label: '分類',
      minWidth: 100,
     // align: 'left',
    },
    {
      id: 'tags',
      label: '標籤',
      minWidth: 100,
     // align: 'left',
    },
    {
      id: 'date',
      label: '發布日期',
      minWidth: 10,
      //align: 'left',
    },
  ];


export const AdminAllArticles = () => {
    const classes = ArticlesStyle();
    let history = useHistory();
    const [year, setYear] = useState(0);
    const [month, setMonth] = useState(0);
    const [category, setCategory] = useState("all");
    const [tag, setTag] = useState("all");
    const [tagRows ,setTagRows ] = useState([]);
    const [categoryRows ,setCategoryRows ] = useState([]);
    const [authorRows ,setAuthorRows ] = useState([]);
    const [author, setAuthor] =useState("all");


    const [postStatus, setPostStatus] = useState("all");
    const [page, setPage] = useState(1);
    const [totalButtonColor, setTotalButtonColor] = useState("black");
    const [recycledButtonColor, setRecycledButtonColor] = useState("grey");
    const [publishedButtonColor, setPublishedButtonColor] = useState("grey");
    const [draftButtonColor, setDraftButtonColor] = useState("grey");
    const [pendingButtonColor, setPendingButtonColor] = useState("grey");
    const [total, setTotalNumber] = useState(0);
    const [keyword, setKeyword] = useState("");
    const [recycled, setRecycledNumber] = useState(0);
    const [published, setPublishedNumber] = useState(0);
    const [draft, setDraftNumber] = useState(0);
    const [pending, setPendingNumber] = useState(0);
    const [rows ,setRows ] = useState([]);
  
    const [changeSuccess, setChangeSuccess] = useState(false);
    const rowsPerPage = 10

    const handleMonthChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      setMonth(event.target.value as any);
    };
    const handleYearChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      setYear(event.target.value as any);
    };
    const handleCategoryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      setCategory(event.target.value as any);
    };
    const handleTagChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      setTag(event.target.value as any);
    };
    const handleAuthorChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      setAuthor(event.target.value as any);
    };
    const handleKeywordChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      setKeyword(event.target.value as any);
    };
    const handleChangePage = (event: unknown, newPage: number) => {
       setPage(newPage);
       GetTableData(year,month,category,tag,postStatus,newPage,keyword,author);
    };
    const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
      setChangeSuccess(false);
    };

    const ClickTotalData = () => {
      setPostStatus("all");
      setTotalButtonColor("black");
      setRecycledButtonColor("grey");
      setPublishedButtonColor("grey");
      setDraftButtonColor("grey");
      setPendingButtonColor("grey");
      GetTableData(year,month,category,tag,"all",page,"",author)
    };
    const ClickRecycledButton = () => {
      setPostStatus("recycled");
      setTotalButtonColor("grey");
      setRecycledButtonColor("black");
      setPublishedButtonColor("grey");
      setDraftButtonColor("grey");
      setPendingButtonColor("grey");
      GetTableData(year,month,category,tag,"recycled",page,"",author)
    };
    const ClickDraftButton = () => {
      setPostStatus("draft");
      setTotalButtonColor("grey");
      setRecycledButtonColor("grey");
      setPublishedButtonColor("grey");
      setDraftButtonColor("black");
      setPendingButtonColor("grey");
      GetTableData(year,month,category,tag,"draft",page,"",author)
    };
    const ClickPendingButton = () => {
      setPostStatus("penging");
      setTotalButtonColor("grey");
      setRecycledButtonColor("grey");
      setPublishedButtonColor("grey");
      setDraftButtonColor("grey");
      setPendingButtonColor("black");
      GetTableData(year,month,category,tag,"pending",page,"",author)
    };
    
    const ClickpublishedButton = () => {
      setPostStatus("published");
      setTotalButtonColor("grey");
      setRecycledButtonColor("grey");
      setPublishedButtonColor("black");
      setDraftButtonColor("grey");
      setPendingButtonColor("grey");
      GetTableData(year,month,category,tag,"published",page,"",author)
    };

    const ClickSearchButton = () => {
      GetTableData(year,month,category,tag,postStatus,page,"",author)
    }

    const ClickSearchKeywordButton = () => {
      setPostStatus("all");
      setTotalButtonColor("black");
      setRecycledButtonColor("grey");
      setPublishedButtonColor("grey");
      setDraftButtonColor("grey");
      setPendingButtonColor("grey");
      setMonth(0);
      setYear(0);
      GetTableData(year,month,category,tag,postStatus,page,keyword,author);
    }

    const GetNumberData = async() => {
      const  {error,results} =  await UseDataFetching(GetArticlesNumberAPI({})) as any;
      setRecycledNumber(results['recycled']);
      setPublishedNumber(results['published']);
      setTotalNumber(results['total']);
      setDraftNumber(results['draft']);
      setPendingNumber(results['pending']);
    };

    const ClickMoveDataToRecycleBinButton = async(postId:number) => {
      const  {error,results} =  await UseDataFetching(MoveArticleToRecycleBinAPI({postId:postId})) as any;
      GetTableData(year,month,category,tag,postStatus,page,"",author);
      setChangeSuccess(true);
      GetNumberData();
    };
    const ClickDeleteDataButton = async(postId:number) => {
      const  {error,results} =  await UseDataFetching(DeleteArticleAPI({postId:postId})) as any;
        GetTableData(year,month,category,tag,postStatus,page,"",author);
        setChangeSuccess(true);
        GetNumberData();
    };

    const GetTagsData =  async()=> {
      var list:any =[];
      const  {error,results} =  await UseDataFetching(GetTagsListAPI({})) as any;
      (results['tags']||[]).map(function(result:any){
         list.push({'id':result.tag_id,'tag_cn':result.tag_cn,'tag_en':result.tag_en });
      })
      setTagRows(list);
    }
    const GetCategoryData =  async()=> {
      var list:any =[];
      const  {error,results} =  await UseDataFetching(GetCategoryListAPI({})) as any;
      (results['category']||[]).map(function(result:any){
        list.push({'id':result.category_id,'category':result.category_cn + ' / '+ result.category_en ,'category_cn':result.category_cn,'category_en':result.category_en});
      })
      setCategoryRows(list);
    };

    const GetAuthorsData =  async()=> {
      var list:any =[];
      const  {error,results} =  await UseDataFetching(GetAdminUsersAPI({})) as any;
      (results['users']||[]).map(function(result:any){
        list.push({'user_id':result.user_id,'nickname':result.nickname,'username':result.username});
      })
      setAuthorRows(list);
    };

    const GetTableData = async(year:number,month:number,category:string,tag:string,status:string,page:number,keyword:string,author:string) => {
      var list:any =[];
      if (GetCookies("role") !=="Admin"){
        author = GetCookies("userId") as string
      }
      const  {error,results} =  await UseDataFetching(GetArticlesListAPI({year:year,month:month,category:category,tag:tag,status:status,page:page,keyword:keyword,author:author})) as any;
      (results['articles']||[]).map(function(result:any){
        list.push({'index':result.index,'id':result.post_id,'status':result.post_status,'title':result.title,'author':result.author, 'category':result.category,'tags':result.tags,'date':result.date,'path':result.path });
      })
      setRows(list);
    };

  
    useEffect(() =>{
      GetNumberData()
      var date = new Date()
      GetTableData(year,month,category,tag,postStatus,page,"",author)
      
      GetTagsData()
      GetCategoryData()
      GetAuthorsData()
    }, []);

    return <div>
        <div className={classes.wrapper}>
          <div className={classes.group}>
            {GetCookies("role")==="Admin"?
            <div className={classes.root}>
                <Button onClick={ClickTotalData}><a style={{color:totalButtonColor,fontSize:16}}>全部({total})</a></Button>
                <Button onClick={ClickpublishedButton}><a style={{color:publishedButtonColor,fontSize:16}}>已發布({published})</a></Button>
                <Button onClick={ClickDraftButton}><a style={{color:draftButtonColor,fontSize:16}}>草稿({draft})</a></Button>
                <Button onClick={ClickPendingButton}><a style={{color:pendingButtonColor,fontSize:16}}>待审核({pending})</a></Button>
                <Button onClick={ClickRecycledButton}><a style={{color:recycledButtonColor,fontSize:16}}>回收桶({recycled})</a></Button></div>:
                <div className={classes.root}>
                <Button onClick={ClickTotalData}><a style={{color:totalButtonColor,fontSize:16}}>全部</a></Button>
                <Button onClick={ClickpublishedButton}><a style={{color:publishedButtonColor,fontSize:16}}>已發布</a></Button>
                <Button onClick={ClickDraftButton}><a style={{color:draftButtonColor,fontSize:16}}>草稿</a></Button>
                <Button onClick={ClickPendingButton}><a style={{color:pendingButtonColor,fontSize:16}}>待审核</a></Button>
                <Button onClick={ClickRecycledButton}><a style={{color:recycledButtonColor,fontSize:16}}>回收桶</a></Button></div>
            }


            <div className={classes.selectEmpty}>
              <Select
                  value={year}
                  onChange={handleYearChange}
                  className={classes.select}
                  native
                  variant="outlined" 
                  >
                  <option aria-label="None" value={0} style={{display: "none"}}>年</option>   
                  <option key={0} value={0}>不限</option>
                  <option key={2022} value={2022}>2022</option>
                  <option key={2021} value={2021}>2021</option>
                  <option key={2020} value={2020}>2020</option>
                  <option key={2019} value={2019}>2019</option>
              </Select>
              <Select
                  value={month}
                  onChange={handleMonthChange}
                  className={classes.select}
                  native
                  variant="outlined" 
                  >
                  <option aria-label="None" value={0} style={{display: "none"}}>月</option>   
                  <option key={0} value={0}>不限</option>
                  <option key={1} value={1}>1月</option>
                  <option key={2} value={2}>2月</option>
                  <option key={3} value={3}>3月</option>
                  <option key={4} value={4}>4月</option>
                  <option key={5} value={5}>5月</option>
                  <option key={6} value={6}>6月</option>
                  <option key={7} value={7}>7月</option>
                  <option key={8} value={8}>8月</option>
                  <option key={9} value={9}>9月</option>
                  <option key={10} value={10}>10月</option>
                  <option key={11} value={11}>11月</option>
                  <option key={12} value={12}>12月</option>
              </Select>
              <Select
                  value={category}
                  onChange={handleCategoryChange}
                  className={classes.select}
                  native
                  variant="outlined" 
                  >
                  <option aria-label="None" key={0}  value={0}>全部分類</option>
                  {categoryRows.map((row:any) => {
                    return <option key={row.id} value={row.id}>{row.category_cn}</option>;
                    })
                  }

              </Select>
              <Select
                  value={tag}
                  onChange={handleTagChange}
                  className={classes.select}
                  native
                  variant="outlined" 
                  >
                  <option aria-label="None" key={0}  value={0}>全部標籤</option>
                  {tagRows.map((row:any) => {
                    return <option key={row.id}  value={row.id}>{row.tag_cn}</option>;
                    })
                  }
              </Select>
              {GetCookies("role")=="Admin"?
              <Select
                  value={author}
                  onChange={handleAuthorChange}
                  className={classes.select}
                  native
                  variant="outlined" 
                  >
                  <option aria-label="None" key={0}  value={0}>全部作者</option>
                  {authorRows.map((row:any) => {
                    return <option key={row.user_id}  value={row.user_id}>{row.nickname}</option>;
                    })
                  }
              </Select>:null}
              <Button variant="outlined" color="primary" onClick={ClickSearchButton} className={classes.selectButton}>
                  篩選
              </Button>
              <Input id="searchBar"  
               onChange={handleKeywordChange}
              style={{position:"absolute",right:50}}
               placeholder="Search"
               endAdornment={
                <InputAdornment position="end">
                  <IconButton type="submit"  aria-label="search" onClick={ClickSearchKeywordButton}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              } />
          </div>
          </div>
        
          <div className={classes.group}>
            <div className={classes.table}>
              <TableContainer >
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                    <TableCell key="actions" align="center" >
                        Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                  {rows.map((row:any) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell  style={{wordBreak:"break-all",maxWidth:140}} key={column.id} align={column.align}  >
                              {value}
                            </TableCell>
                          );
                        })}
                         <TableCell  key={"actions"} style={{wordBreak:"break-all",width:60}} align="center">
                             <a target='_blank' href={"/admin-panel/article_editor?&id="+row.id}><Button > 編輯</Button></a>
                             <a target='_blank' href={"https://www.cryptowesearch.com/blog/all/"+row.path}><Button > 檢視</Button></a> 
                             {row.status=="回收桶"?<Button onClick={() => ClickDeleteDataButton(row.id)}>删除</Button>:<Button onClick={() => ClickMoveDataToRecycleBinButton(row.id)}>移至回收桶</Button>}
                          </TableCell>
                      </TableRow>
                    );
                  })}

                </TableBody>
              </Table>
            </TableContainer>

          </div>
          
        </div>
        <div className={classes.paginNation}>
              <Pagination count={Math.floor(total/11+10)} shape="rounded" onChange={handleChangePage}/>
          </div>
      </div>

      <Snackbar open={changeSuccess} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
            Success!
        </Alert>
     </Snackbar>
    </div>


}