import _ from 'lodash'
import React from 'react'
// libraries
import Slider from '@material-ui/core/Slider'
// components
import { Button } from '..'
// helpers
import { Dictionary, CheckIsVersionMobile } from '../../helpers'
// styles
import { ArticleTagsReadingTimeStyles } from '../../styles'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import "react-multi-carousel/lib/styles.css"
// theme
import { Colors, Layouts } from '../../theme'

const muiTheme = createMuiTheme({
    overrides: {
        MuiSlider: {
            root: {
                marginTop: 4,
                width: '92.5%',
                marginLeft: 12,
            },
            thumb: {
                color: Colors.white,
                border: Layouts.borderLine(Colors.blue2, 2),
                height: 16,
                width: 16,
            },
            track: {
                color: Colors.blue2,
                height: 6,
                borderRadius: 99,
            },
            rail: {
                color: Colors.blue3,
                height: 6,
                borderRadius: 99,
            }
        }
    }
})

export const ArticleTagsReadingTimeView = (props) => {

    const {
        container,
        containerM,
        headerContainerM,
        headerText,
        headerSubTextM,
        leftInnerContainer,
        rightInnerContainer,
        labelsContainer,
        slideBarTimeText,
        slideBarTimesContainer,
        slideBarTimesContainerWrapperM,
        buttonsContainer,
        buttonsContainerM,
    } = ArticleTagsReadingTimeStyles()

    const {
        isVersionMobile,
        availableLabels,
        selectedLabelIndexs,
        selectedSliderValues,
        onChangeSliderValue,
        onClickLabel,
        onClickApply,
        onClickCancelOrClear,
    } = props

    const RenderLabels = () => (
        <div className={labelsContainer}>
            {availableLabels?.map((label, index) => {
                return (
                    <div key={`article-tag--${index}`}>
                        <Button
                            buttonContainerClass={selectedLabelIndexs.indexOf(label.tagId) !== -1 ? 'selectedArticleslabelContainer' : 'articleslabelContainer'}
                            buttonTextClass={selectedLabelIndexs.indexOf(label.tagId) !== -1 ? 'selectedArticleslabelText' : 'homePageNewslabelText'}
                            buttonText={label.tagCn}
                            onClick={() => onClickLabel(label.tagId)}
                        />
                    </div>
                )
            })}
        </div>
    )

    const RenderTimeSlider = () => (
        <div>
            <h6 className={headerText}>{t('screens.normalText.readingTime')}</h6>
            <div>
                <ThemeProvider theme={muiTheme}>
                    <Slider
                        value={selectedSliderValues}
                        defaultValue={[0, 30]}
                        step={5}
                        min={0}
                        max={30}
                        onChange={onChangeSliderValue}
                    />
                </ThemeProvider>
                <div className={slideBarTimesContainer}>
                    {Dictionary('slideBarTimes')?.map((minutes: number, index: number) => {
                        return (
                            <div
                                key={`slide-bar-time--${index}`}
                                className={slideBarTimeText}
                            >
                                {minutes === 99 ? '∞' : t('screens.normalText.minutes', { minutes })}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )

    const RenderButtons = () => (
        <div className={isVersionMobile ? buttonsContainerM : buttonsContainer}>
            <Button
                buttonContainerClass={`articlesFilterConfirmContainer${isVersionMobile ? 'M' : ''}`}
                buttonTextClass={'articlesFilterConfirmText'}
                buttonText={t('screens.normalText.apply')}
                onClick={onClickApply}
            />
            <Button
                buttonContainerClass={`articlesFilterCancelContainer${isVersionMobile ? 'M' : ''}`}
                buttonTextClass={'articlesFilterCancelText'}
                buttonText={t(`screens.normalText.${isVersionMobile ? 'cancel' : 'clear'}`)}
                onClick={() => onClickCancelOrClear(isVersionMobile ? 'cancel' : 'clear')}
            />
        </div>
    )

    const RenderComponentM = () => (
        <div className={containerM}>
            <div className={headerContainerM}>
                <h6 className={headerText}>{t('screens.normalText.articleClassification')}</h6>
                <div
                    className={headerSubTextM}
                    onClick={() => onClickCancelOrClear('clear')}
                >
                    {t('screens.normalText.clear')}
                </div>
            </div>
            {RenderLabels()}
            <div className={slideBarTimesContainerWrapperM}>
                {RenderTimeSlider()}
            </div>
            {RenderButtons()}
        </div>
    )

    const RenderComponent = () => (
        <div className={container}>
            <div className={leftInnerContainer}>
                <h6 className={headerText}>{t('screens.normalText.articleTag')}</h6>
                {RenderLabels()}
            </div>
            <div className={rightInnerContainer}>
                {RenderTimeSlider()}
                {RenderButtons()}
            </div>
        </div>
    )

    return (
        isVersionMobile
            ? RenderComponentM()
            : RenderComponent()
    )
}
