import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { RouteProps, Route, Redirect } from 'react-router-dom'
// helpers
import { GetUserToken } from '../helpers'

export const PrivateRoute = ({ component, ...rest }: RouteProps) => {

    const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(undefined)

    const Component: any = component

    useEffect(() => {
        checkAuthentication()
        window.addEventListener('storage', () => {
            checkAuthentication()
        })
    }, [])

    const checkAuthentication = () => {
        const token = GetUserToken()
        setIsAuthenticated(token !== null)
    }

    if (Component === null) {
        return null
    }

    let render: (props: any) => JSX.Element

    if (isAuthenticated) {
        render = (props: any) => (
            <Component {...props} />
        )
    } else if (isAuthenticated === false) {
        render = () => (
            <Redirect to={'/login'} />
        )
    } else {
        render = () => <div></div>
    }

    return <Route {...rest} render={render} />
}
