import React from 'react'
// styles
import { BeginnerGuideItemStyles } from '../../styles'
// types
import { IBeginnerGuideItemViewProps } from '../../interfaces'
// theme
import { Sprites } from '../../theme'
// components
import { Button } from '../../components'
// helpers
import { CheckIsVersionMobile } from '../../helpers'

export const BeginnerGuideItemView = (props: IBeginnerGuideItemViewProps) => {

    const {
        isInRange = false,
        id,
        title,
        subTitle,
        currentHover,
        onHandleSetHover,
    } = props

    const {
        container,
        containerM,
        containerHover,
        iconContainer,
        iconInnerContainer,
        iconInnerContainerM,
        iconInnerContainer794L,
        contentContainer,
        contentContainer794L,
        titleContainer,
        titleInnerContainer,
        titleInnerNumberText,
        titleText,
        titleTextM,
        titleText794L,
        subTitleContaienrM,
        subTitleText,
        subTitleTextM,
    } = BeginnerGuideItemStyles()
    const isVersionMobile = CheckIsVersionMobile()

    return (
        <div
            className={isVersionMobile ? containerM : currentHover === id ? containerHover : container}
            style={isVersionMobile ? { height: 100 } : {}}
            onMouseEnter={() => onHandleSetHover(id)}
            onMouseLeave={() => onHandleSetHover(-1)}
        >
            <div className={iconContainer}>
                <div className={isVersionMobile ? iconInnerContainerM : isInRange ? iconInnerContainer794L : iconInnerContainer}>
                    <Sprites
                        icon={`IconBeginnerGuide${id}`}
                        size={isInRange ? 'iconBeginnerGuideBrief794L' : 'iconBeginnerGuideBrief'}
                        alt={'icon-beginner-guide-brief'}
                    />
                </div>
            </div>
            <div className={isInRange ? contentContainer794L : contentContainer}>
                <div
                    onClick={() => console.log('isVersionMobile')}
                    style={isVersionMobile
                        ? {}
                        : {
                            transition: `transform 200ms`,
                            transform: currentHover === id ? 'none' : 'translateY(20px)',
                        }}>
                    <div className={titleContainer}>
                        <div className={titleInnerContainer}>
                            <div className={titleInnerNumberText}>{id}</div>
                        </div>
                        {isVersionMobile
                            ? <h6 className={titleTextM}>{title}</h6>
                            : <h5 className={isInRange ? titleText794L : titleText}>{title}</h5>
                        }
                    </div>
                    <div className={isVersionMobile ? subTitleContaienrM : ''}>
                        <div className={isVersionMobile ? subTitleTextM : subTitleText}>{subTitle}</div>
                        {isVersionMobile &&
                            <Sprites
                                icon={'IconNextGrey'}
                                size={'iconNextGrey'}
                                alt={'next'}
                            />
                        }
                    </div>
                </div>
                {isVersionMobile
                    ? null
                    : <div
                        style={{
                            transition: `opacity 300ms, transform 300ms`,
                            transform: currentHover === id ? 'none' : 'translateY(20px)',
                            opacity: currentHover === id ? 1 : 0,
                        }}
                    >
                        <Button
                            buttonContainerClass={'homePageKnowMoreBasicContainer'}
                            buttonTextClass={'homePageKnowMoreBasicText'}
                            buttonText={t('screens.normalText.iWantToKnowMoreBasicKnowledge')}
                            onClick={() => null}
                        />
                    </div>
                }
            </div>
        </div>
    )
}
