import { useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { UseDataFetching,GetHomepageVideosAPI,UploadDataForHomePageVideoAPI } from '../../apis'
import {Table,Select,TextField,Grid,Snackbar,InputLabel,Button,FormLabel,TableBody,TableCell,TableContainer,TableHead,TableRow,Paper} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom'
import {APIDomainHTTP} from '../../helpers/api-domain-http'
import { GetUserToken } from '../../helpers';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const homepageVideoStyle = makeStyles((theme) => ({
    body:{
        paddingTop:10,
        marginTop:30,
        paddingLeft:70,
        paddingRight:100,
    },
    content:{
        padding:theme.spacing(2),
        paddingTop:40,
        paddingLeft:40,
        border:1,
        borderRadius:4,
        backgroundColor:"white",
        borderStyle:"solid",
        borderColor:"#bdbdbd",
        width:"100%",
        height:90,
    },
    select:{
        marginTop:2,
        backgroundColor:"white",
    },
    subTitle:{
        marginTop:5,
    },
    title:{
        marginTop:45,
    },
    inputFied:{
        marginTop:1,
    },
    textfield:{
        border:1,
        marginTop:9,
        borderRadius:4,
        backgroundColor:"white",
    },

})); 


export const AdminHomepageVideo= (props:any) => {
    const classes = homepageVideoStyle();
    const [rows ,setRows ] = useState([]);
    const [channel1 ,setChannel1 ] = useState("");
    const [title1 ,setTitle1 ] = useState("");
    const [createdTime1 ,setCreatedTime1 ] = useState("");
    const [channel2 ,setChannel2 ] = useState("");
    const [title2 ,setTitle2 ] = useState("");
    const [createdTime2 ,setCreatedTime2 ] = useState("");
    const [channel3 ,setChannel3 ] = useState("");
    const [title3 ,setTitle3 ] = useState("");
    const [createdTime3 ,setCreatedTime3 ] = useState("");
    const [channel4 ,setChannel4 ] = useState("");
    const [title4 ,setTitle4 ] = useState("");
    const [createdTime4 ,setCreatedTime4 ] = useState("");
    const [channel5 ,setChannel5 ] = useState("");
    const [title5 ,setTitle5 ] = useState("");
    const [createdTime5 ,setCreatedTime5 ] = useState("");
    const [channel6 ,setChannel6 ] = useState("");
    const [title6 ,setTitle6 ] = useState("");
    const [createdTime6 ,setCreatedTime6 ] = useState("");
    const [channel7 ,setChannel7 ] = useState("");
    const [title7 ,setTitle7 ] = useState("");
    const [createdTime7 ,setCreatedTime7 ] = useState("");
    const [channel8 ,setChannel8 ] = useState("");
    const [title8 ,setTitle8 ] = useState("");
    const [createdTime8 ,setCreatedTime8 ] = useState("");
    const [channel9 ,setChannel9 ] = useState("");
    const [title9 ,setTitle9 ] = useState("");
    const [createdTime9 ,setCreatedTime9 ] = useState("");
    const [postSuccess,setPostSuccess] = useState(false);
    const [open, setOpen] = useState(false);
    const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
        setPostSuccess(false);
    };
   
    const setTitle = (n:any,title:any)=>{
        switch (n){
            case 1:
                setTitle1(title);
                break
            case 2:
                setTitle2(title);
                break    
            case 3:
                setTitle3(title);
                break 
            case 4:
                setTitle4(title);
                break 
            case 5:
                setTitle5(title);
                break 
            case 6:
                setTitle6(title);
                break 
            case 7:
                setTitle7(title);
                break 
            case 8:
                setTitle8(title);
                break 
            case 9:
                setTitle9(title);
                break 
        }
    }
    const getTitle = (n:any)=>{
        switch (n){
            case 1:
                return title1
            case 2:
                return title2
            case 3:
                return title3
            case 4:
                return title4
            case 5:
                return title5 
            case 6:
                return title6 
            case 7:
                return title7
            case 8:
                return title8
            case 9:
                return title9
        }
    }
    const getChannel = (n:any)=>{
        switch (n){
            case 1:
                return channel1
            case 2:
                return channel2
            case 3:
                return channel3
            case 4:
                return channel4
            case 5:
                return channel5 
            case 6:
                return channel6 
            case 7:
                return channel7
            case 8:
                return channel8
            case 9:
                return channel9
        }
    }
    const getCreatedTime = (n:any)=>{
        switch (n){
            case 1:
                return createdTime1
            case 2:
                return createdTime2
            case 3:
                return createdTime3
            case 4:
                return createdTime4
            case 5:
                return createdTime5 
            case 6:
                return createdTime6 
            case 7:
                return createdTime7
            case 8:
                return createdTime8
            case 9:
                return createdTime9
        }
    }
    const setCreateTime = (n:any,createdTime:any)=>{
        switch (n){
            case 1:
                setCreatedTime1(createdTime);
                break
            case 2:
                setCreatedTime2(createdTime);
                break    
            case 3:
                setCreatedTime3(createdTime);
                break 
            case 4:
                setCreatedTime4(createdTime);
                break 
            case 5:
                setCreatedTime5(createdTime);
                break 
            case 6:
                setCreatedTime6(createdTime);
                break 
            case 7:
                setCreatedTime7(createdTime);
                break 
            case 8:
                setCreatedTime8(createdTime);
                break 
            case 9:
                setCreatedTime9(createdTime);
                break 
        }
    }
    const setChannel = (n:any,channel:any)=>{
        switch (n){
            case 1:
                setChannel1(channel);
                break
            case 2:
                setChannel2(channel);
                break    
            case 3:
                setChannel3(channel);
                break 
            case 4:
                setChannel4(channel);
                break 
            case 5:
                setChannel5(channel);
                break 
            case 6:
                setChannel6(channel);
                break 
            case 7:
                setChannel7(channel);
                break 
            case 8:
                setChannel8(channel);
                break 
            case 9:
                setChannel9(channel);
                break 
        }
    }
    const CreateOrUpdateVideoList=  async()=> {
        let videoList:any[]=[];
        for (let i = 1; i < 10; i++) {
            videoList.push({
                video_order:i,
                title:getTitle(i)||"",
                channelId:getChannel(i)||"",
                createdTime:getCreatedTime(i)||""
            })
        }
        const { results } = await UseDataFetching(UploadDataForHomePageVideoAPI({
            videoList:videoList
        })) as any
        if (results?.code===200){
            setPostSuccess(true);
        }
    }
    const GetVideosList=  async()=> {
        var list:any =[];
        const  {results} =  await UseDataFetching(GetHomepageVideosAPI({})) as any;
        (results['video_list']||[]).map(function(result:any){
           setChannel(result.video_order,result.channel_id);
           setTitle(result.video_order,result.title);
           setCreateTime(result.video_order,result.created_time);
           list.push({'id':result.video_order,'title':result.title,'channel_id':result.channel_id,'created_time':result.created_time });
        })
        setRows(list);
    }


    useEffect(() =>{
        GetVideosList();
    }, []);
    return <div  className={classes.body}>
        <div>
            <FormLabel component="legend">Video list</FormLabel>
        </div>
        <Grid container spacing={1}>
        {rows.map((row:any) => (
            <>
            <Grid item xs={4}>
                <div className={classes.textfield}>
                    <TextField id="schema"
                        label="title"
                        value={getTitle(row.id)}
                        onChange={event => setTitle(row.id,event.target.value)}
                        fullWidth
                        variant="outlined" />
                    </div>
            </Grid >
            <Grid item xs={4}>
                <div className={classes.textfield}>
                    <TextField id="schema"
                        label="channel id"
                        value={getChannel(row.id)}
                        onChange={event => setChannel(row.id,event.target.value)}
                        fullWidth
                        variant="outlined" />
                    </div>
            </Grid >
            <Grid item xs={4}>
                <div className={classes.textfield}>
                    <TextField id="schema"
                        label="sub text"
                        value={getCreatedTime(row.id)}
                        onChange={event => setCreateTime(row.id,event.target.value)}
                        fullWidth
                        variant="outlined" />
                </div>
            </Grid >
            </>
        ))}
        <div  style={{marginTop:25,marginBottom:30}}>
            <Button onClick={CreateOrUpdateVideoList} color="primary" variant="outlined">Update</Button>
        </div>
        </Grid >
        <div>
            <Snackbar open={postSuccess} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    Success!
                </Alert>
            </Snackbar>
            
        </div>
    </div>
}