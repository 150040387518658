import _ from 'lodash'
import axios from 'axios'
// helpers
import { CancelRequest, APICatchError, ObjectSnakeCaseKeys, APIHeaders, APIDomainHTTP, APIVersion } from '../helpers'
import { RemoveUserToken} from '../helpers/local-storage'

export const UploadBlogImageAPI = async (
    body: {
        image: any,
    }) => {

    const { post, CancelToken } = axios
    const url = `${APIDomainHTTP()}/${APIVersion()}/upload/blog_img`
    const headers = APIHeaders()
    const source = CancelToken.source()
    CancelRequest.add(
        'uploadDataForCreateCategoryAPI',
        () => source.cancel('uploadDataForCreateCategoryAPI: Operation cancelled by user'),
    )

    try {

        var formData = new FormData()
        formData.append('img', body.image, "upload.jpg");

        const res: {
            status: number;
            message: string;
        } = await post(url, formData, {
            headers,
            cancelToken: source.token,
        })
        if (res.status !== 200) {
            return APICatchError(res.message)
        }
        
        return res
    } catch (e) {
        if (e.message == 'Request failed with status code 403'){
            RemoveUserToken()
        }
        return APICatchError(e.message)
    }
} 
