import React from 'react'
// libraries
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress'
// styles
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
// theme
import { Colors } from '../../theme'

const useStylesFacebook = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
        },
        bottom: {
            color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        },
        top: {
            color: Colors.blue2,
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
        },
        circle: {
            strokeLinecap: 'round',
        },
    }),
)

const FacebookCircularProgress = (props: CircularProgressProps) => {
    const classes = useStylesFacebook()

    return (
        <div className={classes.root}>
            <CircularProgress
                variant="determinate"
                className={classes.bottom}
                size={80}
                thickness={4}
                {...props}
                value={100}
            />
            <CircularProgress
                variant="indeterminate"
                disableShrink
                className={classes.top}
                classes={{
                    circle: classes.circle,
                }}
                size={80}
                thickness={4}
                {...props}
            />
        </div>
    )
}

const useStyles = makeStyles({
    root: {
        // flexGrow: 1,
    },
})


export const LoadingIndicator = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <FacebookCircularProgress />
        </div>
    )
}
