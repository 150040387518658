import React, { useMemo } from 'react'
// styles
import { DiscountBannerStyles } from '../../styles'
// theme
import { Sprites } from '../../theme'
// component
import { Button } from '../../components'
// helpers
import { ImageDomain, CheckIsVersionMobile, SecondsToClockTime } from '../../helpers'

export const DiscountBannerView = (props) => {

    const {
        timeRemaining,
        bannerDetail,
        onClickApply,
    } = props

    const {
        container,
        containerM,
        innerContainer,
        iconContainer,
        iconContainerM,
        iconDiscountDetailContainer,
        iconDiscountDetailContainerM,
        foreverDiscountPlainText,
        foreverDiscountPlainTextM,
        foreverDiscountTextContainer,
        foreverDiscountTextContainerM,
        foreverDiscountTextInnerContainer,
        foreverDiscountText1,
        foreverDiscountText1M,
        foreverDiscountText2,
        foreverDiscountText2M,
        applyPlainText,
        timeRemainingContainer,
        timeRemainingContainerM,
        timeRamainingText,
        timeRamainingPlainText,
        timeRemainingValueText,
    } = DiscountBannerStyles()

    const isVersionMobile = CheckIsVersionMobile()

    const { hours = '99', minutes = '99', seconds = '99' } = SecondsToClockTime(timeRemaining)

    const RenderLeftComponent = useMemo(() => {
        return (
            <div className={iconDiscountDetailContainer}>
                <div className={iconContainer}>
                    <Sprites
                        isLink
                        icon={`${ImageDomain('banner')}/${bannerDetail?.uuid}`}
                        size={'iconBannerCompany'}
                        alt={bannerDetail?.imageAlt}
                    />
                </div>
                <div className={foreverDiscountTextContainer}>
                    <span className={foreverDiscountPlainTextM}>{t('screens.normalText.specialOfferDescription1')}</span>
                    <div className={foreverDiscountTextInnerContainer}>
                        <div className={foreverDiscountText1}>{`${bannerDetail?.discount}%`}</div>
                        <div className={foreverDiscountText2}>{t('screens.normalText.off')}</div>
                    </div>
                </div>
            </div>
        )
    }, [bannerDetail])

    const RenderTopComponentM = useMemo(() => {
        return (
            <div className={iconDiscountDetailContainerM}>
                <div className={iconContainerM}>
                </div>
                <div className={foreverDiscountTextContainerM}>
                    <div className={foreverDiscountTextInnerContainer}>
                        <div className={foreverDiscountText1M}>{`${bannerDetail?.discount}%`}</div>
                        <div className={foreverDiscountText2M}>{t('screens.normalText.off')}</div>
                    </div>
                    <span className={foreverDiscountPlainText}>{t('screens.normalText.specialOfferDescription2')}</span>
                </div>
            </div>
        )
    }, [bannerDetail])

    const RenderButton = useMemo(() => {
        return (
            <Button
                buttonContainerClass={`bannerApplyDiscountContainer${isVersionMobile ? 'M' : ''}`}
                renderComponent={() => {
                    return <h5 className={applyPlainText}>{t('screens.normalText.applyImmediately')}</h5>
                }}
                onClick={() => onClickApply(bannerDetail?.invitationLink)}
            />
        )
    }, [bannerDetail])

    const RenderTimeRemainingText = () => (
        <div className={isVersionMobile ? timeRemainingContainerM : timeRemainingContainer}>
            <div className={timeRamainingPlainText}>{t('screens.normalText.timeRemain')}</div>
            <div className={timeRemainingValueText}>{hours}</div>
            <div className={timeRamainingText}>{t('screens.normalText.hour')}</div>
            <div className={timeRemainingValueText}>{minutes}</div>
            <div className={timeRamainingText}>{t('screens.normalText.minute')}</div>
            <div className={timeRemainingValueText}>{seconds}</div>
            <div className={timeRamainingText}>{t('screens.normalText.second')} </div>
        </div>
    )

    const RenderComponent = () => (
        <div className={container}>
            <div className={innerContainer}>
                {RenderLeftComponent}
                <div>
                    {RenderTimeRemainingText()}
                    {RenderButton}
                </div>
            </div>
        </div>
    )

    const RenderComponentM = () => (
        <div className={containerM}>
            {RenderTopComponentM}
            {RenderButton}
            {RenderTimeRemainingText()}
        </div>
    )

    return (
        isVersionMobile
            ? RenderComponentM()
            : RenderComponent()
    )
}
