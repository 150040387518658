import { makeStyles } from '@material-ui/core/styles'
// theme
import { Fonts, Colors, Layouts } from '../theme'

const containerWidth = 360
const containerWidth2 = 264
const containerWidthM = 232
const newsContainerWidthM = 327

const aboutUsImageContainer = {
    ...Layouts.rowWithAllCenter,
    marginTop: 16,
    border: Layouts.borderLine(Colors.grey1),
    boxSizing: 'border-box',
    borderRadius: 8,
}

export const ContentBriefItemStyles = makeStyles({
    blogArticlesContainerM: {
        width: containerWidthM,
        borderRadius: 8,
        marginBottom: 32,
        marginLeft: 24,
    },
    blogArticlesImageContainerM: {
        ...Layouts.rowWithAllCenter,
        marginTop: 12,
        width: containerWidthM,
        height: 120,
        borderRadius: 8,
        backgroundColor: Colors.greyBlack1
    },
    blogArticlesTextM: {
        ...Fonts.fontMedium400,
        margin: `4px 0`,
        color: Colors.greyBlack85,
    },
    latestOffersContainer: {
        height: 274,
        width: containerWidth,
        borderRadius: 8,
        backgroundColor: Colors.grey0,
    },
    latestOffersImageContainer: {
        ...Layouts.rowWithAllCenter,
        marginTop: 24,
        height: 202,
        width: containerWidth, 
        border: '1px solid #F4F4F4',
        boxSizing: 'border-box',
        borderRadius: 8,
    },
    latestOffersTextContainer: {
        ...Layouts.rowWithAllCenter,
        padding: `0 12px`,
        height: 72,
    },
    latestOffersText: {
        ...Fonts.fontMediumBig700,
        letterSpacing: 0.3
    },
    latestNewsContainer: {
        height: 314,
        width: containerWidth,
        borderRadius: 8,
    },
    latestNewsContainerM: {
        width: newsContainerWidthM,
        borderRadius: 8,
        marginBottom: 32,
    },
    latestNewsImageContainer: {
        ...Layouts.rowWithAllCenter,
        marginTop: 24,
        height: 202,
        width: containerWidth, 
    },
    latestNewsImageContainerM: {
        ...Layouts.rowWithAllCenter,
        marginTop: 12,
        width: newsContainerWidthM,
        height: 184,
        borderRadius: 8,
        backgroundColor: Colors.greyBlack1
    },
    latestNewsTextContainer: {
        height: 112, 
    },
    latestNewsText: {
        ...Fonts.fontMediumBig400,
        margin: `8px 0`,
        letterSpacing: 0.3,
        color: Colors.greyBlack85,
    },
    latestNewsTextM: {
        ...Fonts.fontMediumBig700,
        margin: `8px 0`,
        letterSpacing: 0.3,
        color: Colors.greyBlack85,
    },
    latestNewsSubText: {
        ...Fonts.fontSmall400,
        letterSpacing: 1,
        width: containerWidth,
        color: Colors.greyBlack6,
        textAlign: 'start',
    },
    labelContainerWrapper: {
        ...Layouts.rowWithJCStart,
        marginTop: 8,
    },
    aboutUsContainer: {
        height: 246,
        width: containerWidth2,
        borderRadius: 8,
    },
    aboutUsContainerM: {
        height: 180,
        width: 160,
        marginRight: 6,
    },
    aboutUsImageContainer: {
        ...aboutUsImageContainer as {},
        height: 132,
        width: containerWidth2, 
    },
    aboutUsImageContainerM: {
        ...aboutUsImageContainer as {},
        height: 76,
        width: 156,
    },
    aboutUsTextContainer: {
        height: 112 - 16,
    },
    aboutUsText: {
        ...Fonts.fontMediumBig700,
        letterSpacing: 0.3,
        marginTop: 16,
        marginBottom: 8,
        textAlign: 'center',
    },
    aboutUsSubText: {
        ...Fonts.fontMedium400,
        letterSpacing: 0.3,
        textAlign: 'center',
    },
    latestOffersImageContainerM: {
        width: containerWidthM,
        height: 120,
        border: Layouts.borderLine(Colors.grey1),
        borderRadius: 8,
    },
    itemContainer: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    latestOffersContainerM: {
        width: containerWidthM,
        height: 178,
        marginLeft: 24,
        marginBottom: 48,
    },
    marginRightM: {
        marginRight: 24,
    },
    aboutUsTextM: {
        ...Fonts.fontMedium700,
        margin: `0 4px`,
        marginTop: 12,
    },
    newslabelContainer: {
        ...Layouts.rowWithAllCenter,
        marginRight: 8,
        padding: `4px 8px`,
        minWidth: 60,
        height: 24,
        borderRadius: 20,
        backgroundColor: Colors.blue5,
    },
    newslabelText: {
        ...Fonts.fontSmall400,
        color: Colors.blue2,
    },
})
