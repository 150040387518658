import _ from 'lodash'
import React, { useState, useEffect } from 'react'
// styles
import { FollowOurSocietyStyles } from '../../styles'
// helpers
import { Dictionary, CheckIsVersionMobile } from '../../helpers'
// theme
import { Sprites } from '../../theme'
// components
import { Button } from '../../components'

export const SocialOtherShares = () => {

    const {
        socialOtherShareContainerWrapper,
        socialOtherShareContainerWrapperM,
        socialOtherShareContainer,
        socialOtherShareContainerM,
    } = FollowOurSocietyStyles()
    const isVersionMobile = CheckIsVersionMobile()

    const [items, setItems] = useState<any[]>([])

    useEffect(() => {
        setItems(!isVersionMobile ? [1, 2, 3] : [1, 2])
    }, [isVersionMobile])

    return (
        <div className={isVersionMobile ? socialOtherShareContainerWrapperM : socialOtherShareContainerWrapper}>
            {items?.map((item, index) => {
                return (
                    <div
                        key={`social-other-share--${index}`}
                        className={isVersionMobile ? socialOtherShareContainerM : socialOtherShareContainer}
                    >

                    </div>
                )
            })}
        </div>
    )
}
