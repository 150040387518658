export const UseDataFetching = async (api: any) => {

    let results: any = []
    let error = null
    let isLoading = true

    try {
        const res = await api
        if (res) {
            results = res.data
        }
    } catch (err) {
        error = err.message
    } finally {
        isLoading = false
    }
    return {
        isLoading,
        results,
        error,
    }
}
