import React from 'react'
// theme
import { Sprites } from '../../theme'
// styles
import { NotFoundStyles } from '../../styles'
// components
import { Button } from '../../components'

export const NotFound = (props) => {

    const {
        isFromArticleBrief = false,
        is404 = false,
    } = props

    const {
        container,
        container404,
        noResultText,
        resultNotFoundText,
    } = NotFoundStyles()

    return (
        !is404
            ? <div className={container}>
                <Sprites
                    icon={'IconNoResult'}
                    alt={'icon-no-result'}
                />
                <h2 className={resultNotFoundText}>{t('screens.normalText.resultNotFound')}</h2>
                {!isFromArticleBrief && <div className={noResultText}>{t('screens.normalText.pleaseSearchWithOtherTerms')}</div>}
                <Button
                    buttonContainerClass={'askChingContainer'}
                    buttonTextClass={'askChingText'}
                    buttonText={t('screens.normalText.askChingThroughTg')}
                    onClick={() => null}
                />
            </div>
            : <div className={container404}>
                <Sprites
                    icon={'Icon404'}
                    alt={'icon-404'}
                />
                <h2 className={resultNotFoundText}>{t('screens.normalText.404NotFound1')}</h2>
                <div className={noResultText}>{t('screens.normalText.404NotFound2')}</div>
            </div>
    )
}