import _ from 'lodash'
import axios from 'axios'
// helpers
import { CancelRequest, APICatchError, ObjectSnakeCaseKeys, APIHeaders, APIDomainHTTP, APIVersion } from '../helpers'
import { RemoveUserToken} from '../helpers/local-storage'


export const UploadDataForCreatePlatformAPI = async (body: {
    displayName:string,
    description:string,
    productName:string,
    platformId:number,
    website:string,
    coinNumber :number,
    postId:number,
    invitationCode:string,
    invitationLink:string
    imageAlt:string,
    comparable:boolean,
    cryptoCurrency:string,
    legalCurrency:string,
    transactionFee :number,
    depositFee:number,
    withdrawalFee:number,
    withdrawalDepositMethod:string,
    coinMarketCapScore:number,
    safetyIndex:number,
    popularityIndex:number,
    scaleIndex:number,
    activityContent:string,
    discount:number,
    features:string[],
}) => {

    const { post, CancelToken } = axios
    const url = `${APIDomainHTTP()}/${APIVersion()}/platform/add`
    const headers = APIHeaders()

    const source = CancelToken.source()

    CancelRequest.add(
        'UploadDataForCreatePlatformAPI',
        () => source.cancel('UploadDataForCreatePlatformAPI: Operation cancelled by user'),
    )

    try {
        const res: {
            status: number;
            message: string;
        } = await post(url, ObjectSnakeCaseKeys(body), {
            headers,
            cancelToken: source.token,
        })
        if (res.status !== 200) {
            return APICatchError(res.message)
        }
        
        return res
    } catch (e) {
        if (e.message == 'Request failed with status code 403'){
            RemoveUserToken()
        }
        return APICatchError(e.message)
    }
} 
