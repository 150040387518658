import _ from 'lodash'
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
// view screens
import { ArticleTagsReadingTimeView } from './article-tags-reading-time-view'
// libraries
import { useQuery } from 'react-query'
// apis
import { GetTagsAPI } from '../../apis'
// types
import { APITag } from '../../interfaces'
// helpers
import { CheckIsVersionMobile, ErrorAlert } from '../../helpers'

export const ArticleTagsReadingTimeController = forwardRef((props: any, ref) => {

    useImperativeHandle(
        ref,
        () => ({
            setValuesBack(labelIds: number[], sliderValues: number[]) {
                setSelectedLabelIndexs(labelIds)
                setSelectedSliderValues(sliderValues)
            }
        }),
    )

    const {
        tagIdsM = [],
        readingTimesM = [0, 30],
        onSetNumberOfLabels = () => null,
        onHandleDrawerVisibiity = () => null,
        onClickApply: onClickApplyProps = () => null,
    } = props

    const isVersionMobile = CheckIsVersionMobile()

    const [availableLabels, setAvailableLabels] = useState<APITag[]>([])
    const [selectedLabelIndexs, setSelectedLabelIndexs] = useState<number[]>([])
    const [selectedSliderValues, setSelectedSliderValues] = useState<number[]>([0, 30])

    const { error, data } = useQuery<APITag[], { message: string }>('tags', GetTagsAPI)

    useEffect(() => {
        if (error !== null) {
            return ErrorAlert({ text1: error.message })
        }

        if (data) {
            setAvailableLabels(data)
        }

    }, [error, data])

    useEffect(() => {
        if (!isVersionMobile || (_.isEmpty(tagIdsM) && readingTimesM === [0, 30])) return
        setSelectedLabelIndexs(tagIdsM)
        setSelectedSliderValues(readingTimesM)
    }, [isVersionMobile])

    useEffect(() => {
        onSetNumberOfLabels(selectedLabelIndexs.length)
    }, [selectedLabelIndexs])

    const onChangeSliderValue = (_, values: number[]) => {
        setSelectedSliderValues(values)
    }

    const onClickLabel = (index: number) => {
        if (selectedLabelIndexs.indexOf(index) !== -1) {
            setSelectedLabelIndexs(prevState => prevState.filter(i => i !== index))
        } else {
            setSelectedLabelIndexs(prevState => [...prevState, index])
        }
    }

    const onClickApply = () => {
        onClickApplyProps(selectedLabelIndexs, selectedSliderValues)
        if (isVersionMobile) {
            onClickCancelOrClear('cancel')
        }
    }

    const onClickCancelOrClear = (type: string) => {
        if (type !== 'clear') {
            onHandleDrawerVisibiity(false, 'bottom')
        } else if (!_.isEqual(selectedSliderValues, [0, 30]) || !_.isEqual(selectedLabelIndexs, [])) {
            setSelectedSliderValues([0, 30])
            setSelectedLabelIndexs([])
        }
    }

    const ViewProps = {
        isVersionMobile,
        availableLabels,
        selectedSliderValues,
        selectedLabelIndexs,
        onChangeSliderValue,
        onClickLabel,
        onClickApply,
        onClickCancelOrClear,
    }

    return (
        <ArticleTagsReadingTimeView {...ViewProps} />
    )
})
