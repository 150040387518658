import React from 'react'
import { Dictionary } from '../../helpers'
// styles
import { CoinAnalysisStyles } from '../../styles'
// components
import { Button } from '../../components'
import { Sprites } from '../../theme'

export const CoinAnalysisView = (props) => {

    const {
        onClickMoreFeatures,
        onClickButton,
    } = props

    const {
        container,
        iconContainer,
        coinTypeBriefContainer,
        coinNameContainer,
        coinNameText,
        coinMarksContainer,
        coinMarksText,
        coinAnalysisTypesContainer,
        coinAnalysisTypeContainer,
        coinAnalysisTypeTitleText,
        coinAnalysisTypeValueText,
        AdditionalCoinAnalysisTypeContainer,
        coinAnalysisTypeValueH1Text,
        moreFeaturesContainer,
        moreFeaturesPlainText,
        discountContainer,
        discountBriefText,
        discountDetailContainer,
        discountDetailText1,
        discountDetailText2,
        buttonsContainer,
    } = CoinAnalysisStyles()

    return (
        <div className={container}>
            <div className={iconContainer}>

            </div>
            <div className={coinTypeBriefContainer}>
                <div className={coinNameContainer}>
                    <h3 className={coinNameText}>Binance 幣安</h3>
                    <div className={coinMarksContainer}>
                        <div className={coinMarksText}>9.9</div>
                    </div>
                </div>
                <div className={coinAnalysisTypesContainer}>
                    {Dictionary('coinAnalysisTypes')?.map((type: string, index: number) => {
                        const dummyText = type === 'debitCreditChannel'
                            ? '信用卡、加密貨幣、借記卡、FPS、ATM'
                            : type === 'coinTypeQuantity'
                                ? '369'
                                : '0.06%'
                        return (
                            <div className={index === 0 ? coinAnalysisTypeContainer : AdditionalCoinAnalysisTypeContainer}>
                                <div className={coinAnalysisTypeTitleText}>{t(`screens.normalText.${type}`)}</div>
                                {type === 'debitCreditChannel'
                                    ? <div className={coinAnalysisTypeValueText}>{dummyText}</div>
                                    : <h1 className={coinAnalysisTypeValueH1Text}>{dummyText}</h1>
                                }
                            </div>
                        )
                    })}
                </div>
                <Button
                    buttonContainerClass={'coinAnalysisMoreFeaturesContainer'}
                    renderComponent={() => {
                        return (
                            <div className={moreFeaturesContainer}>
                                <div className={moreFeaturesPlainText}>{t('screens.normalText.moreFeatures')}</div>
                                <Sprites
                                    icon={'IconDropDownDarkBlue'}
                                    size={'iconDropDownDarkBlue'}
                                    alt={'icon-drop-down-dark-blue'}
                                />
                            </div>
                        )
                    }}
                    onClick={onClickMoreFeatures}
                />
            </div>
            <div className={discountContainer}>
                <div className={discountBriefText}>全球交易量最大。支援港元進行即時零費用存取款，多種加密貨幣和24小時本地客服。</div>
                <div className={discountDetailContainer}>
                    <Sprites
                        icon={'IconDiscount'}
                        // size={'iconDiscount'}
                        alt={'icon-discount'}
                    />
                    <div className={discountDetailText1}>
                        活動內容：輸入
                        <span className={discountDetailText2}>邀請碼“O0R2L”</span>
                        ，首次充值滿 HK$10,000  / US$1,300，即送你 25 USDT （價值約HK$200）及 10 Dogecoin。 每邀請一位朋友成功入金即可獲得 15 USDT。
                    </div>
                </div>
                <div className={buttonsContainer}>
                    <Button
                        buttonContainerClass={'coinAnalysisApplyContainer'}
                        buttonTextClass={'coinAnalysisApplyText'}
                        buttonText={t('screens.normalText.applyImmediately')}
                        onClick={() => onClickButton('apply')}
                    />
                    <Button
                        buttonContainerClass={'coinAnalysisAnalyzeContainer'}
                        buttonTextClass={'coinAnalysisAnalyzeText'}
                        buttonText={t('screens.normalText.analyzeDetaily')}
                        onClick={() => onClickButton('analyze')}
                    />
                </div>
            </div>
        </div>
    )
}
