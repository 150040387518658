import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import moment from 'moment'
// view screen
import { DiscountBannerView } from './discount-banner-view'
// helpers
import { SetDiscountTimeDue, GetDiscountTimeDue } from '../../helpers'

export const DiscountBannerController = (props) => {

    const [time, setTime] = useState<string>('')
    const [currentTime, setCurrentTime] = useState<number>(0)
    const [targetTime, setTargetTime] = useState<string>('')
    const [discountTimeDue, setDiscountTimeDue] = useState<string>('')
    const timeRemaining = moment(time).diff(new Date(), 'seconds')

    useEffect(() => {
        const timeDue = GetDiscountTimeDue()
        const newTimeDue = !_.isEmpty(timeDue) ? moment(timeDue).add(1, 'day').format('YYYY-MM-DD HH:mm:ss') : moment().add(1, 'day').format('YYYY-MM-DD 04:00:00')
        const isToGoNext = moment().diff(timeDue) > 0 || _.isEmpty(timeDue)
        if (isToGoNext) {
            SetDiscountTimeDue(newTimeDue)
            setDiscountTimeDue(newTimeDue)
        } else {
            setDiscountTimeDue(timeDue)
        }

        setTime(isToGoNext ? newTimeDue : timeDue)
        let seconds = 0
        const startTime = moment().format('YYYY-MM-DD HH:mm:ss')
        setInterval(() => {
            const startTimeInSeconds = moment(startTime).startOf('seconds')
            seconds = moment().diff(startTimeInSeconds, 'seconds')
            setCurrentTime(currentTime + seconds)
        }, 1000)
    }, [])

    const onClickApply = (link: string) => {
        window.location.replace(`https://${link}`)
    }

    const ViewProps = {
        ...props,
        timeRemaining,
        onClickApply,
    }

    return (
        <DiscountBannerView {...ViewProps} />
    )
}
