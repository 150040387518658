import _ from 'lodash'
import axios from 'axios'
// helpers
import { GetCurrentLocale, CancelRequest, APICatchError, ObjectSnakeCaseKeys, APIHeaders, APIDomainHTTP, APIVersion, ObjectCamelCaseKeys } from '../helpers'
// types
import { APIParamsArticle, APIArticle } from '../interfaces'

export const GetArticlesAPI = async (params: APIParamsArticle) => {

    const hasKeywords = !_.isEmpty(params.keywords)

    const { get, CancelToken } = axios
    const url = `${APIDomainHTTP()}/${APIVersion()}/articles_brief/${hasKeywords ? 'search' : 'content'}`
    const headers = APIHeaders()

    const source = CancelToken.source()

    CancelRequest.add(
        'GetArticles',
        () => source.cancel('GetArticles: Operation cancelled by user'),
    )

    try {
        const resolvedParams = hasKeywords
        ? { keywords: params.keywords }
        : ObjectSnakeCaseKeys({
            categoryIds: JSON.stringify(params.categoryIds),
            readingTime: JSON.stringify(params.readingTime),
            tags: JSON.stringify(params.tags),
            page: params.page ? params.page : 1,
        })

        const res: {
            status: number;
            message: string;
            data: {
                results: APIArticle[],
            };
        } = await get(url, {
            params: resolvedParams,
            headers,
            cancelToken: source.token,
        })

        if (res.status !== 200) {
            throw new Error(APICatchError(res.message))
        }

        const { data } = ObjectCamelCaseKeys(res)

        const { results } = data
        return results
    } catch (e) {
        throw new Error(APICatchError(e.message))
    }
} 
