import _ from 'lodash'
// components
import { Button, Popover, Drawer } from '..'
import '../../css/navigation-bar.css'
// helpers
import { CheckIsVersionMobile, Dictionary } from '../../helpers'
// styles
import { NavigationBarStyles } from '../../styles/navgation-bar-styles'
// theme
import { Sprites } from '../../theme'
// libraries
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import { Link } from 'react-router-dom'

export const NavigationBarView = (props) => {

    const {
        popoverRef,
        isPopoverOpened,
        isDrawerOpened,
        drawerPosition,
        onHoverTitle,
        onLeaveTitle,
        onClickTitle,
        onHandleDrawerVisibiity,
    } = props

    const {
        aTag,
        zIndex1301,
        appBarContainer,
        toolBarContainer,
        toolBarContainerM,
        rightBarContainer,
        titleText,
        popContainer,
        popInnerContainer,
        popInnerContainerM,
        triangleUp,
        buttonPopGuideContainer,
        buttonPopGuideContainerM,
        buttonPopGuideInnerContainer,
        buttonPopGuideInnerContainerM,
        buttonPopGuideText,
        whiteBotttomLine,
        menuContainer,
    } = NavigationBarStyles()

    const isVersionMobile = CheckIsVersionMobile()

    const titles = Dictionary('navigationBarTitles')
    const homePageButtonTitles = Dictionary('homePageButtonTitles')
    const menuItems = Dictionary('mobileMenuItems')

    const isMenuBarOpened = isDrawerOpened && drawerPosition === 'left'

    const RenderMenuItems = (isLastItem: boolean, title: string) => (
        <Link
            to={`/n/${Dictionary('navigationBarLinks')[title]}`}
            className={aTag}
            onClick={() => onClickTitle(title)}
        >
            <div className={`${buttonPopGuideInnerContainerM} ${!isLastItem && whiteBotttomLine}`}>
                <Sprites
                    icon={`IconMenu${_.upperFirst(title)}`}
                    size={`iconPopGuide`}
                    alt={`icon-${title}`}
                />
                <div className={buttonPopGuideText}>{t(`screens.normalText.${title}`)}</div>
            </div>
        </Link>
    )

    const RenderPopoverContent = () => {
        const className1 = isVersionMobile ? popInnerContainerM : popInnerContainer
        const className2 = isVersionMobile ? buttonPopGuideContainerM : buttonPopGuideContainer
        return (
            <div className={className1}>
                {homePageButtonTitles?.map((title: string, index: number) => {
                    const isLastItem = index === homePageButtonTitles.length - 1
                    const className3 = isVersionMobile ? `${buttonPopGuideInnerContainerM} ${whiteBotttomLine}` : `${buttonPopGuideInnerContainer} ${!isLastItem && whiteBotttomLine}`
                    return (
                        <div
                            key={`button-pop-guide--${index}`}
                            className={className2}
                            onClick={() => null}
                        >
                            <div className={className3}>
                                <Sprites
                                    icon={`Icon${_.upperFirst(title)}`}
                                    size={`iconPopGuide`}
                                    alt={`icon-${title}`}
                                />
                                <div className={buttonPopGuideText}>{t(`screens.normalText.${title}`)}</div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    const RenderComponent = () => (
        <AppBar position='sticky' className={`${appBarContainer} ${isVersionMobile && zIndex1301}`}>
            <Toolbar className={isVersionMobile ? toolBarContainerM : toolBarContainer}>
                <Link
                    to="/n/home"
                    className={aTag}
                >
                    <Sprites
                        icon={'IconLogoWithTitle'}
                        size={'iconLogoWithTitle'}
                        alt={'cryptowesearch'}
                    />
                </Link>
                {isVersionMobile
                    ? <div
                        className={rightBarContainer}
                        onClick={() => onHandleDrawerVisibiity(!isMenuBarOpened, 'left')}
                    >
                        <Sprites
                            icon={isMenuBarOpened ? 'IconCloseDarkBlue' : 'IconMenuDarkBlue'}
                            size={isMenuBarOpened ? 'iconCloseDarkBlue' : 'iconMenuDarkBlue'}
                            alt={'menu'}
                        />
                    </div>
                    : <div className={rightBarContainer}>
                        {titles.map((title: string, index: number) => {
                            const isTitleGuide = title === 'guide'
                            return (
                                <Link
                                    to={`/n/${Dictionary('navigationBarLinks')[title]}`}
                                    className={aTag}
                                    onClick={() => onClickTitle(title)}
                                >
                                    <div
                                        ref={isTitleGuide ? popoverRef : null}
                                        aria-owns={isTitleGuide ? 'mouse-over-popover' : ''}
                                        aria-haspopup={isTitleGuide ? 'true' : 'false'}
                                        onMouseEnter={isTitleGuide ? onHoverTitle : () => null}
                                        onMouseLeave={isTitleGuide ? onLeaveTitle : () => null}
                                    >
                                        <Button
                                            buttonContainerClass={'navigationBarButtonContainer'}
                                            renderComponent={() => {
                                                return (
                                                    index !== titles.length - 1
                                                        ? <div className={titleText}>
                                                            {t(`screens.normalText.${title}`)}
                                                        </div>
                                                        : <Sprites
                                                            icon={'IconSearch'}
                                                            size={'iconSearch'}
                                                            alt={'icon-search'}
                                                        />
                                                )
                                            }}
                                            onClick={() => null}
                                        />
                                        {isTitleGuide
                                            ? <Popover
                                                popoverRef={popoverRef}
                                                isPopoverOpened={isPopoverOpened}
                                                onHoverTitle={onHoverTitle}
                                                onLeaveTitle={onLeaveTitle}
                                                renderComponent={() => {
                                                    return (
                                                        <div className={popContainer}>
                                                            <div className={triangleUp} />
                                                            {RenderPopoverContent()}
                                                        </div>
                                                    )
                                                }}
                                            />
                                            : null
                                        }
                                    </div>
                                </Link>
                            )
                        })}
                    </div>
                }
            </Toolbar>
        </AppBar>
    )

    return (
        isVersionMobile
            ? <div>
                <Drawer
                    isOpened={isDrawerOpened}
                    position={drawerPosition}
                    onHandleDrawerVisibiity={onHandleDrawerVisibiity}
                    renderComponent={() => {
                        return (
                            <div className={menuContainer}>
                                {menuItems?.map((title: string, index: number) => {
                                    const isLastItem = index === menuItems.length - 1
                                    return (
                                        <div
                                            key={`button-pop-guide--${index}`}
                                            className={buttonPopGuideContainerM}
                                            onClick={() => null}
                                        >
                                            {title !== 'guide'
                                                ? RenderMenuItems(isLastItem, title)
                                                : <Accordion>
                                                    <AccordionSummary>
                                                        {RenderMenuItems(isLastItem, title)}
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        {RenderPopoverContent()}
                                                    </AccordionDetails>
                                                </Accordion>
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    }}
                />
                {RenderComponent()}
            </div>
            : RenderComponent()
    )
}
