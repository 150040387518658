export const GetDiscountTimeDue = () => {
    const timeDue = localStorage.getItem('@DISCOUNT_TIME_DUE')
    const resolvedTimeDue = timeDue !== null ? timeDue : ''
    return resolvedTimeDue
}

export const SetDiscountTimeDue = (time: string) => {
    localStorage.setItem("@DISCOUNT_TIME_DUE", time)
}

export const RemoveDiscountTimeDue = () => {
    localStorage.removeItem('@DISCOUNT_TIME_DUE')
}
