import { makeStyles, withStyles } from '@material-ui/core/styles'
import { TextFieldProps } from '@material-ui/core'
import { Colors } from '../theme'

export const textFieldWithStyles = (Component: (props: TextFieldProps) => JSX.Element) => withStyles({
    root: {
          '&.Mui-focused fieldset': {
            borderColor: 'green',
        },
    },
})(Component)

export const textFieldStyles = makeStyles({
    textField: {
        
    },
    searchContainer: {
        backgroundColor: Colors.white,
        borderColor: Colors.transparent,
    },
})
