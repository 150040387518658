import { useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { UseDataFetching, UploadDataForCreateUpdateCoinAPI,GetCoinListAPI,GetCoinDataAPI,DeleteCoinAPI,GetCoinsAricleAPI } from '../../apis'
import {Table,Select,TextField,Grid,Snackbar,InputLabel,Button,FormLabel,TableBody,TableCell,TableContainer,TableHead,TableRow,Paper} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom'
import {APIDomainHTTP} from '../../helpers/api-domain-http'
import { GetUserToken } from '../../helpers';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const coinStyle = makeStyles((theme) => ({
    body:{
        paddingTop:10,
        marginTop:30,
        paddingLeft:70,
        paddingRight:100,
    },
    content:{
        padding:theme.spacing(2),
        paddingTop:40,
        paddingLeft:40,
        border:1,
        borderRadius:4,
        backgroundColor:"white",
        borderStyle:"solid",
        borderColor:"#bdbdbd",
        width:"100%",
        height:90,
    },
    select:{
        marginTop:2,
        backgroundColor:"white",
    },
    subTitle:{
        marginTop:5,
    },
    title:{
        marginTop:45,
    },
    inputFied:{
        marginTop:1,
    },
    textfield:{
        border:1,
        marginTop:9,
        borderRadius:4,
        backgroundColor:"white",
    },

})); 


export const AdminCoin= (props:any) => {
    const classes = coinStyle();
    const [rows ,setRows ] = useState([]);
    const [name ,setName ] = useState("");
    const [symbol ,setSymbol] = useState("");
    const [imageSrc ,setImageSrc ] = useState("");
    const [deleteTarget ,setDeleteTarget ] = useState(0);
    const [open, setOpen] = useState(false);
    const [postId,setPostId] = useState(0);
    const [showUploadImagebutton, setShowUploadImagebutton] = useState('');
    const [showImage, setShowImage] = useState('none');
    const [file, setFile] = useState(null);
    const [coinUuid,setCoinUuid] = useState("");
    const [postSuccess,setPostSuccess] = useState(false);
    const [currentCoinId,setCurrentCoinId] = useState(0);
    const [coinsAricleList ,setCoinsAricleList] = useState([{post_id:0,title:''}]);
    let history = useHistory();
    var id = props.location.search.substr(5,10);
    const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
        setDeleteTarget(0);
        setPostSuccess(false);
    };

    const createOrUpdateCoin = async() => {
        const { results } = await UseDataFetching(UploadDataForCreateUpdateCoinAPI({
            symbol:symbol,
            name:name,
            coinId:currentCoinId,
            postId:postId,
        })) as any
        switch (results['code']) {
            case 200:
                if (file){
                    var xhr, formData;
                    xhr = new XMLHttpRequest();
                    xhr.withCredentials = false;
                    if (id == ""){
                        xhr.open('POST', APIDomainHTTP() + '/v1/upload/coin_img/'+results['coin_uuid']);
                    }else{
                        xhr.open('POST', APIDomainHTTP() + '/v1/upload/coin_img/'+coinUuid);
                    }
                    xhr.setRequestHeader("Cache-Control","no-cache");
                    xhr.setRequestHeader("Authorization",GetUserToken());
                    xhr.onload = function() {
                        var json;
                        console.log(xhr.status);
                        json = JSON.parse(xhr.responseText);
                    };        
                    formData = new FormData();
                    file && formData.append('img', file, "upload.jpg");
                    xhr.send(formData);
                }
                setPostSuccess(true);
                if (id == ""){
                    GetCoinsList();
                }
                break
        }

    }
    const ClickViewButton = async(coinId:number) => {
        history.push("/admin-panel/coin?&id="+coinId)
    }

    const ClickDeleteButton = async() => {
        await UseDataFetching(DeleteCoinAPI({coinId:deleteTarget})) as any;
        GetCoinsList();
        setPostSuccess(true);
        setOpen(false);
    }
    const handleImageChange = (e:any) => {
        e.preventDefault();
        var reader = new FileReader();
        setShowUploadImagebutton('none');
        setShowImage('none');
        (document.getElementById("result")as any).innerHTML=""
        reader.onload = function (event) {
            var txt = (event.target as any).result;
            var img =  document.createElement("img");
            img.src = txt;
            img.id = "imageExample"
            img.width=80;
            img.height=80;
            (document.getElementById("result") as any).appendChild(img);
        };
        if (e.target.files[0]){
            reader.readAsDataURL(e.target.files[0]);
            setFile(e.target.files[0]); 
        }else{
            setShowUploadImagebutton('');
        }

    }

    const handleClickOpen = async(coinId:number)=> {
        setDeleteTarget(coinId);
        setOpen(true);
    };
   
    const GetCoin=  async()=> {
        var list:any =[];
        const  {results} =  await UseDataFetching(GetCoinDataAPI(id)) as any;
        setName(results['name']);
        setSymbol(results['symbol']);
        setCoinUuid(results['coin_uuid']);
        setPostId(results['post_id']);
        setImageSrc("https://cryptowesearch.s3.ap-southeast-1.amazonaws.com/coin_img/"+results["coin_uuid"]);
        setShowUploadImagebutton('none');
        setShowImage("");
    }
    const GetCoinsArticle =  async()=> {
        var list:any =[];
        const  {results} =  await UseDataFetching(GetCoinsAricleAPI({})) as any;
        (results['coins']||[]).map(function(result:any){
           list.push({'post_id':result.post_id,'title':result.title });
        })
        setCoinsAricleList(list);
        if (id ==""&&list[0]){
            setPostId(list[0].post_id);
        }
    }
    
    const GetCoinsList=  async()=> {
        var list:any =[];
        const  {results} =  await UseDataFetching(GetCoinListAPI({})) as any;
        (results['coins']||[]).map(function(result:any){
           list.push({'coin_id':result.coin_id,'name':result.name,'coin_uuid':result.coin_uuid,'symbol':result.symbol });
        })
        setRows(list);
        
      }

    useEffect(() =>{
        GetCoinsArticle();
        GetCoinsList();
        if (id ==""){
            setCurrentCoinId(0);
        }else{
            setCurrentCoinId(Number(id));
            GetCoin();
        }
    }, [id]);
    return <div  className={classes.body}>
        <div>
            <FormLabel component="legend">幣種列表</FormLabel>
        </div>
        <div>
            <TableContainer style={{marginTop:5}} component={Paper}>
                <Table  aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell align="center">Icon</TableCell>          
                        <TableCell align="center">幣種名稱</TableCell> 
                        <TableCell align="center">Symbol</TableCell> 
                        <TableCell align="center">Actions</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {rows.map((row:any) => (
                        <TableRow key={row.coin_id}>
                        <TableCell align="center">
                            <img style={{width:40,height:40,borderRadius:"100%",overflow:"hidden"}} src={"https://cryptowesearch.s3.ap-southeast-1.amazonaws.com/coin_img/"+row.coin_uuid}></img>
                        </TableCell>
                        <TableCell align="center">
                            {row.name}
                        </TableCell>
                        <TableCell align="center">{row.symbol}</TableCell>
                        <TableCell align="center">
                            <Button onClick={()=>ClickViewButton(row.coin_id)}>view</Button>
                            <Button onClick={()=>handleClickOpen(row.coin_id)}>delete</Button>
                        </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
            {id==""?
            <div className={classes.title}>
                <FormLabel component="legend">新增幣種</FormLabel>
            </div>
            :<div className={classes.title}></div>}
            <div className={classes.inputFied}>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <div className={classes.textfield}>
                            <TextField id="schema"
                            label="幣種名稱"
                            value={name}
                            onChange={event => setName(event.target.value)}
                            fullWidth
                            variant="outlined" />
                        </div>
                </Grid >
                <Grid item xs={6}>
                    <div className={classes.textfield}>
                        <TextField id="schema"
                        label="symbol"
                        value={symbol}
                        onChange={event => setSymbol(event.target.value)}
                        fullWidth
                        variant="outlined" />
                    </div>
                </Grid >
                </Grid >
                <div className={classes.subTitle}>
                    <FormLabel component="legend">幣種介紹</FormLabel>
                    </div>
                    <Select
                        value={postId}
                        onChange={event => setPostId(Number(event.target.value))}
                        fullWidth
                        className={classes.select}
                        native
                        variant="outlined" 
                        >
                        {
                            coinsAricleList.map((option, index) => (
                            <option key={option.post_id} value={option.post_id}>{option.title}</option>
                            ))
                        }
                        
                    </Select>
                <InputLabel style={{marginTop:10}}  htmlFor="standard-adornment-password">Upload Icon(56*56)</InputLabel>
                    <div style={{marginTop:10}}>
                        <input id="image" style={{display:'none'}} type="file" onChange={(e)=>handleImageChange(e)} />
                        <label style={{display:showUploadImagebutton, color:"#1890FF",border:"1px dashed #1890FF",padding:'3px 15px'}} htmlFor="image">upload</label>
                        <label  htmlFor="image"><div id ="result"></div>< img style={{display:showImage,width:'56px',height:'56px'}} src={imageSrc} /></label>
                        
                    </div>

                    <div  style={{marginTop:25}}>
                        <Button onClick={createOrUpdateCoin} color="primary" variant="outlined">{id==""?"Create":"Update"}</Button>
                     </div>
            </div> 
            <div>
                <Snackbar open={postSuccess} autoHideDuration={3000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">
                        Success!
                    </Alert>
                </Snackbar>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"delete?"}</DialogTitle>
                    <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        No
                    </Button>
                    <Button onClick={ClickDeleteButton} color="primary" autoFocus>
                        Yes
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>
    </div>
}