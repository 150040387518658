import { makeStyles } from '@material-ui/core/styles'
// theme
import { Colors, Layouts, Fonts, Sprites } from '../theme'
import * as Svgs from '../theme/sprites/svgs'

const subTitleText = {
    ...Fonts.fontMeduim400,
     color: Colors.greyBlack6,  
}

const background = {
    ...Layouts.defualtBackgroundStyle,
    backgroundImage: `url(${Svgs['IconHomepageHeaderBg']})`,
    backgroundColor: Colors.blue1,
}

export const HomepageHeaderStyles = makeStyles({
    background: {
        ...background,
        height: Layouts.homePageHeight.header,
    },
    backgroundM: {
        ...background,
        height: Layouts.M.homePageHeight.header - 28,
    },
    conatinerM: {
        ...Layouts.M.defaultContainerStyle,
    },
    container: {
        ...Layouts.defaultContainerStyle,
    },
    titleText: {
         paddingTop: 0,
    },
    titleContainerM: {
        paddingTop: 0,
        width: 216,
        margin: `0 24px`,
        marginTop: 32,
    },
    titleTextM: {
        ...Fonts.fontXBig700,
        margin: 0,
        color: Colors.greyBlack85,
    },
    subTitleText: {
        ...subTitleText,  
    },
    subTitleContainerM: {
        width: 240,
        margin: `4px 24px`,
    },
    subTitleTextM: {
        ...subTitleText, 
        marginBottom: 4,
    },
    buttonsContainer: {
        ...Layouts.rowWithJCSpaceBetween as {},
        paddingTop: 172,
    },
    buttonsContainerM: {
        ...Layouts.rowWithWrap as {},
        // paddingTop: 172,
        paddingTop: 320,
        margin: `0 24px`,
    },
    buttonInnerText: {
        ...Fonts.fontMediumBig700,
        lineHeight: 0,
        color: Colors.blue2,
    },
    buttonInnerTextM: {
        ...Fonts.fontSmall400,
        lineHeight: 0,
        color: Colors.greyBlack85,
    },
})
