import _ from 'lodash'
import React, { useLayoutEffect, useEffect, useState } from 'react'
// view screens
import { SearchView } from './search-view'
// types
import { APIArticle } from '../../interfaces'
// libraries
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
// apis
import { GetArticlesAPI } from '../../apis'
// helpers
import { ErrorAlert } from '../../helpers'

export const SearchController = () => {

    const history = useHistory()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [keywords, setKeywords] = useState<string>('')
    const [confirmKeywords, setConfirmKeywords] = useState<string>('')
    const [articles, setArticles] = useState<APIArticle[] | null>(null)

    const { error, data, refetch, isFetching, isSuccess } = useQuery<APIArticle[] | null, { message: string }>(['articles', confirmKeywords], () => !_.isEmpty(confirmKeywords) ? GetArticlesAPI({ keywords: confirmKeywords }) : null, {
        enabled: false,
    })

    useLayoutEffect(() => {
        const query = new URLSearchParams(window.location.search)
        const q = query.get('q')        
        setConfirmKeywords(q!)
    })

    useEffect(() => {
        if (!_.isEqual(articles, data)) {
            if (error !== null) {
                return ErrorAlert({ text1: error.message })
            }

            if (data) {
                setArticles(data)
            }
        }

        if (isSuccess || !isFetching) {
            setIsLoading(false)
        }
    }, [error, data, isFetching])

    useEffect(() => {
        if (keywords !== confirmKeywords) {
            setKeywords(confirmKeywords)
        }

        if (!_.isEmpty(confirmKeywords)){
            setIsLoading(true)
            refetch()
        }else{
            setArticles(null)
        }
    }, [confirmKeywords])

    const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
        setKeywords(e.target.value)
    }

    const onClickSearch = () => {
        history.push(`/n/search?q=${keywords.trim()}`)
    }

    const ViewProps = {
        isLoading,
        articles,
        keywords,
        onChangeText,
        onClickSearch,
    }

    return (
        <SearchView {...ViewProps} />
    )
}