import React, { useState } from 'react'
// view screens
import { BeginnerGuideItemView } from './beginner-guide-item-view'
// types
import { IBeginnerGuideItemControllerProps, IBeginnerGuideItemViewProps } from '../../interfaces'

export const BeginnerGuideItemController = (props: IBeginnerGuideItemControllerProps) => {

    const [currentHover, setCurrentHover] = useState<number>(-1)

    const onHandleSetHover = (index: number) => {
        setCurrentHover(index)
    }


    const ViewProps: IBeginnerGuideItemViewProps = {
        ...props,
        currentHover,
        onHandleSetHover,
    }

    return (
        <BeginnerGuideItemView {...ViewProps} />
    )
}
