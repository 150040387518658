const dispatchEvent = () => {
    window.dispatchEvent(new Event('storage'))
}

export const GetUserToken = () => {
    return localStorage.getItem('@USER_TOKEN') || null
}

export const SetUserToken = (userToken: string) => {
    localStorage.setItem('@USER_TOKEN', userToken)
    dispatchEvent()
}

export const RemoveUserToken = () => {
    localStorage.removeItem('@USER_TOKEN')
    dispatchEvent()
}
