import _ from 'lodash'
import React from 'react'
// styles
import { PageIndexStyles } from '../../styles'
// components
import { Button } from '..'
// theme
import { Sprites } from '../../theme'
// helpers
import { CheckIsVersionMobile } from '../../helpers'

export const PageIndexView = (props) => {

    const {
        pages,
        selectedPageIndex,
        onClickPageIndex,
        onClickEtc,
    } = props

    const {
        pageIndexsContainer,
        pageIndexsContainerM,
    } = PageIndexStyles()

    const isVersionMobile = CheckIsVersionMobile()

    const RenderArrowButton = (page: number) => {

        return (
            <Button
                disabled={false}
                renderComponent={() => {
                    return (
                        <Sprites
                            icon={`IconArrow${page === -1 ? 'Left' : 'Right'}`}
                            size={isVersionMobile ? `iconArrowM` : `iconArrow`}
                            alt={`icon-arrow-${page === -1 ? 'left' : 'right'}`}
                        />
                    )
                }}
                buttonContainerClass={'arrowContainer'}
                onClick={() => onClickPageIndex((selectedPageIndex + (
                    page === -1 && selectedPageIndex > 1
                        ? -1
                        : page === -2 && selectedPageIndex < pages[pages.length - 2]
                            ? 1
                            : 0
                )))}
            />
        )
    }

    const RenderNumberButton = (page: number) => {
        return (
            <Button
                buttonContainerClass={selectedPageIndex === page ? 'selectedPageIndexContainer' : 'pageIndexContainer'}
                buttonTextClass={selectedPageIndex === page ? 'selectedPageIndexText' : 'pageIndexText'}
                buttonText={`${page}`}
                onClick={() => onClickPageIndex(page)}
            />
        )
    }


    const RenderNumberButtonM = (page: number) => {
        return (
            <Button
                buttonContainerClass={selectedPageIndex === page ? 'selectedPageIndexContainerM' : 'pageIndexContainerM'}
                buttonTextClass={selectedPageIndex === page ? 'selectedPageIndexTextM' : 'pageIndexTextM'}
                buttonText={`${page}`}
                onClick={() => onClickPageIndex(page)}
            />
        )
    }


    const RenderEtcButton = () => {
        return (
            <Button
                buttonContainerClass={isVersionMobile ? 'pageIndexContainerM' : 'pageIndexContainer'}
                renderComponent={() => {
                    return (
                        <Sprites
                            icon={'IconEtc'}
                            size={isVersionMobile ? 'iconEtcM' : 'iconEtc'}
                            alt={'icon-etc'}
                        />
                    )
                }}
                onClick={onClickEtc}
            />
        )
    }

    return (
        <div className={isVersionMobile ? pageIndexsContainerM : pageIndexsContainer}>
            {pages.map((page: number | null, index: number) => {
                return (
                    <div key={`page--${index}`}>
                        {page === null
                            ? RenderEtcButton()
                            : page < 0
                                ? RenderArrowButton(page)
                                : isVersionMobile
                                    ? RenderNumberButtonM(page)
                                    : RenderNumberButton(page)
                        }
                    </div>
                )
            })}
        </div>
    )
}
