import _ from 'lodash'
import React from 'react'
// helpers
import { Dictionary, CheckIsVersionMobile } from '../../helpers'
// styles
import { FooterStyles } from '../../styles'
// theme
import { Sprites } from '../../theme'

export const Footer = () => {

    const {
        footerBackground,
        footerBackgroundM,
        container,
        footerContainerM,
        footerLeftInnerContainer,
        footerRightInnerContainer,
        footerCategoriesContainer,
        footerCategoriesContainerM,
        footerCategoryText,
        footerSubCategoryTextContainer,
        footerSubCategoryText,
        footerDisclaimerContainer,
        footerDisclaimerContainerM,
        footerDisclaimerText,
        footerShareIconsContainer,
        footerShareIconsContainerM,
        footerShareIconContainer,
        footerShareIconsContainerWrapperM,
    } = FooterStyles()
    const isVersionMobile = CheckIsVersionMobile()

    const RenderFooterCategories = () => (
        Dictionary('footerCategories')?.map((category: string, index: number) => {
            return (
                <div key={`footer-category--${index}`}>
                    <h5 className={footerCategoryText}>{t(`screens.normalText.${category}`)}</h5>
                    {Dictionary(`footerCategory${_.upperFirst(category)}`)?.map((subCategory: string, iindex: number) => {
                        return (
                            <div
                                key={`sub-category-${category}--${iindex}`}
                                className={footerSubCategoryTextContainer}
                            >
                                <a href={'/n/articles'} className={footerSubCategoryText}>{t(`screens.normalText.${subCategory}`)}</a>
                            </div>
                        )
                    })}
                </div>
            )
        }
        ))

    const RenderFooterShares = () => (
        Dictionary('footerShares')?.map((share: string, index: number) => {
            return (
                <div
                    key={`footer-icon-share--${index}`}
                    className={footerShareIconContainer}
                >
                    <Sprites
                        icon={`IconFooterShare${_.upperFirst(share)}`}
                        size={'iconFooterShare'}
                        alt={`icon-footer-share-${share}`}
                    />
                </div>
            )
        })
    )

    const RenderComponent = () => (
        <div className={footerBackground}>
            <div className={container}>
                <div className={footerLeftInnerContainer}>
                    <Sprites
                        icon={'IconLogoWithTitleWhite'}
                        alt={'icon-logo-white'}
                    />
                    <div className={footerCategoriesContainer}>
                        {RenderFooterCategories()}
                    </div>
                </div>
                <div className={footerRightInnerContainer}>
                    <div className={footerDisclaimerContainer}>
                        <div className={footerDisclaimerText}>{t('screens.normalText.disclaimer')}</div>
                        <div>
                            <div className={footerShareIconsContainer}>
                                {RenderFooterShares()}
                            </div>
                            <div className={footerDisclaimerText}>{t('screens.normalText.copyRight')}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    const RenderComponentM = () => (
        <div className={footerBackgroundM}>
            <div className={footerContainerM}>
                <Sprites
                    icon={'IconLogoWithTitleWhite'}
                    size={'iconLogoWithTitleWhiteM'}
                    alt={'icon-logo-white'}
                />
                <div className={footerCategoriesContainerM}>
                    {RenderFooterCategories()}
                </div>
                <div className={footerDisclaimerContainerM}>
                    <div className={footerDisclaimerText}>{t('screens.normalText.disclaimer')}</div>
                    <div className={footerShareIconsContainerWrapperM}>
                        <div className={footerShareIconsContainerM}>
                            {RenderFooterShares()}
                        </div>
                        <div className={footerDisclaimerText}>{t('screens.normalText.copyRight')}</div>
                    </div>
                </div>
            </div>
        </div>

    )

    return (
        isVersionMobile
            ? RenderComponentM()
            : RenderComponent()
    )
}
