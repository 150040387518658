import { useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {FormLabel,Modal,Select,TextField,Button,Grid,Snackbar,Card,CardActions,Typography,CardActionArea,CardContent,CardMedia} from '@material-ui/core';
import { UseDataFetching, UploadDataForCreateOrUpdateHomepageBannerAPI,GetHomepageBannersAPI,DeleteHomepageBannerAPI } from '../../apis'
import { GetUserToken } from '../../helpers';
import {APIDomainHTTP} from '../../helpers/api-domain-http';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const HomepageBannerStyle = makeStyles((theme) => ({
    body:{
        paddingTop:10,
        marginTop:10,
        paddingRight:10,
    },
    modalContent:{
        minWidth: 600,
        padding:15,
        backgroundColor: 'white',
        borderRadius:4,
        border:1,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    card:{
        marginTop:10,
        maxWidth: 1200,
    },
    media:{
        //maxWidth:500,
        height: 160,
    }
}));



export const AdminHomepageBanner= () => {
    const classes = HomepageBannerStyle();
    const [open, setOpen] = useState(false);
    const [postSuccess,setPostSuccess] = useState(false);
    const [link, setLink] = useState('');
    const [order, setOrder] = useState(0);
    const [selectedDate, setSelectedDate] = useState(0);
    const [selectedEndDate, setSelectedEndDate] = useState(0);
    const d = new Date(); 
    const year = (d.getFullYear()).toString();
    const month = ((d.getMonth()) + 101).toString().slice(-2);
    const date = ((d.getDate()) + 100).toString().slice(-2);
    const hours = ((d.getHours()) + 100).toString().slice(-2);
    const mins = ((d.getMinutes()) + 100).toString().slice(-2);
    const [dateTime, setDateTime] = useState(`${year}-${month}-${date}T${hours}:${mins}`);
    const [endTime, setEndTime] = useState(`${year}-${month}-${date}T${hours}:${mins}`);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [deleteTarget, setDeleteTarget] = useState(0);
    const [imageAlt, setImageAlt] = useState('');
    const [bannerStatus,setBannerStatus]=useState('Active');
    const [showUploadMobileImagebutton, setShowUploadMobileImagebutton] = useState('');
    const [showUploadDesktopImagebutton, setShowUploadDesktopImagebutton] = useState('');
    const [showMobileImage, setShowMobileImage] = useState('none');
    const [showDesktopImage, setShowDesktopImage] = useState('none');
    const [loading,setLoading]=useState(false);
    const [desktopImagePreviewUrl, setDesktopImagePreviewUrl] = useState('');
    const [mobileImagePreviewUrl, setMobileImagePreviewUrl] = useState('');
    const [desktopImageFile,setDesktopImageFile] = useState(null);
    const [mobileImageFile,setMobileImageFile] = useState(null);
    const [bannerId,setBannerId] = useState(0);
    const [bannerList,setBannerList] = useState([{id:0,link:'',image_alt:'',order:0,banner_uuid:'',click_times:0,public_time:0,end_time:0,status:''}]);
    const save = async(e:any) => {
        setLoading(true);
        const { error , results } = await UseDataFetching(UploadDataForCreateOrUpdateHomepageBannerAPI({
            link:link,
            id:bannerId,
            order:order,
            imageAlt:imageAlt,
            publicTime:selectedDate,
            endTime:selectedEndDate,
            status:bannerStatus,
            })) as any
            switch (results['code']) {
                case 200:
                   if (desktopImageFile){
                       var xhr, formData;
                       xhr = new XMLHttpRequest();
                       xhr.withCredentials = false;
                       xhr.open('POST', APIDomainHTTP() + '/v1/upload/desktop_banner/'+results['banner_uuid']);
                       xhr.setRequestHeader("Cache-Control","no-cache");
                       xhr.setRequestHeader("Authorization",GetUserToken());
                       xhr.onload = function() {
                           console.log(xhr.responseText);
                       };        
                       formData = new FormData();
                       desktopImageFile && formData.append('img', desktopImageFile, "upload.jpg");
                       xhr.send(formData);
                }
                if (mobileImageFile){
                    var xhr, formData;
                    xhr = new XMLHttpRequest();
                    xhr.withCredentials = false;
                    xhr.open('POST', APIDomainHTTP() + '/v1/upload/mobile_banner/'+results['banner_uuid']);
                    xhr.setRequestHeader("Cache-Control","no-cache");
                    xhr.setRequestHeader("Authorization",GetUserToken());
                    xhr.onload = function() {
                        console.log(xhr.responseText);
                    };        
                    formData = new FormData();
                    mobileImageFile && formData.append('img', mobileImageFile, "upload.jpg");
                    xhr.send(formData);
                }      
                setPostSuccess(true);
                setOpen(false);
                GetBannersData();


            }
            setLoading(false);


    }
    const handleAddOpen = () => {
        setShowDesktopImage('none');
        setShowMobileImage('none');
        setShowUploadMobileImagebutton('');
        setShowUploadDesktopImagebutton('');
        setMobileImagePreviewUrl('');
        setDesktopImagePreviewUrl('');
        setLink('');
        setImageAlt('');
        setDesktopImageFile(null);
        setMobileImageFile(null);
        setOrder(0);
        setBannerId(0);
        setOpen(true);
    };
    const handleEditOpen = (index:number) => {
        setShowDesktopImage('');
        setShowMobileImage('');
        setShowUploadMobileImagebutton('none');
        setShowUploadDesktopImagebutton('none');
        setMobileImagePreviewUrl('https://cryptowesearch.s3.ap-southeast-1.amazonaws.com/mobile_banner/'+bannerList[index].banner_uuid);
        setDesktopImagePreviewUrl('https://cryptowesearch.s3.ap-southeast-1.amazonaws.com/desktop_banner/'+bannerList[index].banner_uuid);
        setBannerId(bannerList[index].id);
        setImageAlt(bannerList[index].image_alt);
        setLink(bannerList[index].link);
        setBannerStatus(bannerList[index].status);
        setOrder(bannerList[index].order);

        var d = new Date(bannerList[index].public_time* 1000);
        const dyear = (d.getFullYear()).toString();
        const dmonth = ((d.getMonth()) + 101).toString().slice(-2);
        const ddate = ((d.getDate()) + 100).toString().slice(-2);
        const dhours = ((d.getHours()) + 100).toString().slice(-2);
        const dmins = ((d.getMinutes()) + 100).toString().slice(-2);
        setDateTime(`${dyear}-${dmonth}-${ddate}T${dhours}:${dmins}`)

        var e = new Date(bannerList[index].end_time* 1000);
        const eyear = (d.getFullYear()).toString();
        const emonth = ((d.getMonth()) + 101).toString().slice(-2);
        const edate = ((d.getDate()) + 100).toString().slice(-2);
        const ehours = ((d.getHours()) + 100).toString().slice(-2);
        const emins = ((d.getMinutes()) + 100).toString().slice(-2);
        setEndTime(`${eyear}-${emonth}-${edate}T${dhours}:${emins}`)

        
        setOpen(true);
    };
    const handleSelectedPublicDate = async(value)=>{
        const selectTime = new Date(value)
        const inSeconds = Math.floor(selectTime.getTime() / 1000);
        setSelectedDate(inSeconds)
        setDateTime(value);
    }
    const handleSelectedEndDate = async(value)=>{
        const selectTime = new Date(value)
        const inSeconds = Math.floor(selectTime.getTime() / 1000);
        setSelectedEndDate(inSeconds)
        setEndTime(value);
    }
    const handleClickDelete = async(id:number)=> {
        setDeleteTarget(id)
        setDialogOpen(true);
    };
    const ClickDeleteBannerButton = async() => {
        await UseDataFetching(DeleteHomepageBannerAPI({id:deleteTarget})) as any;
        setPostSuccess(true);
        setDialogOpen(false);
        GetBannersData();
    };

    const handleClose = () => {
        setOpen(false);
        setPostSuccess(false);
        setDeleteTarget(0);
        setDialogOpen(false);
    };
    const handleDesktopImageChange = (e:any) => {
        e.preventDefault();
        var reader = new FileReader();
        setShowUploadDesktopImagebutton('none');
        setShowDesktopImage('none');
        (document.getElementById("desktop_result")as any).innerHTML=""
        reader.onload = function (event) {
            var txt = (event.target as any).result;
            var img = document.createElement("img");
            img.src = txt;
            img.width=260;
            img.height=52;
            (document.getElementById("desktop_result") as any).appendChild(img);
        };
        
        if (e.target.files[0]){
            reader.readAsDataURL(e.target.files[0]);
            setDesktopImageFile(e.target.files[0]); 
        }else{
            setShowUploadDesktopImagebutton('');
        }
    }
    const handleStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setBannerStatus(event.target.value as any);
    };
    const handleMobileImageChange = (e:any) => {
        e.preventDefault();
        var reader = new FileReader();
        setShowUploadMobileImagebutton('none');
        setShowMobileImage('none');
        (document.getElementById("mobile_result")as any).innerHTML=""
        reader.onload = function (event) {
            var txt = (event.target as any).result;
            var img = document.createElement("img");
            img.src = txt;
            img.width=123;
            img.height=132;
            (document.getElementById("mobile_result") as any).appendChild(img);
        };
        
        if (e.target.files[0]){
            reader.readAsDataURL(e.target.files[0]);
            setMobileImageFile(e.target.files[0]); 
        }else{
            setShowUploadMobileImagebutton('');
        }
    }

   
    const GetBannersData =  async()=> {
        var list:any =[];
        let index=0;
        const  {results} =  await UseDataFetching(GetHomepageBannersAPI({})) as any;
        (results['banners']||[]).map(function(result:any){
           list.push({'index':index,'status':result.status,'id':result.id,'banner_uuid':result.banner_uuid,'image_alt':result.image_alt,'order':result.order,'link':result.link,'click_times':result.click_times,"public_time":result.public_time,"end_time":result.end_time});
            index = index + 1;
        })
        setBannerList(list);
    }

    const body = (
        <div className={classes.modalContent}>           
            <div >
            <FormLabel component="legend">Link</FormLabel>
            </div>
            <div>
                <TextField id="Link" 
                value={link}
                onChange={event => setLink(event.target.value)}
                fullWidth 
                required
                variant="outlined" />
            </div>

            <div  style={{marginTop:10}}>
                <FormLabel component="legend">Image Alt</FormLabel>
            </div>
            <div  >
                <TextField id="alt" 
                value={imageAlt}
                onChange={event => setImageAlt(event.target.value)}
                fullWidth 
                required
                variant="outlined" />
            </div>
            
             <div  style={{marginTop:10}}>
                <FormLabel component="legend">順序</FormLabel>
            </div>
            <TextField id="order" 
                    value={order}
                    type="number"
                    onChange={event => setOrder(Number(event.target.value))}
                    fullWidth 
                    required
                    variant="outlined" />

            <div  style={{marginTop:10}}>
                <FormLabel component="legend">Status</FormLabel>
            </div>
            <Select
                value={bannerStatus}
                onChange={handleStatusChange}
                fullWidth
                native
                variant="outlined" 
                >
                 <option key="active" value="active">active</option>
                 <option key="inActive" value="inActive">inActive</option>
            </Select>


            <div  style={{marginTop:10}}>
                <FormLabel component="legend">發佈時間</FormLabel>
            </div>
            <TextField
                id="publicTime"
              //  label="Next appointment"
                value={dateTime}
                type="datetime-local"
                //defaultValue={dateTime}
                onChange={event => handleSelectedPublicDate(event.target.value)}
                fullWidth
                InputLabelProps={{
                shrink: true,
                }}
            />

            <div  style={{marginTop:10}}>
                <FormLabel component="legend">結束時間</FormLabel>
            </div>
            <TextField
                id="publicTime"
              //  label="Next appointment"
                value={endTime}
                type="datetime-local"
                //defaultValue={dateTime}
                onChange={event => handleSelectedEndDate(event.target.value)}
                fullWidth
                InputLabelProps={{
                shrink: true,
                }}
            />

            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <div  style={{marginTop:10}}>
                        <FormLabel component="legend">Desktop Image</FormLabel>
                    </div>
                    <div style={{marginTop:10}}>
                        <input id="desktopImage" style={{display:'none'}} type="file" onChange={(e)=>handleDesktopImageChange(e)}/>
                        <label style={{display:showUploadDesktopImagebutton, color:"#1890FF",border:"1px dashed #1890FF",padding:'3px 15px'}} htmlFor="desktopImage">upload</label>
                        <label  htmlFor="desktopImage"><div id ="desktop_result"></div>< img style={{display:showDesktopImage,width:'260px',height:'52px'}} src={desktopImagePreviewUrl} /></label>
                    </div>
                </Grid>
            
                <Grid item xs={6}>
                    <div  style={{marginTop:10}}>
                        <FormLabel component="legend">Mobile Image</FormLabel>
                    </div>
                    <div style={{marginTop:10}}>
                        <input id="mobileImage" style={{display:'none'}} type="file" onChange={(e)=>handleMobileImageChange(e)}/>
                        <label style={{display:showUploadMobileImagebutton, color:"#1890FF",border:"1px dashed #1890FF",padding:'3px 15px'}} htmlFor="mobileImage">upload</label>
                        <label  htmlFor="mobileImage"><div id ="mobile_result"></div>< img style={{display:showMobileImage,width:'123px',height:'132px'}} src={mobileImagePreviewUrl} /></label>
                    </div>
                </Grid>
            </Grid>
            
            <div style={{marginTop:25}}>
                <Button  onClick={save}  variant="contained" color="primary">添加/修改</Button>
                {loading && <CircularProgress  size={20} style={{marginLeft:10}} />}
            </div>
             

        </div>
    );
    useEffect(() => {
        GetBannersData();
    }, []);

    return <div>
        <div className={classes.body}>

        <div>
            <FormLabel component="legend">(更改圖片需要較長時間，建議重新添加banner)
                <button onClick={handleAddOpen} style={{marginLeft:5,width:30}}  >+</button>
            </FormLabel>
        </div>
        <div>
        {bannerList.map((row:any) => (
            <Card className={classes.card}>
            <CardActionArea>
                <Grid container spacing={1}>
                    <Grid item xs={9}>
                        <CardMedia
                            className={classes.media}
                            image={"https://cryptowesearch.s3.ap-southeast-1.amazonaws.com/desktop_banner/"+row.banner_uuid}
                            title="image"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <CardMedia
                            className={classes.media}
                            image={"https://cryptowesearch.s3.ap-southeast-1.amazonaws.com/mobile_banner/"+row.banner_uuid}
                            title="image"
                        />
                    </Grid>
                    
                </Grid>
                    
            </CardActionArea>
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    {row.image_alt!==""?(row.image_alt+"  "):null}
                    {"(點擊次數:"+row.click_times+")"}
                </Typography>
            </CardContent> 
            <CardActions>
                <Button onClick={()=>handleEditOpen(row.index)} size="small" color="primary">
                Edit
                </Button>
                <Button onClick={()=>handleClickDelete(row.id)} size="small" color="primary">
                Delete
                </Button>
            </CardActions>
        </Card>
         ))}
           

        </div>
        
        
        </div>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >
        {body}
        </Modal>
        <Snackbar open={postSuccess} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
            Success!
        </Alert>
        </Snackbar>
        <Dialog
            open={dialogOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">{"delete?"}</DialogTitle>
            <DialogActions>
            <Button onClick={handleClose} color="primary">
                No
            </Button>
            <Button onClick={ClickDeleteBannerButton} color="primary" autoFocus>
                Yes
            </Button>
            </DialogActions>
         </Dialog>
    </div>
}