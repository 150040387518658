import { useState, useEffect} from 'react';
import { makeStyles,Theme ,createStyles} from '@material-ui/core/styles';
import { Snackbar,Grid,FormLabel,TableContainer, Table, Paper,TableHead, TableRow, TableCell, TableBody,InputLabel,InputAdornment,IconButton,Input ,Button} from '@material-ui/core';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import { useHistory } from 'react-router-dom'
import { UseDataFetching, GetProfileAPI,UploadDataForUpdateProfileAPI,GetAdminUserAPI,GetArticlesListAPI} from '../../apis'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { GetUserToken } from '../../helpers';
import {APIDomainHTTP} from '../../helpers/api-domain-http'
function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function GetCookies(name:any){
    var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    if (arr = document.cookie.match(reg)) return unescape(arr[2]);
    else return null;
};
const UserProfileStyle = makeStyles((theme: Theme) => 
    createStyles({
        body:{
            paddingTop:30,
            marginTop:30,
            paddingLeft:70,
            paddingRight:150,
        },
        content:{
            padding:theme.spacing(2),
            paddingTop:40,
            paddingLeft:40,
            marginTop:10,
            border:1,
            borderRadius:4,
            backgroundColor:"white",
            borderStyle:"solid",
            borderColor:"#bdbdbd",
            width:"100%",
            height:136,
        },
        tableContent:{
            padding:theme.spacing(2),
            paddingTop:10,
            paddingLeft:40,
            marginTop:10,
            border:1,
            borderRadius:4,
            backgroundColor:"white",
            borderStyle:"solid",
            borderColor:"#bdbdbd",
            width:"100%",
        }
    })
);


export const AdminProfile = (props:any) => {
    const classes = UserProfileStyle();
    let history = useHistory();
    const [password, setPassword] = useState('');
    const [nickname, setNickname] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [username, setUsername] = useState('');
    const [queryUsername,setQueryUsername] = useState('');
    const [queryNickname,setQueryNickname] = useState('');
    const [lastLoginTime, setLastLoginTime] = useState('');
    const [registrationTime, setRegistrationTime] = useState('');
    const [imageSrc, setImageSrc] = useState('');
    const [postSuccess,setPostSuccess] = useState(false);
    const [rows ,setRows ] = useState([]);
    const [file ,setFile ] = useState(null);
    const [showImage,setShowImage] =  useState('');
    const [description, setDescription] = useState('');
    const [queryDescription, setQueryDescription] = useState('');
    
   // let file;
    const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setPostSuccess(false);
    };
    const handleClickShowPassword = () => {
        setShowPassword(false)
     };
     const ClickViewDataButton = (postId:number,routePath:string) => {
        history.push("/n/blog/all/"+routePath)
      };
     const GetUserProfileData =  async()=> {
        const  {error,results} =  await UseDataFetching(GetProfileAPI({})) as any;
        setNickname(results['nickname']);
        setUsername(results['username']);
        setPassword(results['password']);
        setDescription(results['description']);
    }
    const GetOtherUserData =  async(userId:number)=> {
        const  {error,results} =  await UseDataFetching(GetAdminUserAPI(userId)) as any
        setQueryNickname(results['nickname']);
        setQueryUsername(results['username']);
        setRegistrationTime(results['registration_time']);
        setLastLoginTime(results['last_login_time']);
        setQueryDescription(results['description']);
    }
    
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        setShowPassword(true)
        event.preventDefault();
    };
    const GetTableData = async(page:number,author:string) => {
        var list:any =[];
        const  {error,results} =  await UseDataFetching(GetArticlesListAPI({year:0,month:0,category:"all",tag:"all",status:"all",page:page,keyword:"",author:author})) as any;
        (results['articles']||[]).map(function(result:any){
          list.push({'id':result.post_id,'status':result.post_status,'title':result.title,'author':result.author, 'category':result.category,'tags':result.tags,'date':result.date,'path':result.path });
        })
        setRows(list);
    };
    const handleImageChange = (e:any) => {
        e.preventDefault();
        var reader = new FileReader();
        setShowImage("none");
        (document.getElementById("result")as any).innerHTML=""
        reader.onload = function (event) {
            var txt = (event.target as any).result;
            var img = document.createElement("img");
            img.src = txt;
            img.width=40;
            img.height=40;
            (document.getElementById("result") as any).appendChild(img);
        };

        if (e.target.files[0]){
            reader.readAsDataURL(e.target.files[0]);
            setFile(e.target.files[0]); 
        }
    };

    const UpdateUserProfileData =  async()=> {
        var list:any =[];
        if (username == "" || password == "" || nickname == ""){
            return
        }
        console.log(password);
        const  {error,results} =  await UseDataFetching(UploadDataForUpdateProfileAPI({username:username,password:password,nickname:nickname,description:description})) as any;
        switch(results['code']){
            case 200:
                if (file){
                    var xhr, formData;
                    xhr = new XMLHttpRequest();
                    xhr.withCredentials = false;
                    xhr.open('POST', APIDomainHTTP() + '/v1/upload/user_icon');
                    xhr.setRequestHeader("Cache-Control","no-cache");
                    xhr.setRequestHeader("Authorization",GetUserToken());
                    xhr.onload = function() {
                        var json;
                        console.log(xhr.status);
                        if (xhr.status === 200) {
                            var userUuid = String(GetCookies("userUuid"))
                            setImageSrc("https://cryptowesearch.s3.ap-southeast-1.amazonaws.com/user_icon/"+userUuid)
                        }    
                        json = JSON.parse(xhr.responseText);
                        console.log(json);    
                    };        
                    formData = new FormData();
                    file && formData.append('img', file, "upload.jpg");
                    xhr.send(formData);
                }
                
                setPostSuccess(true);
                break
            case 1001:
                break    
        }
    }
    var id = props.location.search.substr(5,10);

    useEffect(() =>{
        if(id!=""){
            GetOtherUserData(id);
            GetTableData(1,id);
        }else{
            GetUserProfileData();
            var userId = String(GetCookies("userId"))
            var userUuid = String(GetCookies("userUuid"))
            GetTableData(1,userId);
            setImageSrc("https://cryptowesearch.s3.ap-southeast-1.amazonaws.com/user_icon/"+userUuid)
        }
    }, [id]);

    return <div className={classes.body}>
        <div>
            <FormLabel component="legend">用戶信息</FormLabel>
        </div>
        <div className={classes.content}>
        {id==""?
        <Grid container spacing={3}>
            <Grid item xs={3}>
                <InputLabel htmlFor="standard-adornment-password">用戶名</InputLabel>
                <Input
                    id="username"
                    value={username}
                    onChange={(event) => setUsername(event.target.value)}
                    aria-describedby="standard-weight-helper-text"
                    inputProps={{
                    'aria-label': 'weight',
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <InputLabel htmlFor="standard-adornment-password">顯示名稱</InputLabel>
                    <Input
                        id="nickname"
                        type='text'
                        value={nickname}
                        onChange={(event) => setNickname(event.target.value)}
                        aria-describedby="standard-weight-helper-text"
                        inputProps={{
                        'aria-label': 'weight',
                        }}
                    />
            </Grid>

            <Grid item xs={3}>
                <InputLabel htmlFor="standard-adornment-password">密碼</InputLabel>
                    <Input
                        id="password"
                        type={showPassword? 'text' : 'password'}
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                        }
                    />
            </Grid>
            <Grid item xs={3}>
                <InputLabel htmlFor="standard-adornment-password">上傳頭像</InputLabel>
                <div id="upload" style={{textAlign:"center"}}>
                    <input id="imageUpload" style={{display:'none'}} type="file" onChange={(e)=>handleImageChange(e)}/>
                    <label  htmlFor="imageUpload">
                        <img id="preview" style={{display:showImage,width:40,height:40,borderRadius:"100%",overflow:"hidden"}} src={imageSrc}></img>
                        <div id="result"></div>
                    </label>
                </div>


            </Grid>
            <Grid item xs={9}>
                <InputLabel htmlFor="standard-adornment-password">描述</InputLabel>
                    <Input
                        id="description"
                        type='text'
                        value={description}
                        fullWidth
                        onChange={(event) => setDescription(event.target.value)}
                        aria-describedby="standard-weight-helper-text"
                        inputProps={{
                        'aria-label': 'weight',
                        }}
                    />
            </Grid>
            <Grid item xs={3}>
                <div  style={{marginTop:10,marginLeft:50}}>
                    <Button variant="contained" color="primary" onClick={UpdateUserProfileData}>Update</Button>
                </div>
            </Grid>
        </Grid>
        :<div>
             <Grid container spacing={3}>
                <Grid item xs={3}>
                <InputLabel htmlFor="standard-adornment-password">用戶名</InputLabel>
                <Input
                    id="username"
                    value={queryUsername}
                    disabled
                    aria-describedby="standard-weight-helper-text"
                    inputProps={{
                    'aria-label': 'weight',
                    }}
                />
                </Grid>
                <Grid item xs={3}>
                <InputLabel htmlFor="standard-adornment-password">顯示名稱</InputLabel>
                <Input
                    id="nickname"
                    value={queryNickname}
                    disabled
                    aria-describedby="standard-weight-helper-text"
                    inputProps={{
                    'aria-label': 'weight',
                    }}
                />
                </Grid>
                <Grid item xs={3}>
                <InputLabel htmlFor="standard-adornment-password">上次登陸時間</InputLabel>
                <Input
                    id="lastLoginTime"
                    value={lastLoginTime=="1970-01-01"?"未曾登陸":lastLoginTime}
                    disabled
                    aria-describedby="standard-weight-helper-text"
                    inputProps={{
                    'aria-label': 'weight',
                    }}
                />
                </Grid>
                <Grid item xs={3}>
                <InputLabel htmlFor="standard-adornment-password">註冊時間</InputLabel>
                <Input
                    id="registrationTime"
                    value={registrationTime}
                    disabled
                    aria-describedby="standard-weight-helper-text"
                    inputProps={{
                    'aria-label': 'weight',
                    }}
                />
                </Grid>
                <Grid item xs={12}>
                <InputLabel htmlFor="standard-adornment-password">描述</InputLabel>
                    <Input
                        id="description"
                        type='text'
                        value={queryDescription}
                        fullWidth
                        disabled
                        onChange={(event) => setQueryDescription(event.target.value)}
                        aria-describedby="standard-weight-helper-text"
                        inputProps={{
                        'aria-label': 'weight',
                        }}
                    />
            </Grid>
            </Grid>
        </div>
        }
        </div>

        <div style={{marginTop:25}}>
            <FormLabel component="legend">用戶文章</FormLabel>
        </div>
        <div className={classes.tableContent}>
             <TableContainer style={{marginTop:5}} >
                <Table  aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell  align="center">內容標題</TableCell>
                        <TableCell  align="center">狀態</TableCell>
                        <TableCell  align="center">分類</TableCell>
                        <TableCell  align="center">標籤</TableCell>
                        <TableCell  align="center">發布日期</TableCell>
                        <TableCell  align="center">Actions</TableCell>

                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {rows.map((row:any) => (
                        <TableRow key={row.post_id}>
                        <TableCell   align="center">{row.title}</TableCell>
                        <TableCell   align="center">
                            {row.status}
                        </TableCell>
                        <TableCell   align="center">{row.category}</TableCell>
                        <TableCell   align="center">{row.tags}</TableCell>
                        <TableCell   align="center">{row.date}</TableCell>
                        <TableCell   align="center">
                            <Button onClick={() => ClickViewDataButton(row.id,row.path)}>view</Button>
                        </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
        <Snackbar open={postSuccess} autoHideDuration={3000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
                Success!
            </Alert>
        </Snackbar>
    </div>
}