import React from 'react'
// styles
import { FollowOurSocietyStyles } from '../../styles'
// components
import { SocialShare } from '../social-share'
import { SocialOtherShares } from '../social-other-shares'
// helpers
import { CheckIsVersionMobile } from '../../helpers'

export const FollowOurSocietyView = () => {

    const {
        background,
        container,
        containerM,
        headerText,
        headerTextM,
        contentContainer,
    } = FollowOurSocietyStyles()
    const isVersionMobile = CheckIsVersionMobile()

    return (
        <div className={background}>
            <div className={isVersionMobile ? containerM : container}>
                {isVersionMobile
                    ? <h3 className={headerTextM}>{t('screens.normalText.followOurSociety')}</h3>
                    : <h2 className={headerText}>{t('screens.normalText.followOurSociety')}</h2>
                }
                <div className={isVersionMobile ? '' : contentContainer}>
                    <SocialShare />
                    <SocialOtherShares />
                </div>
            </div>
        </div>
    )
}
