import axios from 'axios'
// helpers
import { ObjectSnakeCaseKeys, APICatchError, APIDomainHTTP, APIHeaders, APIVersion, CancelRequest, ObjectCamelCaseKeys } from '../helpers'
// types
import { APIBlogDetail } from '../interfaces'

export const GetBlogDetailAPI = async (path: string, categoryIds: number[]) => {

    const { get, CancelToken } = axios
    const url = `${APIDomainHTTP()}/${APIVersion()}/blog/detail`
    const headers = APIHeaders()

    const source = CancelToken.source()

    CancelRequest.add(
        'GetBlogDetail',
        () => source.cancel('GetBlogDetail: Operation cancelled by user'),
    )

    try {
        const res: {
            status: number;
            message: string;
            data: {
                results: APIBlogDetail;
            }
        } = await get(url, {
            params: ObjectSnakeCaseKeys({ path, categoryIds: JSON.stringify(categoryIds) }),
            headers,
            cancelToken: source.token,
        })

        if (res.status !== 200) {
            throw new Error(APICatchError(res.message))
        }

        const { data } = ObjectCamelCaseKeys(res)
        
        const { results } = data
        return results
    } catch (e) {
        throw new Error(APICatchError(e.message))
    }
} 
