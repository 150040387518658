import _ from 'lodash'
import React, { useEffect, useState } from 'react'
// libraries
import { useQuery } from 'react-query'
// apis
import { GetArticlePagesAPI } from '../../apis'
// helpers
import { ErrorAlert } from '../../helpers'
// types
import { APIArticlePages } from '../../interfaces'
// view
import { PageIndexView } from './page-index-view'

export const PageIndexController = (props) => {

    const {
        onChangePage,
    } = props

    const [selectedPageIndex, setSelectedPageIndex] = useState<number>(1)
    const [pages, setPages] = useState<(number | null)[]>([])

    const { error, data } = useQuery<APIArticlePages, { message: string }>(['articlePages', selectedPageIndex], GetArticlePagesAPI)

    useEffect(() => {
        window.scrollTo(0, 0)

        if (error !== null) {
            return ErrorAlert({ text1: error.message })
        }

        const totalPages = data?.pages

        if (!totalPages) return
        let newPages: (number | null)[] = []
        newPages.push(-1)

        _.range(1, totalPages + 1).forEach((page: number) => {
            if (page < 5 || page > totalPages - 1 || page === selectedPageIndex) {
                newPages.push(page)
            } else if (page + 1 === selectedPageIndex || page - 1 === selectedPageIndex || (selectedPageIndex < 5 && page === 5)) {
                newPages.push(null)
            }
        })

        newPages.push(-2)
        setPages(newPages)

    }, [error, data])

    const onClickPageIndex = (page: number) => {
        if (page !== selectedPageIndex){
            setSelectedPageIndex(page)
            onChangePage(page)
        }
    }

    const onClickEtc = () => {
        const targetPage = Number(prompt(t('screens.normalText.pleaseEnterPageNumber')))
        if (targetPage &&
            !isNaN(targetPage) &&
            targetPage > 0 &&
            targetPage < pages[pages.length - 2]! + 1 &&
            targetPage !== selectedPageIndex
        ) {
            onClickPageIndex(targetPage)
        }
    }

    const ViewProps = {
        pages,
        selectedPageIndex,
        onClickPageIndex,
        onClickEtc,
    }

    return (
        <PageIndexView {...ViewProps} />
    )
}
