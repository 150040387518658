export const SecondsToClockTime = (seconds: number) => {
    const hours = ~~(seconds / 3600)
    const minutes = ~~((seconds % 3600) / 3600 * 60)

    const resolvedHours = `${addZero(hours)}`
    const resolvedMinutes = `${addZero(minutes)}`
    const resolvedSeconds = `${addZero(seconds % 60)}`

    return {
        hours: resolvedHours,
        minutes: resolvedMinutes,
        seconds: resolvedSeconds,
    }
}

const addZero = (number: number): string => {
    return number.toString().length === 1 ? `0${number}` : `${number}`
}