export default {
  "screens": {
    "normalText": {
      "hello": "吊你老味",
      "homePage": "首頁",
      "beginnerGuide": "新手教學",
      "tradePlatform": "交易平台",
      "interestGuide": "收息指南",
      "depositWithdraw": "出入金",
      "defi": "DEFI",
      "wallet": "信用卡",
      "creaditCard": "錢包",
      "allArticles": "所有文章",
      "cryptoOneGuide": "先求知  後投資  加密貨幣一站式指南",
      "cryptoOneGuideM1": "先求知  後投資",
      "cryptoOneGuideM2": "加密貨幣一站式指南",
      "focusOnSharingCryptoMarketInfo": "專注於分享加密貨幣市場資訊、實用工具、投資小知識",
      "focusOnSharingCryptoMarketInfoM1": "專注於分享加密貨幣市場資訊、",
      "focusOnSharingCryptoMarketInfoM2": "實用工具、投資小知識",
      "compareCreditCard": "信用卡比較",
      "compareTradingPlatform": "交易平台比較",
      "defiGuide": "DEFI指南",
      "depositWithdrawGuide": "出入金指南",
      "walletGuide": "錢包指南",
      "latestOffers": "最新優惠",
      "viewMore": "查看更多",
      "beginnerGuide2": "新手指引",
      "startLearningCrypto": "立即開始加密貨幣學習之路",
      "beginnerGuideBrief1": "什麼是加密貨幣與區塊鏈",
      "beginnerGuideDescription1": "加密貨幣就像是數位現金，區塊鏈則是去中心化帳本。",
      "beginnerGuideBrief2": "開始交易前的準備",
      "beginnerGuideDescription2": "你只需要創立一個交易平台的戶口即可以開始踏入加密貨幣入加密貨幣入加密貨幣世界。",
      "beginnerGuideBrief3": "儲存及使用加密貨幣",
      "beginnerGuideDescription3": "你只需要創立一個交易平台的戶口即可以開始踏入加密貨幣世界。",
      "beginnerGuideBrief4": "產生被動收入，邁向財務自由",
      "beginnerGuideDescription4": "你只需要創立一個交易平台的戶口即可以開始踏入加密貨幣世界。",
      "iWantToKnowMoreBasicKnowledge": "我想知道更多基礎知識",
      "latestNews": "最新文章",
      "interestingNewsInCoin": "幣圈趣聞",
      "aboutUs": "關於我們",
      "aboutUsDescription": "一些團隊成立理念，願景與廢話。",
      "followOurSociety": "關注我們的社群",
      "communityTelegram": "每日幣研 Crypto HK 交流群",
      "communityInstagram": "crypto_wesearch",
      "communityYoutube": "每日幣研 Crypto Wesearch",
      "youtubeFollowers": "{{people}} 位訂閱者",
      "telegramFollowers": "{{people}} 會員",
      "getLatestMarketNewsFirst": "搶先收到最新市場資訊",
      "weeklyMarketNews": "每週市場資訊(中文)",
      "analysisOfHPCoins": "優質高潛力貨幣分析",
      "particalInvestmentTools": "實用的投資工具",
      "joinThroughTelegram": "加入 Telegram",
      "basicKnowledge": "基礎知識",
      "particalTools": "實用工具",
      "dailyComsumption": "日常消費",
      "tradingExperience": "交易心得",
      "landingApplication": "落地應用",
      "coinPriceTrend": "幣價走勢",
      "about": "關於",
      "news": "文章",
      "comparison": "比較",
      "coinTypeComparison": "幣種比較",
      "exchangeComparison": "交易所比較",
      "walletComparison": "錢包比較",
      "interestComparison": "收息比較",
      "disclaimer": "Disclaimer: NerdWallet strives to keep its information accurate and up to date. This information may be different than what you see when you visit a financial institution, service provider or specific product’s site. All financial products, shopping products and services are presented without warranty. When evaluating offers, please review the financial institution’s Terms and Conditions.",
      "copyRight": "© 2021 Crypto Wesearch 版權所有",
      "guide": "指南",
      "coinTypeAnalysis": "幣種分析",
      "promotionSales": "推廣優惠",
      "article": "文章",
      "articleTag": "文章標籤",
      "readingTime": "閱讀時間",
      "minutes": "{{minutes}}分鐘",
      "apply": "套用",
      "clear": "清除",
      "shareTo": "分享至",
      "aboutTheAuthor": "關於作者",
      "relatedArticles": "相關文章",
      "moreFeatures": "更多特點",
      "debitCreditChannel": "出入金渠道",
      "coinTypeQuantity": "幣種數量",
      "tradingFee": "交易手續費",
      "applyImmediately": "立即申請",
      "analyzeDetaily": "詳細分析",
      "timeRemain": "剩餘",
      "hour": "小時",
      "minute": "分鐘",
      "second": "秒",
      "tableOfContents": "內容目錄",
      "search": "搜尋",
      "pleaseSearchWithOtherTerms": "請檢查或使用其他關鍵字",
      "resultNotFound": "沒有搜尋結果",
      "404NotFound1": "找不到相關頁面",
      "404NotFound2": "頁面可能已移除、名稱已變更 或暫時無法使用",
      "askChingThroughTg": "加入Telegram，問問其他Ching",
      "filter": "篩選",
      "articleClassification": "文章分類",
      "cancel": "取消",
      "pleaseEnterPageNumber": "請輸入頁數:",
      "readingTimeInMinutes": "{{readingTime}} 分鐘閱讀",
      "specialOfferDescription1": "獨家優惠碼申請可享有永久交易折扣",
      "specialOfferDescription2": "有永久交易折",
      "off": "OFF",
    }
  }
}
