import { makeStyles } from '@material-ui/core/styles'
// theme
import { Fonts, Colors, Layouts } from '../theme'
// styles
import { BeginnerGuideItemHeight, BeginnerGuideItemIconSize } from './beginner-guide-styles' 

const mouseOverTableOfContents = {
    '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
    }
}

const defaultLabelContainer = (color: string) => {
    return {
        ...Layouts.rowWithAllCenter,
        marginRight: 8,
        marginTop: 12,
        padding: `8px 12px`,
        borderRadius: 20,
        backgroundColor: color,
    }
}

const contentTextFixed = (color: string) => {
    return {
        ...Fonts.fontMedium400,
        ...mouseOverTableOfContents,
        marginBottom: 12,
        color,
    }
}

export const BlogStyles = makeStyles({
    appBarContainerWrapperM: {
        position: 'fixed', 
        left: 0, 
        top: 0,  
        zIndex: 1,
    },
    appBarContainerM: {
        ...Layouts.columnWithAllCenter as {},
        width: window.innerWidth,
        backgroundColor: Colors.blue1,
    },
    appBarInnerContainerM: {
        ...Layouts.columnWithAllCenter as {},
        width: window.innerWidth,
        borderBottom: Layouts.borderLine(Colors.grey1)
    },
    appBarInnerInnerContainerM: {
        ...Layouts.rowWithJCSpaceBetweenAIStart,
        display: 'flex',
        width: 375 - 48,
        height: '100%',
    },
    currentContentContainerM: {
        width: 375 - 48 - 80,
        overflow: 'hidden',
    },
    currentContentTextM: {
        ...Fonts.fontMedium700,
        display: 'inline-block',
        color: Colors.blue2,
        margin: 0,
        lineHeight: 0,
    },
    iconsContainerM: {
        ...Layouts.rowWithAllCenter,
        width: 64,
    },
    background: {
        ...Layouts.defualtBackgroundStyle,
    },
    container: {
        ...Layouts.defaultContainerStyle,
    },
    containerM: {
        ...Layouts.M.defaultContainerStyle,
    },
    innerContainer: {
        padding: `0 192px`
    },
    innerContainerM: {
        padding: `0 24px`
    },
    navigationTitlesContainer: {
        ...Layouts.rowWithJCStart,
        marginTop: 40,
        marginBottom: 16,
    },
    navigationTitlesContainerM: {
        ...Layouts.rowWithWrap as {},
        marginTop: 24,
        marginBottom: 4,
    },
    navigationTitleContainer: {
        ...Layouts.rowWithAllCenter,
    },
    navigationTitleText: {
        ...Fonts.fontMediumBig400,
    },
    navigationTitleTextM: {
        ...Fonts.fontSmall400,
    },
    navigationNormalTitleText: {
        color: Colors.greyBlack85,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    navigationLastTitleText: {
        color: Colors.greyBlack6,
    },
    labelsContainer: {
        ...Layouts.rowWithJCStart, 
    },
    labelsContainerM: {
        ...Layouts.rowWithJCStart, 
    },
    headerTitleText: {
        ...Fonts.font2XBig700
    },
    headerTitleTextM: {
        ...Fonts.fontBig700
    },
    authorBriefShareContainer: {
        ...Layouts.rowWithJCSpaceBetween,
    },
    authorBriefContainer: {
        ...Layouts.rowWithJCStart,
    },
    iconAuthorContainerWrapper: {
        height: 48,
        width: 48,
        borderRadius: 24,
        backgroundColor: Colors.grey1,
        marginRight: 8,
    },
    authorNameText: {
        ...Fonts.fontMedium700,
        margin: 0,
        marginBottom: 4,
        color: Colors.greyBlack85,
    },
    authorNameTextM: {
        ...Fonts.fontMedium400,
        margin: 0,
        marginBottom: 4,
        color: Colors.blue2,
    },
    authorNameReadingTimeContainer: {
    },
    readingTimeText: {
        ...Fonts.fontMedium400,
        color: Colors.greyBlack6,
    },
    shareContainer: {
        ...Layouts.rowWithJCSpaceBetween,
    },
    shareToPlainText: {
        ...Fonts.fontMedium400,
        color: Colors.greyBlack85,
    },
    pictureContainer: { 
        height: 624,
        margin: `24px 96px`,
        borderRadius: 4,
        backgroundColor: Colors.grey1,
    },
    pictureContainerM: {
        height: 250,
        margin: `24px 0`,
        backgroundColor: Colors.grey1,
    },
    aboutTheAuthorPlainText: {
        ...Fonts.fontXBig700, 
        color: Colors.greyBlack85,
    },
    authorNameIntroductionContainer: {

    },
    authorNameBigText: {
        ...Fonts.fontXMediumBig700,
        margin: 0,
        marginBottom: 8,
        color: Colors.greyBlack85,
    },
    introductionText: {
        ...Fonts.fontMedium400, 
        color: Colors.greyBlack6,
    },
    backendHTMLBottom: {
        paddingBottom: 52,
        borderBottom: Layouts.borderLine(Colors.grey1, 1)
    },
    backendHTMLContainerM: {
        padding: `24px 24px 0 24px`
    },
    relatedArticlesPlainText: {
        ...Fonts.fontXBig700, 
        margin: 0,
        marginTop: 24,
        color: Colors.greyBlack85,
    },
    relatedArticlesPlainTextM: {
        ...Fonts.fontBig700, 
        margin: `0 24px`,
        marginTop: 24,
        color: Colors.greyBlack85,
    },
    relatedArticlesContainer: {
        ...Layouts.rowWithJCStart,
        marginBottom: 48,
    },
    tableOfContentsContainer: {
        padding: 24,
        borderRadius: 8,
        backgroundColor: Colors.grey0,
    },
    tableOfContentsPlainText: {
        ...Fonts.fontMediumBig700,
        color: Colors.greyBlack85,
        margin: 0,
    },
    contentTextContainer: {
        paddingInlineStart: 24
    },
    contentTextContainerM: {
       margin: `0 18px`,
       width: 375 - 48,
    },
    subContentTextContainer: {
        paddingInlineStart: 24
    },
    contentText: {
        ...Fonts.fontMedium400,
        ...mouseOverTableOfContents,
        marginBottom: 12,
    },
    subContentText: {
        ...Fonts.fontMedium400,
        ...mouseOverTableOfContents,
        marginBottom: 8,
        marginTop: -80,
        paddingTop: 80,
    },
    subContentLastText: {
        marginBottom: 12,
    },
    leftContentsContainer: {
        position: 'absolute',
        width: 144,
        // left: 200,
        padding: 12,
        backgroundColor: Colors.white,
    },
    leftContentsContainerFixed: {
        position: 'fixed',
        width: 144,
        // left: 200,
        padding: 12,
        backgroundColor: Colors.white,
        zIndex: 99,
    },
    tableOfContentsPlainTextFixed: {
        ...Fonts.fontMedium400,
        paddingBottom: 12,
        borderBottom: Layouts.borderLine(Colors.greyBlack1),
    },
    contentTextContainerFixed: {
        paddingInlineStart: 16,
    },
    contentTextFixed: {
        ...contentTextFixed(Colors.greyBlack6),
    },
    contentTextFixedFocused: {
        ...contentTextFixed(Colors.blue2),
    },
    scrollingWrapperM: {
        width: Layouts.backgroundWidth,
        overflowX: 'scroll',
        overflowY: 'hidden',
        display: 'flex',
        marginBottom: 24,
    },
    contentShareContainerM: {
        ...Layouts.rowWithJCStart,
        width: Layouts.backgroundWidth,
        margin: `0 -18px`,
        padding: `6px 40px 8px 40px`,
        borderTop: Layouts.borderLine(Colors.greyBlack1),
    },
    labelContainer: {
        ...defaultLabelContainer(Colors.blue5),
    },
    labelText: {
        ...Fonts.fontSmall400,
        color: Colors.blue2,
    },
    labelContainerM: {
        ...defaultLabelContainer(Colors.blue5),
        padding: `4px 8px`,
    },
    itemContainer: {
        marginRight: 24,
    },
    loadingContainer: {
        ...Layouts.rowWithAllCenter,
        height: 692,
    },
    loadingContainerM: {
        ...Layouts.rowWithAllCenter,
        height: 300,
    },
    discountBannerContainer: {
        ...Layouts.rowWithAllCenter,
    },
})
