import _ from 'lodash'
import React, { Fragment } from 'react'
// styles
import { DrawerStyles } from '../../styles'
import '../../css/drawer.css'
// libraries
import Drawer from '@material-ui/core/Drawer'
// types
import { DrawerPosition } from '../../interfaces'

export const DrawerView = (props) => {

    const {
        renderComponent,
        isOpened,
        position,
        onClose,
    } = props

    const styles = DrawerStyles()

    const state = {
        [position]: isOpened
    }

    return (
        <div>
            {(['left', 'right', 'top', 'bottom'] as DrawerPosition[]).map((p: DrawerPosition) => {
                return (
                    <Fragment key={p}>
                        <Drawer anchor={p} open={state[p]} onClose={onClose}>
                            <div className={styles[`container${_.upperFirst(position)}`]}>
                                {renderComponent()}
                            </div>
                        </Drawer>
                    </Fragment>
                )
            })}
        </div>
    )
}
