import axios from 'axios'
// helpers
import { CancelRequest, APICatchError, ObjectSnakeCaseKeys, APIHeaders, APIDomainHTTP, APIVersion } from '../helpers'
import { RemoveUserToken} from '../helpers/local-storage'

export const DeleteCoinAPI = async (body: {
    coinId : any,
}) => {

    const { post, CancelToken } = axios
    const url = `${APIDomainHTTP()}/${APIVersion()}/coin/delete/`+body.coinId
    const headers = APIHeaders()

    const source = CancelToken.source()

    CancelRequest.add(
        'DeleteCoinAPI',
        () => source.cancel('DeleteCoinAPI: Operation cancelled by user'),
    )

    try {
        const res: {
            status: number;
            message: string;
        } = await post(url, ObjectSnakeCaseKeys(body), {
            headers,
            cancelToken: source.token,
        })
        console.log(res.status);
        if (res.status !== 200) {
            return APICatchError(res.message)
        }
        
        return res
    } catch (e) {
        if (e.message === 'Request failed with status code 403'){
            RemoveUserToken();
        }
        return APICatchError(e.message)
    }
} 
