import React from 'react'
// libraries
import { BrowserRouter, Switch } from 'react-router-dom'
// routes
import { InnerRoute } from './inner-route'

export const Route = () => {

    return (
        <BrowserRouter>
            <Switch>
                <InnerRoute />
            </Switch>
        </BrowserRouter>
    )
}
