import { makeStyles } from '@material-ui/core/styles'
// theme
import { Fonts, Colors, Layouts } from '../theme'

const containerWrapperWidth = 360

export const FollowOurSocietyStyles = makeStyles({
    background: {
        ...Layouts.defualtBackgroundStyle,
        borderBottom: Layouts.borderLine(Colors.blue5)
    },
    container: {
        ...Layouts.defaultContainerStyle,
        paddingTop: 32,
        paddingBottom: 80,
    },
    containerM: {
        ...Layouts.M.defaultContainerStyle,
    },
    headerText: {
        ...Fonts.fontXBig700,
    },
    headerTextM: {
        ...Fonts.fontBig700,
        margin: 0,
        marginTop: 48,
        marginLeft: 24,
        color: Colors.greyBlack85,
    },
    contentContainer: {
        ...Layouts.rowWithJCSpaceBetween,
        marginTop: 24,
        height: 232,
    },
    socialShareContainer: {
        ...Layouts.columnWithAIStart as {},
        height: '100%',
        width: containerWrapperWidth,
        borderRadius: 8, 
        backgroundColor: Colors.grey0,
    },
    socialShareContainerM: {
        width: '100%',
    },
    communityContainer: {
        ...Layouts.rowWithJCSpaceBetween as {},
        margin: `24px 16px`,
    },
    communityInnerContainer: {
        ...Layouts.rowWithJCStart as {},
    },
    communityTextContainer: {
        marginLeft: 8,
    },
    communityText: {
        ...Fonts.fontMedium700,
        margin: `0 0`,
    },
    communitySubText: {
        ...Fonts.fontSmall400,
        color: Colors.greyBlack6,
    },
    socialOtherShareContainerWrapper: {
        ...Layouts.rowWithJCSpaceBetween as {},
        flex: Layouts.contentWidth - containerWrapperWidth,
        marginLeft: 24,
        height: '100%',
    },
    socialOtherShareContainerWrapperM: {
        ...Layouts.rowWithJCSpaceBetween as {},
        margin: `0 24px`,
        height: 156,
        paddingTop: 12,
        paddingBottom: 76,
    },
    socialOtherShareContainer: {
        height: '100%',
        width: 232,
        border: Layouts.borderLine(Colors.grey0, 2)
    },
    socialOtherShareContainerM: {
        height: 156,
        width: 155,
        border: Layouts.borderLine(Colors.grey0, 2)
    }
})
