import { makeStyles } from '@material-ui/core/styles';
import { Box,Grid,Typography,Snackbar,FormLabel,IconButton,Button,TextField,List,ListItem,ListSubheader,ListItemText,ListItemSecondaryAction, Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { useState, useEffect} from 'react';
import {UseDataFetching, UploadDataForCreateTagAPI,DeleteTagAPI,DeleteCategoryAPI, UploadDataForCreateCategoryAPI,GetTagsListAPI, GetCategoryListAPI, UploadDataForEditTagAPI, UploadDataForEditCategoryAPI } from '../../apis';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CategoryTagsStyle = makeStyles((theme) => ({
    body:{
        marginLeft:8,
        marginRight:15,
    },
    group:{
        backgroundColor: 'white',
        padding:15,
        maxWidth: 460,
        borderRadius:4,
        border:1,
        borderStyle:"solid",
        borderColor:"#bdbdbd",
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalContent:{
        minWidth: 400,
        padding:15,
        backgroundColor: 'white',
        borderRadius:4,
        border:1,
    },
    submitButton:{
        marginTop: 10,
    },
    text:{
        width:'100%',
    },
    title:{

    },
    root: {
        borderRadius:4,
        border:1,
        borderStyle:"solid",
        borderColor:"#bdbdbd",
        width: '100%',
        maxWidth: 490,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 400,
      },
}));

export const AdminCategoryTags = () => {
    const classes = CategoryTagsStyle();
    const [tagRows ,setTagRows ] = useState([]);
    const [categoryRows ,setCategoryRows ] = useState([]);
    const [postSuccess, setPostSuccess] = useState(false);
    const [categoryCNError, setCategoryCNError] = useState(false);
    const [categoryCNErrorMessage, setCategoryCNErrorMessage] = useState('');
    const [categoryENError, setCategoryENError] = useState(false);
    const [categoryENErrorMessage, setCategoryENErrorMessage] = useState('');
    const [editCategoryCNError, setEditCategoryCNError] = useState(false);
    const [editCategoryENError, setEditCategoryENError] = useState(false);
    const [editCategoryPathError, setEditCategoryPathError] = useState(false);
    const [editTagPathError, setEditTagPathError] = useState(false);
    const [editTagCNError, setEditTagCNError] = useState(false);
    const [editTagENError, setEditTagENError] = useState(false);
    const [tagCNError, setTagCNError] = useState(false);
    const [tagCNErrorMessage, setTagCNErrorMessage] = useState('');
    const [tagENError, setTagENError] = useState(false);
    const [tagENErrorMessage, setTagENErrorMessage] = useState('');
    const [tagCn, setTagCn] = useState('');
    const [tagEn, setTagEn] = useState('');
    const [categoryCn, setCategoryCn] = useState('');
    const [categoryEn, setCategoryEn] = useState('');
    const [editCategoryCn, setEditCategoryCn] = useState('');
    const [editCategoryId, setEditCategoryId] = useState(0);
    const [editTagId, setEditTagId] = useState(0);
    const [editTagCn, setEditTagCn] = useState('');
    const [categoryPath, setCategoryPath] = useState('');
    const [categoryPathError, setCategoryPathError] = useState(false);
    const [categoryPathErrorMessage, setCategoryPathErrorMessage] = useState('');
    const [tagPath, setTagPath] = useState('');
    const [tagPathError, setTagPathError] = useState(false);
    const [editHeaderElementsError, setEditHeaderElementsError] = useState(false);
    const [tagPathErrorErrorMessage, setTagPathErrorErrorMessage] = useState('');
    const [editCategoryEn, setEditCategoryEn] = useState('');
    const [editTagEn, setEditTagEn] = useState('');
    const [editTagPath, setEditTagPath] = useState('');
    const [editCategoryPath, setEditCategoryPath] = useState('');
    const [categoryopen, setCategoryOpen] = useState(false);
    const [tagOpen, setTagOpen] = useState(false);
    const [tagLoading, setTagLoading] = useState(false);
    const [categoryLoading, setCategoryLoading] = useState(false);
    const [editHeaderElements,setEditHeaderElements] =  useState('');
    const [headerElements,setHeaderElements] =  useState('');
    const [headerElementsError,setHeaderElementsError] =  useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectObject, setSelectObject] = useState('');
    const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setPostSuccess(false);
        setCategoryOpen(false);
        setTagOpen(false);
        setDialogOpen(false);
    };

    const GetTagsData =  async()=> {
        var list:any =[];
        const  {error,results} =  await UseDataFetching(GetTagsListAPI({})) as any;
        (results['tags']||[]).map(function(result:any){
           list.push({'id':result.tag_id,'tag':result.tag_cn + ' / '+ result.tag_en,'tag_cn':result.tag_cn,'tag_en':result.tag_en,'tag_path':result.tag_path });
        })
        setTagRows(list);
    }

    const GetCategoryData =  async()=> {
        var list:any =[];
        const  {error,results} =  await UseDataFetching(GetCategoryListAPI({})) as any;
        (results['category']||[]).map(function(result:any){
           list.push({'id':result.category_id,'category':result.category_cn + ' / '+ result.category_en ,'category_cn':result.category_cn,'category_en':result.category_en,'category_path':result.category_path,'header_element':result.header_element});
        })
        setCategoryRows(list);
    }
    const CreateTag = async() => {
        if (tagCn === ""){
            setTagENError(false)
            setTagENErrorMessage("")
            setTagCNError(true)
            setTagCNErrorMessage("can not empty")
            return
        }
        if (tagEn === ""){
            setTagCNError(false)
            setTagCNErrorMessage("")
            setTagENError(true)
            setTagENErrorMessage("can not empty")
            return
        }
        setTagLoading(true);
        const { error , results } = await UseDataFetching(UploadDataForCreateTagAPI({tagCn:tagCn,
            tagPath:tagPath,
            tagEn:tagEn})) as any
        switch (results['code']) {
             case 200:
                setTagCNError(false)
                setTagENError(false)
                setTagCNErrorMessage("")
                setTagENErrorMessage("")
                setPostSuccess(true);
                GetTagsData();
                break
            case 2002:
                if (results['tag']==="cn"){
                    setTagENError(false)
                    setTagENErrorMessage("")
                    setTagCNError(true)
                    setTagCNErrorMessage(results['message'])
                }else{
                    setTagCNError(false)
                    setTagCNErrorMessage("")
                    setTagENError(true)
                    setTagENErrorMessage(results['message'])
                }
                break   
            default:
                console.log(error);
                break;
        }
        setTagLoading(false);
    };
    
    const CreateCategory = async() => {
        if (categoryCn === ""){
            setCategoryPathError(false)
            setCategoryENError(false)
            setCategoryENErrorMessage("")
            setCategoryCNError(true)
            setCategoryCNErrorMessage("can not empty")
            return
        }
        if (categoryEn === ""){
            setCategoryPathError(false)
            setCategoryCNError(false)
            setCategoryCNErrorMessage("")
            setCategoryENError(true)
            setCategoryENErrorMessage("can not empty")
            return
        }
        if (categoryPath === ""){
            setCategoryPathError(true)
            setCategoryPathErrorMessage("can not empty")
            return
        }
        setCategoryLoading(true);
        const { error , results } = await UseDataFetching(UploadDataForCreateCategoryAPI({categoryCn:categoryCn,
            categoryPath:categoryPath,
            headerElement:headerElements,
            categoryEn:categoryEn})) as any
        switch (results['code']) {
            case 200:
                setCategoryENError(false)
                setCategoryCNError(false)
                setCategoryENErrorMessage("")
                setCategoryCNErrorMessage("")
                setPostSuccess(true);
                GetCategoryData();
                break
            case 2001:
                if (results['category']=="cn"){
                    setCategoryENError(false)
                    setCategoryENErrorMessage("")
                    setCategoryCNError(true)
                    setCategoryCNErrorMessage(results['message'])
                }else{
                    setCategoryCNError(false)
                    setCategoryCNErrorMessage("")
                    setCategoryENError(true)
                    setCategoryENErrorMessage(results['message'])
                }
                break 
            default:
                console.log(error);
                break;
        }
        setCategoryLoading(false);
    };

   
    const ClickDeleteTagButton = (tagId:number) => {
        setDialogOpen(true);
        setEditTagId(tagId);
        setSelectObject('tag');
    };
    const DeleteButton = async() => {
        if (selectObject === 'tag'){
            const  {error,results} =  await UseDataFetching(DeleteTagAPI({tagId:editTagId})) as any;
            GetTagsData();
            setPostSuccess(true);
        }else if (selectObject === 'category'){
            const  {error,results} =  await UseDataFetching(DeleteCategoryAPI({categoryId:editCategoryId})) as any;
            GetCategoryData();
            setPostSuccess(true);
        }
    }

    const ClickDeleteCategoryButton = (categoryId:number) => {
        setDialogOpen(true);
        setEditCategoryId(categoryId);
        setSelectObject('category');
        // const  {error,results} =  await UseDataFetching(DeleteCategoryAPI({categoryId:categoryId})) as any;
        // GetCategoryData();
        // setPostSuccess(true);
    };
    
    const ClickEditTagButton = (tagCn:string,tagEn:string,tagId:number,tagPath:string) => {
        setEditTagCn(tagCn);
        setEditTagEn(tagEn);
        setEditTagId(tagId);
        setEditTagPath(tagPath);
        setEditTagPathError(false)
        setEditTagENError(false)
        setEditTagCNError(false)
        setTagOpen(true);
    };

    const ClickEditCategoryButton = (categoryCn:string,categoryEn:string,categoryId:number,categoryPath:string,headerElement:string) => {
        setEditCategoryCn(categoryCn);
        setEditCategoryEn(categoryEn);
        setEditCategoryPath(categoryPath);
        setEditCategoryId(categoryId);
        setEditHeaderElements(headerElement);
        setEditCategoryPathError(false)
        setEditCategoryENError(false)
        setEditCategoryCNError(false)
        setCategoryOpen(true);
    };

    const EditTag = async() => {
        if (editTagCn == ""){
            setEditTagPathError(false)
            setEditTagENError(false)
            setEditTagCNError(true)
            return
        }
        if (editTagEn == ""){
            setEditTagPathError(false)
            setEditTagCNError(false)
            setEditTagENError(true)
            return
        }
        if (editTagPath == ""){
            setEditTagCNError(false)
            setEditTagENError(false)
            setEditTagPathError(true)
            return
        }
        const  {error,results} =  await UseDataFetching(UploadDataForEditTagAPI({tagId:editTagId,
            tagCn:editTagCn,
            tagEn:editTagEn,
            tagPath:editTagPath})) as any;
        
        switch (results['code']) {
            case 200:
                setPostSuccess(true);
                setTagOpen(false);
                GetTagsData();
                break
            case 2001:
                if (results['tag']=="cn"){
                    setEditTagENError(false)
                    setEditTagCNError(true)
                }else{
                    setEditTagCNError(false)
                    setEditTagENError(true)
                }
                break 
            default:
                console.log(error);
                break;
        }

    };

    const EditCategory = async() => {
        if (editCategoryCn === ""){
            setEditCategoryPathError(false)
            setEditCategoryENError(false)
            setEditCategoryCNError(true)
            return
        }
        if (editCategoryEn === ""){
            setEditCategoryPathError(false)
            setEditCategoryCNError(false)
            setEditCategoryENError(true)
            return
        }
        if (editCategoryPath === ""){
            setEditCategoryCNError(false)
            setEditCategoryENError(false)
            setEditCategoryPathError(true)
            return
        }
        const  {error,results} =  await UseDataFetching(UploadDataForEditCategoryAPI({categoryId:editCategoryId,
            categoryCn:editCategoryCn,
            headerElement:editHeaderElements,
            categoryEn:editCategoryEn,categoryPath:editCategoryPath})) as any;
        
        switch (results['code']) {
            case 200:
                setPostSuccess(true);
                setCategoryOpen(false);
                GetCategoryData();
                break
            case 2001:
                if (results['category']=="cn"){
                    setEditCategoryENError(false)
                    setEditCategoryCNError(true)
                }else{
                    setEditCategoryCNError(false)
                    setEditCategoryENError(true)
                }
                break 
            default:
                console.log(error);
                break;
            }
    
    };

    useEffect(() =>{
        GetTagsData();
        GetCategoryData();
    }, []);

    return <div className={classes.body}>
        <Grid container spacing={3} >
            <Grid item xs={12} md={6} >
                <Typography   variant="h6" className={classes.title}>
                    <Box fontStyle="normal">
                        分類
                    </Box> 
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography variant="h6" className={classes.title}>
                    <Box fontStyle="normal">
                    標籤
                    </Box> 
                </Typography>
            </Grid>
        </Grid>
        <Grid container spacing={3} >
            <Grid item xs={12} md={6}>
                <div className={classes.group}>
                    <div>
                        <div className={classes.title}>
                            <FormLabel component="legend">名稱 CN</FormLabel>
                        </div>
                        <TextField className={classes.text} 
                            id="categoryCN" 
                            onChange={event => setCategoryCn(event.target.value)}
                            error={categoryCNError}
                            helperText={categoryCNErrorMessage}
                            variant="outlined"/>
                    </div>
                    <div style={{marginTop:10}}>
                    <div className={classes.title}>
                        <FormLabel component="legend">名稱 EN</FormLabel>
                    </div>
                        <TextField className={classes.text} 
                                id="categoryEN" 
                                onChange={event => setCategoryEn(event.target.value)}
                                error={categoryENError}
                                helperText={categoryENErrorMessage}
                                variant="outlined"/>
                    <div style={{marginTop:10}} className={classes.title}>
                        <FormLabel component="legend">Path</FormLabel>
                    </div>
                        <TextField className={classes.text} 
                                id="categoryPath" 
                                onChange={event => setCategoryPath(event.target.value)}
                                error={categoryPathError}
                                helperText={categoryPathErrorMessage}
                                variant="outlined"/>
                    </div>   
                    <div className={classes.title} style={{marginTop:10}}>
                         <FormLabel component="legend">Header elements</FormLabel>
                    </div>    
                    <TextField className={classes.text} 
                        id="headerElements" 
                        multiline
                        error={headerElementsError}
                        onChange={event => setHeaderElements(event.target.value)}
                        //helperText={tagCNErrorMessage}
                        value={headerElements}
                        variant="outlined"/>       
                    <Button variant="contained" onClick={CreateCategory} color="primary" className={classes.submitButton}>
                        Create
                    </Button>
                    {categoryLoading && <CircularProgress  size={20} style={{marginLeft:10}} />}
                    
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <div className={classes.group}>
                    <div>
                        <div className={classes.title}>
                            <FormLabel component="legend">名稱 CN</FormLabel>
                        </div>
                        <TextField className={classes.text} 
                                id="tagCN" 
                                error={tagCNError}
                                onChange={event => setTagCn(event.target.value)}
                                helperText={tagCNErrorMessage}
                                variant="outlined"/>
                    </div>
                    <div style={{marginTop:10}}>
                    <div className={classes.title}>
                        <FormLabel component="legend">名稱 EN</FormLabel>
                    </div>
                    <TextField className={classes.text} 
                                id="tagEN" 
                                error={tagENError}
                                onChange={event => setTagEn(event.target.value)}
                                helperText={tagENErrorMessage}
                                variant="outlined"/>
                    <div style={{marginTop:10}} className={classes.title}>
                        <FormLabel component="legend">Path</FormLabel>
                    </div>
                        <TextField className={classes.text} 
                                id="tagPath" 
                                onChange={event => setTagPath(event.target.value)}
                                error={tagPathError}
                                helperText={tagPathErrorErrorMessage}
                                variant="outlined"/>
                    </div>                
                    <Button variant="contained" onClick={CreateTag} color="primary" className={classes.submitButton}>
                        Create
                    </Button>
                    {tagLoading && <CircularProgress  size={20} style={{marginLeft:10}} />}
                </div>
            </Grid>
        </Grid>
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <List className={classes.root}  subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        分類
                    </ListSubheader>
                }>
                {categoryRows.map((row:any) => {
                    return (
                        <ListItem  key= {row.id} button>
                             <ListItemText
                                id = {row.id}
                                primary={row.category}
                            />
                            <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="edit" onClick={() => ClickEditCategoryButton(row.category_cn,row.category_en,row.id,row.category_path,row.header_element)}>
                                <CreateIcon />
                            </IconButton>
                            {(row.id!==51&&row.id!==53)? <IconButton edge="end" aria-label="delete" onClick={() => ClickDeleteCategoryButton(row.id)}>
                                <DeleteIcon />
                            </IconButton>:null}
                           
                        </ListItemSecondaryAction>
                        </ListItem>
                    );
                })}

                </List>
            </Grid>
            <Grid item xs={12} md={6}>
                <List className={classes.root}  subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        標籤
                    </ListSubheader>
                }>
                {tagRows.map((row:any) => {
                    return (
                        <ListItem  key= {row.id} button>
                            <ListItemText
                                id = {row.id}
                                primary={row.tag}
                            />
                            <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="edit" onClick={() => ClickEditTagButton(row.tag_cn,row.tag_en,row.id,row.tag_path)} >
                                <CreateIcon />
                            </IconButton>
                            <IconButton edge="end" aria-label="delete" onClick={() => ClickDeleteTagButton(row.id)}>
                                <DeleteIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                        </ListItem>
                    );
                })}
                </List>
            </Grid>
            

        </Grid>
        <Snackbar open={postSuccess} autoHideDuration={2000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
                Success!
            </Alert>
        </Snackbar>
        

        <Modal
            aria-labelledby="catgory-modal-title"
            aria-describedby="catgory-modal-description"
            className={classes.modal}
            open={categoryopen}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
        >
         <div className={classes.modalContent}>
             <div className={classes.title}>
                <FormLabel component="legend">名稱 CN</FormLabel>
            </div>
            <TextField className={classes.text} 
                id="editCatgoryCn" 
                error={editCategoryCNError}
                onChange={event => setEditCategoryCn(event.target.value)}
                value={editCategoryCn}
                //helperText={tagCNErrorMessage}
                variant="outlined"/>
             <div className={classes.title} style={{marginTop:10}}>
                <FormLabel component="legend">名稱 EN</FormLabel>
            </div>    
            <TextField className={classes.text} 
                id="editCatgoryEn" 
                error={editCategoryENError}
                onChange={event => setEditCategoryEn(event.target.value)}
                //helperText={tagCNErrorMessage}
                value={editCategoryEn}
                variant="outlined"/>
            <div className={classes.title} style={{marginTop:10}}>
                <FormLabel component="legend">Path</FormLabel>
            </div>    
            <TextField className={classes.text} 
                id="editCategoryPath" 
                error={editCategoryPathError}
                onChange={event => setEditCategoryPath(event.target.value)}
                //helperText={tagCNErrorMessage}
                value={editCategoryPath}
                variant="outlined"/>

            <div className={classes.title} style={{marginTop:10}}>
                <FormLabel component="legend">Header elements</FormLabel>
            </div>    
            <TextField className={classes.text} 
                multiline
                id="editHeaderElements" 
                error={editHeaderElementsError}
                onChange={event => setEditHeaderElements(event.target.value)}
                //helperText={tagCNErrorMessage}
                value={editHeaderElements}
                variant="outlined"/>    

            <Button variant="contained" onClick={() => EditCategory()}  color="primary" className={classes.submitButton}>
                Edit
            </Button>
          </div>
      </Modal>



      <Modal
            aria-labelledby="tag-modal-title"
            aria-describedby="tag-modal-description"
            className={classes.modal}
            open={tagOpen}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
        >
         <div className={classes.modalContent}>
             <div className={classes.title}>
                <FormLabel component="legend">名稱 CN</FormLabel>
            </div>
            <TextField className={classes.text} 
                id="editTagCn" 
                error={editTagCNError}
                onChange={event => setEditTagCn(event.target.value)}
                value={editTagCn}
                //helperText={tagCNErrorMessage}
                variant="outlined"/>
             <div className={classes.title} style={{marginTop:10}}>
                <FormLabel component="legend">名稱 EN</FormLabel>
            </div>    
            <TextField className={classes.text} 
                id="editTagEn" 
                error={editTagENError}
                onChange={event => setEditTagEn(event.target.value)}
                //helperText={tagCNErrorMessage}
                value={editTagEn}
                variant="outlined"/>
            <div className={classes.title} style={{marginTop:10}}>
                <FormLabel component="legend">Path</FormLabel>
            </div>    
            <TextField className={classes.text} 
                id="editTagPath" 
                error={editTagPathError}
                onChange={event => setEditTagPath(event.target.value)}
                //helperText={tagCNErrorMessage}
                value={editTagPath}
                variant="outlined"/>
            <Button variant="contained" onClick={() => EditTag()}  color="primary" className={classes.submitButton}>
                Edit
            </Button>
          </div>
      </Modal>


      <Dialog open={dialogOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Delete?</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={DeleteButton} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
}