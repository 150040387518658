import { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles,MuiThemeProvider, createMuiTheme  } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { UseDataFetching, UploadDataForLoginAPI } from '../../apis'
import { SetUserToken,GetUserToken} from '../../helpers/local-storage'
import { useHistory } from 'react-router-dom'
import logo from '../../theme/sprites/svgs/logo.svg'

const loginStyles = makeStyles((theme) => ({
    bg:{
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: '0px',
        padding: '0',
        border: '0',
        backgroundColor: '#f5f5f5',
    },
    paper: {
      marginTop: theme.spacing(14),
      display: 'flex',
      flexDirection: 'column',
    },
    logo: {
      flexDirection: 'column',
      display: 'flex',
      alignItems: 'center',
    },
    form: {
      width: '100%', 
      marginTop: theme.spacing(3),
    },
    checkbox: {
        alignItems: 'left',
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
}));


function SetCookies(name:any,value:any){
    var Days = 30;
    var exp = new Date();
    exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
    document.cookie = name + "=" + escape(value) + ";expires=" + exp.toUTCString();
};

function GetCookies(name:any){
    var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    if (arr = document.cookie.match(reg)) return unescape(arr[2]);
    else return null;
};

function DelCookie(name:any) {
    var exp = new Date();
    exp.setTime(exp.getTime() - 10000);
    var cval = GetCookies(name);
    if (cval != null) document.cookie = name + "=" + cval + ";expires=" + exp.toUTCString();
}


export const AdminLogin = () => {
    const classes = loginStyles();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [checked, setChecked] = useState(true);
    const [usernameError, setUsernameError] = useState(false);
    const [usernameErrorMessage, setUsernameErrorMessage] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
    let [usernameFocus] = useState(true);
    let [passwordFocus] = useState(false);
    var cookiesUsername = GetCookies('username')
    if (cookiesUsername!=null){
        usernameFocus = false
        passwordFocus = true
    }
    let history = useHistory();

    const login = async (e:any) => {
        if (username == ''){
            setPasswordError(false)
            setPasswordErrorMessage('')
            setUsernameError(true)
            setUsernameErrorMessage('Required')
            return
        }
        if (password == ''){
            setUsernameError(false)
            setUsernameErrorMessage('')
            setPasswordError(true)
            setPasswordErrorMessage('Required')
            return
        }
        if (checked == true ){
            SetCookies('username',username)
        } else{
            DelCookie('username')
        }

        e.preventDefault()
        const { error , results } = await UseDataFetching(UploadDataForLoginAPI({username: username, password: password})) as any
        switch (results['code']) {
            case 1002:
                setPasswordError(false)
                setPasswordErrorMessage('')
                setUsernameError(true)
                setUsernameErrorMessage(results['message'])
                break;
            case 1003:
                setUsernameError(false)
                setUsernameErrorMessage('')
                setPasswordError(true)
                setPasswordErrorMessage(results['message'])
                break;
             case 200:
                SetUserToken(results['token'])
                DelCookie("userId");
                DelCookie("nickname");
                DelCookie("userUuid");
                DelCookie("role");
                DelCookie('refresh');
                SetCookies("nickname",results['nickname'])
                SetCookies("userId",results['user_id'])  
                SetCookies("userUuid",results['user_uuid']) 
                SetCookies("role",results['role']) 
                if (results['role'] === "Admin"){
                    history.push("/admin-panel")
                    SetCookies("refresh","false");
                    if (GetCookies('refresh') !== 'true'){
                        SetCookies("refresh","true");
                        window.location.reload();
                    }
                }else{
                    history.push("/admin-panel/articles")
                }
               
                break
            default:
                setPasswordError(true)
                setPasswordErrorMessage('backend error')
                break;
        }        

    }  
    useEffect(() => {
        DelCookie('role');
        DelCookie('userId');
        DelCookie('nickname');
        DelCookie('userUuid');
        if (GetCookies('refresh') !== 'true'){
            window.location.reload();
            SetCookies("refresh","true");
        }
        if (cookiesUsername!=null){
            setUsername(cookiesUsername) 
        }
        if (GetUserToken() != null){
            history.push("/admin-panel")
        }

    }, [])
    const blueTheme = createMuiTheme({ palette: { primary: {main:'rgb(70,130,280)'} } })

    return (
        <div className={classes.bg}>
            
            <Container component="main" maxWidth="xs" >
                <div className={classes.paper}  >
                    {/* <Typography component="h1" variant="h4" >
                        <Box textAlign="center">
                            Crypto Wesearch
                        </Box>
                    </Typography> */}
                    <div className={classes.logo}>
                        <img src={logo} style={{ width: '69%'}}/>
                    </div>
                    <div className={classes.form}>
                        <TextField
                            error={usernameError}
                            helperText={usernameErrorMessage}
                            variant="outlined"
                            margin="normal"
                            autoFocus ={usernameFocus}
                            fullWidth
                            required
                            id="username"
                            onChange={event => setUsername(event.target.value)}
                            value={username}
                            label="Username"
                            name="username"
                            autoComplete="username"
                            
                        />
                        <TextField
                            error={passwordError}
                            helperText={passwordErrorMessage}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            required
                            autoFocus ={passwordFocus}
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            onChange={event => setPassword(event.target.value)}
                            autoComplete="current-password"
                        />
                        <FormControlLabel
                            control={<Checkbox  color="primary" />}
                            label="Remember me"
                            checked={checked}
                            id = "checkbox"
                            onChange={() => {
                                if(checked===false){
                                setChecked(true)
                                }else if (checked===true){
                                setChecked(false)
                                }
                            }}
                            className={classes.checkbox}
                        />
                        <MuiThemeProvider theme={blueTheme}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={login}
                            >
                                Login In
                            </Button>
                        </MuiThemeProvider>
                        
                    </div>

                </div>
                <Box mt={10}>
                <Typography variant="body2" color="textSecondary" align="center">
                    {'Copyright © '}
                    <Link color="inherit" href="https://Cryptowesearch.com/">
                    cryptowesearch
                    </Link>{' '}
                    {new Date().getFullYear()}
                    {'.'}
                </Typography>
                </Box>
            </Container>
        </div>
    );
    
}
