import _ from 'lodash'
import React, { useLayoutEffect, useState, useRef } from 'react'
// libraries
import { useParams, useLocation, useHistory } from 'react-router-dom'
// components
import { NotFound as ComponentNF } from '../../components'

export const NotFound = () => {

    const { path }: { path: string } = useParams()
    const location = useLocation()

    const currentLocationPath = useRef<string>('')
    const [is404, setIs404] = useState<boolean>(false)

    useLayoutEffect(() => {
        const locationPath = location?.pathname
        if (currentLocationPath.current !== locationPath){
            const locationSplit = locationPath.split('/')
            const locationSplitLength = locationSplit.length
            if (path === 'home' || path === 'search') {
                if (locationSplitLength > 3 && !_.isEmpty(locationSplit[3])) {
                    setIs404(true)
                } else {
                    setIs404(false)
                }
            } else if (path === 'blog') {
                if (!(locationSplitLength > 3 && !_.isEmpty(locationSplit[3]))) {
                    setIs404(true)
                } else {
                    setIs404(false)
                }
            } else {
                setIs404(true)
            }
            currentLocationPath.current = locationPath
        }
    })

    return (
        is404
            ? <ComponentNF is404 />
            : null
    )
}
