import { makeStyles } from '@material-ui/core/styles'
// theme
import { Fonts, Colors, Layouts } from '../theme'

export const PageIndexStyles = makeStyles({
    pageIndexsContainer: {
        ...Layouts.rowWithAllCenter,
        marginTop: 48,
        marginBottom: 60,
    },
    pageIndexsContainerM: {
        ...Layouts.rowWithAllCenter,
        marginTop: 48,
        marginBottom: 60,
    },
})
