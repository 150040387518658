export const Dictionary = (type: string) => {

    const navigationBarTitles = [
        'guide',
        'coinTypeAnalysis',
        'promotionSales',
        'allArticles',
        'searchIcon',
    ]

    const homePageButtonTitles = [
        'compareTradingPlatform',
        'interestGuide',
        'depositWithdrawGuide',
        'defiGuide',
        'compareCreditCard',
        'walletGuide',
    ]

    const shareCommunities = [
        'instagram',
        'youtube',
        'telegram'
    ]

    const latestMarketNewsBriefs = [
        'weeklyMarketNews',
        'analysisOfHPCoins',
        'particalInvestmentTools',
    ]

    const footerCategories = [
        'about',
        'news',
        'comparison',
    ]

    const footerCategoryAbout = [
        'coinTypeComparison',
        'exchangeComparison',
        'walletComparison',
        'interestComparison',
    ]

    const footerCategoryNews = [
        'basicKnowledge',
        'particalTools',
        'dailyComsumption',
        'tradingExperience',
        'landingApplication',
        'coinPriceTrend',
    ]

    const footerCategoryComparison = [
        'coinTypeComparison',
        'exchangeComparison',
        'walletComparison',
        'interestComparison',
    ]

    const footerShares = [
        'facebook',
        'instagram',
        'telegram',
        'youtube',
    ]

    const slideBarTimes = [
        1,
        5,
        10,
        15,
        20,
        25,
        99,
    ]

    const blogShares = [
        'whatsapp',
        'facebook',
        'mail',
    ]

    const coinAnalysisTypes = [
        'debitCreditChannel',
        'coinTypeQuantity',
        'tradingFee',
    ]

    const mobileMenuItems = [
        'beginnerGuide',
        'guide',
        'coinTypeAnalysis',
        'promotionSales',
        'allArticles',
    ]

    const navigationBarLinks = {
        allArticles: 'blog/all',
        promotionSales: 'blog/promotion',
        coinTypeAnalysis: 'blog/analysis',
        searchIcon: 'search',
    }

    const obj = {
        navigationBarTitles,
        homePageButtonTitles,
        shareCommunities,
        latestMarketNewsBriefs,
        footerCategories,
        footerCategoryAbout,
        footerCategoryNews,
        footerCategoryComparison,
        footerShares,
        slideBarTimes,
        blogShares,
        coinAnalysisTypes,
        mobileMenuItems,
        navigationBarLinks,
    }

    return obj[type]
}
