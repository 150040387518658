import _ from 'lodash'
import React from 'react'
// styles
import { HomepageHeaderStyles } from '../../styles'
import '../../css/homepage-header.css'
// theme
import { Sprites } from '../../theme'
// components
import { Button } from '../../components'
// helpers
import { Dictionary, CheckIsVersionMobile } from '../../helpers'

export const HomepageHeader = () => {

    const {
        background,
        backgroundM,
        container,
        conatinerM,
        titleText,
        titleContainerM,
        titleTextM,
        subTitleContainerM,
        subTitleText,
        subTitleTextM,
        buttonsContainer,
        buttonsContainerM,
        buttonInnerText,
        buttonInnerTextM,
    } = HomepageHeaderStyles()
    const isVersionMobile = CheckIsVersionMobile()

    const RenderButtons = () => {
        return (
            // Dictionary('homePageButtonTitles').map((title: string, index: number) => {
            //     return (
            //         <Button
            //             renderComponent={() => {
            //                 const resolvedTitle = _.upperFirst(title)
            //                 return (
            //                     <div>
            //                         <Sprites
            //                             icon={`Icon${resolvedTitle}`}
            //                             size={isVersionMobile ? 'iconHomePageHeaderButtonsM' : 'iconHomePageHeaderButtons'}
            //                             alt={`icon-${title}`}
            //                         />
            //                         <h5 className={isVersionMobile ? buttonInnerTextM : buttonInnerText}>{t(`screens.normalText.${title}`)}</h5>
            //                     </div>
            //                 )
            //             }}
            //             buttonContainerClass={isVersionMobile ? (index + 1) % 3 !== 0 ? 'homePageHeaderButtonContainerM16' : 'homePageHeaderButtonContainerM' : 'homePageHeaderButtonContainer'}
            //             onClick={() => null}
            //         />
            //     )
            // })
            null
        )
    }

    return (
        <div className={isVersionMobile ? backgroundM : background}>
            <div className={isVersionMobile ? conatinerM : container}>
                {isVersionMobile
                    ? <div className={titleContainerM}>
                        <h2 className={titleTextM}>{t('screens.normalText.cryptoOneGuideM1')}</h2>
                        <h2 className={titleTextM}>{t('screens.normalText.cryptoOneGuideM2')}</h2>
                    </div>
                    : <h1 className={titleText}>{t('screens.normalText.cryptoOneGuide')}</h1>
                }
                {isVersionMobile
                    ? <div className={subTitleContainerM}>
                        <div className={subTitleTextM}>{t('screens.normalText.focusOnSharingCryptoMarketInfoM1')}</div>
                        <div className={subTitleTextM}>{t('screens.normalText.focusOnSharingCryptoMarketInfoM2')}</div>
                    </div>
                    : <div className={isVersionMobile ? subTitleTextM : subTitleText}>{t('screens.normalText.focusOnSharingCryptoMarketInfo')}</div>
                }
                <div className={isVersionMobile ? buttonsContainerM : buttonsContainer}>
                    {RenderButtons()}
                </div>
            </div>
        </div>
    )
}
