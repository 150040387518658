import { makeStyles } from '@material-ui/core/styles'
import { Colors } from '../theme'

export const DrawerStyles = makeStyles({
    containerBottom: {
        height: 552,
    },
    containerLeft: {
        height: '100%',
        width: window.innerWidth,
        backgroundColor: Colors.blue1,
        paddingBottom: 100,
    },
})
