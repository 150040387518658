import { makeStyles } from '@material-ui/core/styles'
// theme
import { Fonts, Colors, Layouts } from '../theme'
// styles
import { BeginnerGuideItemHeight, BeginnerGuideItemIconSize } from './beginner-guide-styles' 

const container = {
    ...Layouts.rowWithAllCenter,
    borderRadius: 16,
    height: BeginnerGuideItemHeight,
}

const iconInnerContainer = {
    ...Layouts.rowWithAllCenter,
    height: BeginnerGuideItemIconSize,
    width: BeginnerGuideItemIconSize,
    borderRadius: BeginnerGuideItemIconSize / 2,
    backgroundColor: Colors.grey0,
}

export const BeginnerGuideItemStyles = makeStyles({
    container: {
        ...container,
        padding: `0 24px`,
    },
    containerM: {
        ...container,
    },
    containerHover: {
        ...Layouts.rowWithAllCenter,
        backgroundColor: Colors.blue1,
        borderRadius: 16,
        height: BeginnerGuideItemHeight,
        padding: `0 24px`,
    },
    iconContainer: {
        width: '20%',
    },
    iconInnerContainer: {
        ...iconInnerContainer,
        marginLeft: 24,
    },
    iconInnerContainerM: {
        ...iconInnerContainer,
    },
    iconInnerContainer794L: {
        ...Layouts.rowWithAllCenter,
        height: BeginnerGuideItemIconSize * 52 / BeginnerGuideItemIconSize,
        width: BeginnerGuideItemIconSize * 52 / BeginnerGuideItemIconSize,
        borderRadius: BeginnerGuideItemIconSize * 52 / BeginnerGuideItemIconSize / 2,
        backgroundColor: Colors.grey0,
    },
    contentContainer: {
        width: '80%',
        marginLeft: 24,
    },
    contentContainer794L: {
        width: '80%',
        marginLeft: 24,
        paddingBottom: 16,
    },
    titleContainer: {
        ...Layouts.rowWithAllCenter,
        justifyContent: 'flex-start',
    },
    titleInnerContainer: {
        ...Layouts.rowWithAllCenter,
        marginRight: 4,
        width: 19,
        height: 19,
        borderRadius: 10,
        backgroundColor: Colors.blue2,
    },
    titleInnerNumberText: {
        ...Fonts.fontSmall700,
        color: Colors.white,
        lineHeight: 0,
        paddingRight: 1,
    },
    titleText: {
        ...Fonts.fontMediumBig700,
        margin: '4px 0',
    },
    titleTextM: {
        ...Fonts.fontMedium700,
        margin: '4px 0',
    },
    titleText794L: {
        ...Fonts.fontMediumBig700,
        lineHeight: 0,
    },
    subTitleContaienrM: {
        ...Layouts.rowWithJCSpaceBetween,
    },
    subTitleText: {
        ...Fonts.fontMedium400,
        color: Colors.greyBlack6,
    },
    subTitleTextM: {
        ...Fonts.fontSmall400,
        marginTop: 4,
        marginRight: 16,
        color: Colors.greyBlack6,
    },
})
