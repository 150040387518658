import { makeStyles,createTheme } from '@material-ui/core/styles';
import { useEffect,useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import { Grid,TableContainer,Paper,Table,TableRow,TableCell,TableHead,TableBody } from '@material-ui/core';
import { UseDataFetching, GetDashboardAPI} from '../../apis'

const DashBoardStyle = makeStyles((theme) => ({
    body:{
        paddingTop:10,
        marginTop:10,
        paddingRight:10,
    },
    title:{
        textAlign:"center",
        fontSize:20,
    },
    data:{
        textAlign:"center",
        fontSize:30,
        color:"#6699ff",
    },
    chart:{
        height:300,
    },
    tableGroup:{
        marginTop:20,
    },
    table:{
        
    }

   
}));


export const AdminDashboard = () => {
    const classes = DashBoardStyle();
    const [dailyActiveUser ,setDailyActiveUser ] = useState([]as any);
    const [dailyTopTenBlog ,setDailyTopTenBlog ] = useState([]as any);
    const [weeklyTopTenBlog ,setWeeklyTopTenBlog ] = useState([]as any);
    const [monthlyTopTenBlog ,setMonthlyTopTenBlog ] = useState([]as any);
    const [totalTopThirtyBlog ,setTotalTopThirtyBlog ] = useState([]as any);

    const GetDashboardData = async() => {
        const  {error,results} =  await UseDataFetching(GetDashboardAPI({})) as any;
        setDailyActiveUser(results['daily_active_user']?results['daily_active_user']:[]);
        setDailyTopTenBlog(results['daily_top_ten_blog']?results['daily_top_ten_blog']:[]);
        setWeeklyTopTenBlog(results['weekly_top_ten_blog']?results['weekly_top_ten_blog']:[]);
        setMonthlyTopTenBlog(results['monthly_top_ten_blog']?results['monthly_top_ten_blog']:[]);
        setTotalTopThirtyBlog(results['total_top_thirty_blog']?results['total_top_thirty_blog']:[]);
      };
    const getOption = () => {
        let dates = new Array(30);

        for (var i=0; i<30; i++)
        {
            var day = new Date();
            day.setTime(day.getTime()-24*60*60*1000*i);
            dates[29-i]= day.getFullYear()+"-" + (day.getMonth()+1) + "-" + day.getDate();
        }
        

        return {
            title: {
                text: '每日瀏覽人数'
            },
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                data: ['每日瀏覽人数']
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            toolbox: {
                feature: {
                    saveAsImage: {}
                }
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: dates,
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    name: '',
                    type: 'line',
                    stack: '总量',
                    data: dailyActiveUser,
                },

                
            ]
        };
    }

    useEffect(() =>{
        GetDashboardData();
    }, []);
    return <div className={classes.body}>
        {/* <div>
         <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
                <Card>
                <CardContent>
                    <Typography  className={classes.title} color="textSecondary" gutterBottom>
                        今日瀏覽次數
                    </Typography>
                    <div >
                        <Typography className={classes.data}  color="textPrimary" gutterBottom>
                            123456
                        </Typography>
                    </div>
                </CardContent>
                </Card>
            </Grid>  
            <Grid item xs={12} sm={4}>
                <Card>
                <CardContent>
                    <Typography className={classes.title}  color="textSecondary" gutterBottom>
                        總瀏覽次數
                    </Typography>
                    <div >
                        <Typography className={classes.data} color="textPrimary" gutterBottom>
                        3245123
                        </Typography>
                    </div>
                </CardContent>
                </Card>
            </Grid>  
            <Grid item xs={12} sm={4}>
                <Card>
                <CardContent>
                    <Typography className={classes.title}  color="textSecondary" gutterBottom>
                        今日留言數量
                    </Typography>
                    <div >
                        <Typography className={classes.data}  color="textPrimary" gutterBottom>
                            54321
                        </Typography>
                    </div>
                </CardContent>
                </Card>
            </Grid> 
        </Grid>      
        </div> */}

        <div className={classes.chart}>
            <ReactEcharts
                option={getOption()} 
                notMerge={true}
                lazyUpdate={true}
                //onEvents={onEvents}
                style={{width: '100%',height:'100%'}}
            />
        </div>

        <div className={classes.tableGroup}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <TableCell>每日瀏覽量Top10</TableCell>
                            <TableCell align="right">瀏覽次數</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            dailyTopTenBlog.map((option, index) => (
                            <TableRow>
                                <TableCell>{option.title}</TableCell>
                                <TableCell align="right">{option.number}</TableCell>
                            </TableRow> 
                            ))
                        }

                       
                    </TableBody>  
                    </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <TableCell>每週瀏覽量Top10</TableCell>
                            <TableCell align="right">瀏覽次數</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            weeklyTopTenBlog.map((option, index) => (
                            <TableRow>
                                <TableCell>{option.title}</TableCell>
                                <TableCell align="right">{option.number}</TableCell>
                            </TableRow> 
                            ))
                        }
   
                    </TableBody>  
                    </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <TableCell>每月瀏覽量Top10</TableCell>
                            <TableCell align="right">瀏覽次數</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            monthlyTopTenBlog.map((option, index) => (
                            <TableRow>
                                <TableCell>{option.title}</TableCell>
                                <TableCell align="right">{option.number}</TableCell>
                            </TableRow> 
                            ))
                        }
                    </TableBody>  
                    </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <TableCell>總瀏覽量Top30</TableCell>
                            <TableCell align="right">瀏覽次數</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            totalTopThirtyBlog.map((option, index) => (
                            <TableRow>
                                <TableCell>{option.title}</TableCell>
                                <TableCell align="right">{option.number}</TableCell>
                            </TableRow> 
                            ))
                        }
                    </TableBody>  
                    </Table>
                    </TableContainer>
                </Grid>
            </Grid>

        </div>
        <br /><br /><br />
    </div>
}