import React from 'react'
// view screens
import { ContentBriefItemView } from './content-brief-item-view'
// helpers
import { SetCurrentArticleCategories } from '../../helpers'

export const ContentBriefItemController = (props) => {

    const {
        categories,
    } = props

    const onClickItem = () => {
        SetCurrentArticleCategories(categories)
    }

    const ViewProps = {
        ...props,
        onClickItem,
    }

    return (
        <ContentBriefItemView {...ViewProps} />
    )
}
