import { makeStyles } from '@material-ui/core/styles'
import { Colors, Fonts, Layouts } from '../theme' 

const toolBarContainer = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'center',
    height: '100%',
}

export const NavigationBarStyles = makeStyles({
    aTag: {
        ...Layouts.noUnderline,
    },
    zIndex1301: {
        zIndex: 1301
    },
    appBarContainer: {
        height: '80px',
        boxShadow: 'none',
        backgroundColor: Colors.blue1,
    },
    toolBarContainer: {
        ...toolBarContainer,
        width: Layouts.contentWidth,
        borderBottom: `1px solid ${Colors.white}`,
    },
    menuContainer: {
       
    },
    toolBarContainerM: {
        ...toolBarContainer,
        width: Layouts.M.contentWidth,
    },
    rightBarContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    titleText: {
        ...Fonts.fontMediumBig700,
        lineHeight: 0,
    },
    popContainer: {
        ...Layouts.columnWithAllCenter as {},
        width: 278,
    },
    popInnerContainer: {
        marginTop: 10,
        width: 278,
        backgroundColor: Colors.blue3,
    },
    popInnerContainerM: {
        width: '100%',
        marginLeft: 75,
        backgroundColor: Colors.blue1,
    },
    triangleUp: {
        position: 'absolute',
        top: 0,
        width: 0, 
        height: 0, 
        borderLeft: Layouts.borderLine(Colors.transparent, 10, 'solid'),
        borderRight: Layouts.borderLine(Colors.transparent, 10, 'solid'),
        borderBottom: Layouts.borderLine(Colors.blue3, 10, 'solid'),
    },
    buttonPopGuideContainer: {
        backgroundColor: Colors.blue3,
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: Colors.blue8,
        },
    },
    buttonPopGuideContainerM: {
        backgroundColor: Colors.blue1,
    },
    buttonPopGuideInnerContainer: {
        ...Layouts.rowWithJCStart,
        margin: `0 24px`,
        padding: `16px 0`,
    },
    buttonPopGuideInnerContainerM: {
        ...Layouts.rowWithJCStart,
        flex: 1,
        margin: `0 24px`,
        padding: `20px 0`,
    },
    whiteBotttomLine : {
        borderBottom: Layouts.borderLine(Colors.white, 1, 'solid')
    },
    buttonPopGuideText: {
        ...Fonts.fontMediumBig400,
        color: Colors.greyBlack85,
    },
})
